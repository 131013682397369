import { Model, BelongsTo, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ImagePipelineSync from './image_pipeline_sync';

@Model()
export default class ImagePipelineSyncTransition extends ApplicationRecord {
  static jsonapiType = 'image_pipeline_sync_transitions';

  @Attr() createdAt: string;
  @Attr() toState: string;
  @Attr() duration: number;
}
