import { Attr, Model, BelongsTo } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Account from './account';

@Model()
export default class Region extends ApplicationRecord {
  static jsonapiType = 'regions';
  @Attr() name: string;
  @Attr() code: string;
  @BelongsTo() account: Account;
}
