import InstanceTypeOffering from '../models/instance_type_offering';
// eslint-disable-next-line no-unused-vars
import InstanceType from '../models/instance_type';

export default {
  name: 'instance_types',
  getReducer: () => {
    const initialData = {
      loadingInstanceTypeOfferings: false,
      instanceTypeOfferings: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_INSTANCE_TYPE_OFFERINGS') {
        return { ...state, loadingInstanceTypeOfferings: true };
      }
      if (type === 'FETCH_INSTANCE_TYPE_OFFERINGS_SUCCESS') {
        return {
          ...state,
          loadingInstanceTypeOfferings: false,
          instanceTypeOfferings: payload.result,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          instanceTypeOfferings: null,
        };
      }

      return state;
    };
  },

  doFetchInstanceTypeOfferings: availabilityZoneId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_INSTANCE_TYPE_OFFERINGS' });
    const scope = InstanceTypeOffering.where({
      availability_zone_id: availabilityZoneId,
    }).order({ identifier: 'asc' });

    const response = await scope.includes(['instance_type']).all();

    dispatch({
      type: 'FETCH_INSTANCE_TYPE_OFFERINGS_SUCCESS',
      payload: { result: response.data },
    });
  },

  doFetchInstanceTypeForFacility: () => async ({ getState, store }) => {
    const state = getState();

    const facility = store.selectActiveFacility(state);
    const scope = InstanceType.where({
      facility_id: facility.id,
    }).order({ identifier: 'asc' });

    const response = await scope.all();

    return response.data;
  },

  selectInstanceTypeOfferings: state =>
    state.instance_types.instanceTypeOfferings,

  selectLoadingInstanceTypeOfferings: state =>
    state.instance_types.loadingInstanceTypeOfferings,
};
