import { createSelector } from 'redux-bundler';
import moment from 'moment-timezone';
import { every } from 'lodash';

import Stat from '../models/stat';
import WorkstationStatRetrieval from '../models/workstation_stat_retrieval';

const STALE_AFTER = 300 * 1000;
const MAX_SWAY = 60 * 1000;

export default {
  name: 'workstation_stats',
  getReducer: () => {
    const initialData = {
      statsRequestedAt: 0,
      statsLoadedAt: 0,
      sway: 0,
      loaded: false,
      loading: false,
      retrieving: false,
      stats: {},
      timezone: moment.tz.guess(),
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'CREATE_WORKSTATION_STATS_RETRIEVAL_START') {
        return {
          ...state,
          // loading: true,
          error: false,
          retrieving: true,
          statsRequestedAt: Date.now(),
        };
      }

      if (type === 'SET_SWAY') {
        const sway = Math.random() * MAX_SWAY;
        console.log('Stat Update Sway set: ', sway);
        return { ...state, sway };
      }
      if (type === 'CREATE_WORKSTATION_STATS_RETRIEVAL_SUCCESS') {
        return {
          ...state,
          error: false,
          retrieving: false,
          statsLoadedAt: null,
        };
      }
      if (type === 'CREATE_WORKSTATION_STATS_RETRIEVAL_FAILURE') {
        return {
          ...state,
          loading: false,
          error: true,
          lastError: Date.now(),
          retrieving: false,
        };
      }
      if (type === 'SET_TIMEZONE') {
        return { ...state, timezone: payload.timezone };
      }

      if (type === 'RESET_ACTIVE_WORKSTATION') {
        return {
          ...state,
          loading: false,
          loaded: false,
          retrieving: false,
          statsLoadedAt: null,
          statsRequestedAt: null,
        };
      }
      if (type === 'FETCH_WORKSTATION_STATS_START') {
        return { ...state, loading: true, loaded: false };
      }
      if (type === 'FETCH_WORKSTATION_STATS_SUCCESS') {
        return {
          ...state,
          loading: false,
          loaded: true,
          statsLoadedAt: Date.now(),
          stats: {
            [payload.workstationId]: {
              loaded: true,
              data: payload.result,
              time: Date.now(),
            },
          },
        };
      }

      return state;
    };
  },

  doSetTimezone: timezone => ({ dispatch }) => {
    dispatch({
      type: 'SET_TIMEZONE',
      payload: { timezone },
    });
  },

  doFetchWorkstationStats: workstationId => async ({
    dispatch,
    store,
    getState,
  }) => {
    dispatch({ type: 'FETCH_WORKSTATION_STATS_START' });

    const response = await Stat.where({
      statable_id: workstationId,
      statable_type: 'Workstation',
    }).all();

    dispatch({
      type: 'FETCH_WORKSTATION_STATS_SUCCESS',
      payload: { workstationId, result: response.data },
    });
  },

  doCreateWorkstationStatRetrieval: () => async ({
    dispatch,
    store,
    getState,
  }) => {
    try {
      if (!store.selectShowMetrics(getState())) {
        return false;
      }

      dispatch({ type: 'CREATE_WORKSTATION_STATS_RETRIEVAL_START' });
      const state = getState();
      const facility = store.selectActiveFacility(state).dup();
      const workstation = store.selectActiveWorkstation(state)?.dup();

      const workstationStatRetrieval = new WorkstationStatRetrieval({
        facility,
        workstation,
      });

      await workstationStatRetrieval.save({
        with: ['facility.id', 'workstation.id'],
      });

      dispatch({ type: 'CREATE_WORKSTATION_STATS_RETRIEVAL_SUCCESS' });
    } catch (err) {
      dispatch({ type: 'CREATE_WORKSTATION_STATS_RETRIEVAL_FAILURE' });
      console.log(err);
    }
  },

  doSetSway: () => ({ dispatch, store, getState }) => {
    dispatch({ type: 'SET_SWAY' });
  },

  selectWorkstationStatsTimezone: state => state.workstation_stats.timezone,
  selectWorkstationStatsState: state => state.workstation_stats,
  selectWorkstationStats: createSelector(
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    (activeWorkstation, workstationStatState) => {
      if (!activeWorkstation) return [];

      return workstationStatState.stats[activeWorkstation.id]?.data || [];
    },
  ),

  selectWorkstationStatsLoaded: createSelector(
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    (activeWorkstation, workstationStatState) => {
      if (!activeWorkstation) return false;

      return workstationStatState.stats[activeWorkstation.id]?.loaded || false;
    },
  ),

  selectLoadingWorkstationStats: createSelector(
    'selectWorkstationStatsState',
    workstationStatState => workstationStatState.loading,
  ),

  selectActivateStats: createSelector(
    'selectRouteApis',
    'selectActiveWorkstation',
    'selectWorkstationStatsState',
    'selectShowMetrics',
    'selectAuthenticatedUser',

    (
      apis,
      activeWorkstation,
      workstationStatState,
      showMetrics,
      authenticatedUser,
    ) => {
      const wantsWorkstationStats = apis.includes('workstationStats');

      return (
        wantsWorkstationStats &&
        showMetrics &&
        activeWorkstation &&
        activeWorkstation.instanceId &&
        !activeWorkstation.discardedAt &&
        authenticatedUser &&
        authenticatedUser.isAuthorized('ui.workstation.stats.view')
      );
    },
  ),

  reactShouldSetSway: createSelector(
    'selectWorkstationStatsState',
    workstationStatsState => {
      if (workstationStatsState.sway) {
        return null;
      }

      return {
        actionCreator: 'doSetSway',
      };
    },
  ),

  reactShouldFetchWorkstationStats: createSelector(
    'selectActivateStats',
    'selectRouteParams',
    'selectWorkstationStatsState',
    (activateStats, routeParams, workstationStatsState) => {
      if (!activateStats) return null;
      if (workstationStatsState.loading) return null;
      if (workstationStatsState.statsLoadedAt) return null;

      return {
        actionCreator: 'doFetchWorkstationStats',
        args: [routeParams.workstationId],
      };
    },
  ),

  reactShouldUpdateStats: createSelector(
    'selectActivateStats',
    'selectRouteParams',
    'selectWorkstationStatsState',
    'selectAppTime',
    (activateStats, routeParams, workstationStatsState, appTime) => {
      if (!activateStats) return null;
      if (workstationStatsState.loading) return null;
      if (workstationStatsState.retrieving) return null;

      if (
        appTime - workstationStatsState.statsRequestedAt >
        STALE_AFTER + workstationStatsState.sway
      ) {
        return {
          actionCreator: 'doCreateWorkstationStatRetrieval',
        };
      }
    },
  ),
};
