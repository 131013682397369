import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import WekaCluster from './weka_cluster';

@Model()
export default class WekaClusterBoot extends ApplicationRecord {
  static jsonapiType = 'weka_cluster_boots';
  @Attr() state: string;
  @Attr() executionArn: string;
  @Attr() executionArnRegion: string;

  @BelongsTo() wekaCluster: WekaCluster;

  stepFunctionUrl() {
    return `https://${this.executionArnRegion}.console.aws.amazon.com/states/home?region=${this.executionArnRegion}#/executions/details/${this.executionArn}`;
  }
}
