// eslint-disable-next-line no-unused-vars
import PusherConfiguration from '../models/pusher_configuration';

export default {
  name: 'pusher_configuration',
  getReducer: () => {
    const initialData = {
      loading: false,
      key: undefined,
      cluster: undefined,
      authEndpoint: undefined,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_PUSHER_CONFIGURATION') {
        return { loading: true };
      }
      if (type === 'FETCH_PUSHER_CONFIGURATION_SUCCESS') {
        return {
          key: payload.key,
          cluster: payload.cluster,
          authEndpoint: payload.authEndpoint,
          loading: false,
        };
      }

      return state;
    };
  },

  doFetchPusherConfiguration: () => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_PUSHER_CONFIGURATION' });

    const response = await PusherConfiguration.find('1');

    dispatch({
      type: 'FETCH_PUSHER_CONFIGURATION_SUCCESS',
      payload: response.data,
    });
  },

  selectPusherConfiguration: state => state.pusher_configuration,
};
