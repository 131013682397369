import { createSelector } from 'redux-bundler';
import { chain, flatten, forEachRight, groupBy, mapValues } from 'lodash';
import moment from 'moment-timezone';

import WorkstationEventFeedEntry from '../models/workstation_event_feed_entry';

export default {
  name: 'workstation_event_feed_entries',
  getReducer: () => {
    const initialData = {
      loading: false,
      events: {},
      timezone: moment.tz.guess(),
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SET_TIMEZONE') {
        return { ...state, timezone: payload.timezone };
      }
      if (type === 'FETCH_WORKSTATION_EVENTS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_WORKSTATION_EVENTS_SUCCESS') {
        return {
          ...state,
          loading: false,
          events: { [payload.workstationId]: payload.result },
        };
      }
      if (type === 'ADD_WORKSTATION_EVENT') {
        const { event } = payload;
        const { workstationId } = event;
        return {
          ...state,
          events: { [workstationId]: [...state.events[workstationId], event] },
        };
      }

      return state;
    };
  },

  doSetTimezone: timezone => ({ dispatch }) => {
    dispatch({
      type: 'SET_TIMEZONE',
      payload: { timezone },
    });
  },

  doAddWorkstationEvent: eventJson => ({ dispatch }) => {
    const event = WorkstationEventFeedEntry.fromJsonapi(
      eventJson.data,
      eventJson,
    );

    dispatch({
      type: 'ADD_WORKSTATION_EVENT',
      payload: { event },
    });
  },

  doFetchWorkstationEvents: workstationId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_WORKSTATION_EVENTS_START' });

    const response = await WorkstationEventFeedEntry.where({
      workstation_id: workstationId,
    }).all();
    dispatch({
      type: 'FETCH_WORKSTATION_EVENTS_SUCCESS',
      payload: { workstationId, result: response.data },
    });
  },

  selectWorkstationEventsTimezone: state =>
    state.workstation_event_feed_entries.timezone,
  selectWorkstationEventsState: state => state.workstation_event_feed_entries,
  selectWorkstationEvents: createSelector(
    'selectActiveWorkstation',
    'selectWorkstationEventsState',
    (activeWorkstation, workstationEventState) =>
      activeWorkstation
        ? workstationEventState.events[activeWorkstation.id] || []
        : [],
  ),

  /*
    This selector produces event data for consumption in components.
    It correctly groups events by date and then by eventable id.
    
    The input is an array of WorkstationEventFeedEntry instances.
    The output is a Map object with the following scheme:

    Map({ [date]: Map(
      { eventableId: [ WorkstationEventFeedEntry, ... ], ... }
    ) ... })

    i) Map is used to preserve the insertion order of the object properties.
       Ad-hoc notation is used to show the Map structure as an object.
    ii) date = moment(event.createdAt).startOf('date')
    
    There are six steps of iteration. Description of each step and examples:
    0. raw event data from api
       [e1, e3, e2, e4]
    1. sort events by creation date
       [e1, e2, e3, e4]
    2. group events by eventable id
       {eid1: [e1, e2], eid2: [e3], eid3: [e4]}
    3. group event groups by date, temporarily discarding eventable id
       {d1: [[e1, e2]], d2: [[e3], [e4]]}
    4. for each date, re-introduce eventable id 
       {d1: {eid1: [e1, e2]}, d2: {eid2: [e3], eid3: [e4]}}
    5. reverse the keys for each day's eventable group
       {d1: Map({eid1: [e1, e2]}), d2: Map({eid3: [e4], eid2: [e3]})}
    5. reverse the keys for each day
       Map({d2: Map({eid3: [e4], eid2: [e3]}), d1: Map({eid1: [e1, e2]})})
  */
  selectDatedAndGroupedWorkstationEvents: createSelector(
    'selectWorkstationEvents',
    'selectWorkstationEventsTimezone',
    (rawEvents, timezone) => {
      if (!rawEvents) {
        return {};
      }

      const result = new Map();

      chain(rawEvents)
        .sortBy(e => e.timestamp)
        .groupBy(e => e.eventableId)
        .groupBy(events =>
          moment(events[0].createdAt)
            .utc()
            .tz(timezone)
            .format('dddd MMM Do YYYY'),
        )
        .mapValues(eventLists =>
          mapValues(
            groupBy(eventLists, events => events[0].eventableId),
            flatten,
          ),
        )
        .forEachRight((eventGroupsForDate, date) => {
          const nextEventGroups = new Map();
          forEachRight(eventGroupsForDate, (events, eventableId) =>
            nextEventGroups.set(eventableId, events),
          );
          result.set(date, nextEventGroups);
        })
        .value();

      return result;
    },
  ),

  selectLoadingWorkstationEvents: createSelector(
    'selectWorkstationEventsState',
    workstationEventState => workstationEventState.loading,
  ),

  reactShouldFetchWorkstationEvents: createSelector(
    'selectRouteApis',
    'selectRouteParams',
    'selectPathname',
    'selectActiveWorkstation',
    'selectWorkstationEventsState',
    'selectWorkstationEvents',
    (
      apis,
      routeParams,
      pathname,
      activeWorkstation,
      workstationEventState,
      events,
    ) => {
      const wantsEvents = apis.includes('workstationEvents');
      if (
        !activeWorkstation ||
        !wantsEvents ||
        !routeParams.workstationId ||
        workstationEventState.loading
      ) {
        return null;
      }
      if (events.length > 0) {
        return null;
      }

      return {
        actionCreator: 'doFetchWorkstationEvents',
        args: [routeParams.workstationId],
      };
    },
  ),
};
