import {
  Button,
  Dialog,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { withStyles } from 'tss-react/mui';
import { chain, sortBy } from 'lodash';
import { connect } from 'redux-bundler-react';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CakeIcon from '@material-ui/icons/Cake';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import matchSorter from 'match-sorter';
import pluralize from 'pluralize';

const styles = theme => ({
  empty: {
    margin: 'auto',
    height: '100',
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    borderTop: `1px solid rgba(0, 0, 0, 0.23)`,
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {},
  input: {
    border: 'none',
    padding: theme.spacing(2),
  },
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
  list: {
    maxHeight: 'calc(90vh - 64px)',
    overflowY: 'scroll',
  },
  chip: {
    borderRadius: 8,
  },
  right: {
    textAlign: 'right',
  },
  key: {
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    fontFamily:
      "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace",
    backgroundColor: theme.palette.background.paper,
    fontSize: '12px',
    borderRadius: 4,
    paddingLeft: 4,
    paddingRight: 4,
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  subheader: {
    // backgroundColor: darken(theme.palette.background.paper, 0.06),
  },
  divider: {
    marginTop: theme.spacing(1),
    '&:last-child': {
      display: 'none',
    },
  },

  cancel: {
    textAlign: 'right',

    borderBottom: `1px solid rgba(0, 0, 0, 0.23)`,
  },
});

const isSearchable = item => {
  return ['Facility', 'Client'].includes(item.type);
};
const ICONS = {
  Facility: LocationCityIcon,
  Workstation: DesktopWindowsIcon,
  ImagePipeline: CakeIcon,
  Client: BusinessCenterIcon,
};
const Recents = ({
  open,
  classes,
  onClose,
  recentItems,
  doUpdateUrl,
  doClearRecentItems,
  onSetClient,
  onSetFacility,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [query, setQuery] = React.useState('');
  const inputEl = React.useRef(null);
  const [selected, setSelected] = React.useState(0);
  const [selectedUrl, setSelectedUrl] = React.useState(null);

  const [filteredRecentItems, setFilteredRecentItems] = React.useState([]);
  const handleKeyDown = e => {
    console.log(filteredRecentItems[selected].type);
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (open && filteredRecentItems[selected]) {
        if (e.metaKey || e.ctrlKey) {
          window.open(filteredRecentItems[selected].url, '_blank');
        } else {
          if (
            e.key === 'Tab' &&
            filteredRecentItems[selected].type === 'Client'
          ) {
            onSetClient(filteredRecentItems[selected]);
          } else if (
            e.key === 'Tab' &&
            filteredRecentItems[selected].type === 'Facility'
          ) {
            onSetFacility(filteredRecentItems[selected]);
          } else {
            doUpdateUrl(filteredRecentItems[selected].url);
          }
        }
        onClose();
      }
      e.preventDefault();
    }
    if (e.key === 'ArrowDown') {
      if (open && filteredRecentItems) {
        let nextSelected;
        if (filteredRecentItems.length - 1 > selected) {
          nextSelected = selected + 1;
        } else {
          nextSelected = 0;
        }
        setSelected(nextSelected);
        setSelectedUrl(filteredRecentItems[nextSelected].url);
      }
      e.preventDefault();
    }

    if (e.key === 'ArrowUp') {
      if (open && filteredRecentItems) {
        let nextSelected;
        if (selected > 0) {
          nextSelected = selected - 1;
        } else {
          nextSelected = filteredRecentItems.length - 1;
        }
        setSelected(nextSelected);
        setSelectedUrl(filteredRecentItems[nextSelected].url);
      }
      e.preventDefault();
    }
  };

  const listItemRefCallback = React.useCallback(
    ref => {
      if (!ref) return;

      ref.scrollIntoView(false);
    },
    [selected],
  );

  React.useEffect(() => {
    let filteredItemsBeforeSorting;
    if (query !== '') {
      filteredItemsBeforeSorting = matchSorter(recentItems, query.trim(), {
        keys: ['name'],
      });
    } else {
      filteredItemsBeforeSorting = recentItems;
    }

    const filteredItemsAfterSorting = sortBy(filteredItemsBeforeSorting, a => [
      a.type,
      a.name,
    ]);
    setFilteredRecentItems(filteredItemsAfterSorting);

    setSelected(0);
    setSelectedUrl(filteredItemsAfterSorting[0]?.url);
  }, [query, recentItems]);
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
      fullScreen={fullScreen}
      onClose={onClose}
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      {fullScreen && (
        <div className={classes.cancel} onClick={() => onClose()}>
          <Button>Cancel</Button>
        </div>
      )}
      <div className={classes.form} onKeyDown={handleKeyDown}>
        <InputBase
          inputRef={inputEl}
          className={classes.input}
          value={query}
          onChange={e => setQuery(e.target.value)}
          autoFocus
          fullWidth
          variant="outlined"
          placeholder={'Recent items'}
          /* onKeyDown={handleKeyDown} */
        />
      </div>

      <Divider />
      {recentItems.length === 0 && (
        <div className={classes.empty}>No recent items to show</div>
      )}

      {filteredRecentItems.length === 0 && recentItems.length > 0 && (
        <div className={classes.empty}>No recent items match your search</div>
      )}
      {filteredRecentItems.length > 0 && (
        <List dense className={classes.list}>
          {chain(filteredRecentItems)
            .groupBy(d => d.type)
            .map((items, type, index) => (
              <>
                <ListSubheader className={classes.subheader} disableSticky>
                  {pluralize(type)}
                </ListSubheader>
                {items.map(item => {
                  const Icon = ICONS[item.type];
                  return (
                    <ListItem
                      button
                      component="a"
                      href={item.url}
                      ref={
                        item.url === selectedUrl ? listItemRefCallback : null
                      }
                      selected={item.url === selectedUrl}
                      onClick={() => {
                        /* doUpdateUrl(item.url); */
                        onClose();
                      }}
                    >
                      <ListItemIcon className={classes.icon}>
                        {Icon && <Icon />}
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                      <ListItemText
                        secondary={item.secondary}
                        className={classes.right}
                      />
                    </ListItem>
                  );
                })}

                <Divider className={classes.divider} />
              </>
            ))
            .value()}
        </List>
      )}
      <div className={classes.footer}>
        <Typography variant="caption" color="textSecondary">
          <kbd className={classes.key}>ctrl+k</kbd> Search for Facilities,
          Workstations, Templates etc
        </Typography>

        <SettingsIcon
          htmlColor="grey"
          onClick={event => setAnchorEl(event.currentTarget)}
        />

        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          className={classes.menu}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setAnchorEl(null);
              doClearRecentItems();
            }}
          >
            Clear history
          </MenuItem>
        </Menu>
      </div>
    </Dialog>
  );
};

export default connect(
  'doUpdateUrl',
  'selectActiveClient',

  'selectClientData',
  'selectRecentItems',
  'doClearRecentItems',
  withStyles(Recents, styles, { withTheme: true }),
);
