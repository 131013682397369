import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';

import Facility from './facility';

@Model()
export default class FacilityCreation extends ApplicationRecord {
  static jsonapiType = 'facility_creations';
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() vpcCompletedAt: string;
  @Attr() sshCompletedAt: string;
  @Attr() permissionsCompletedAt: string;
  @Attr() configurationCompletedAt: string;
  @Attr() bastionCompletedAt: string;
  @Attr() cmCompletedAt: string;
  @Attr() directoryCompletedAt: string;
  @Attr() storageCompletedAt: string;
  @Attr() licensesCompletedAt: string;
  @Attr() renderCompletedAt: string;
  @Attr() ssmCompletedAt: string;
  @Attr() sslCompletedAt: string;
  @Attr() wekaCompletedAt: string;
  @Attr() gpoCompletedAt: string;
  @Attr() reason: string;

  @Attr() vpcStartedAt: string;
  @Attr() sshStartedAt: string;
  @Attr() permissionsStartedAt: string;
  @Attr() configurationStartedAt: string;
  @Attr() bastionStartedAt: string;
  @Attr() cmStartedAt: string;
  @Attr() directoryStartedAt: string;
  @Attr() storageStartedAt: string;
  @Attr() licensesStartedAt: string;
  @Attr() renderStartedAt: string;
  @Attr() ssmStartedAt: string;
  @Attr() sslStartedAt: string;
  @Attr() wekaStartedAt: string;
  @Attr() gpoStartedAt: string;

  @Attr() vpcFailedAt: string;
  @Attr() sshFailedAt: string;
  @Attr() permissionsFailedAt: string;
  @Attr() configurationFailedAt: string;
  @Attr() bastionFailedAt: string;
  @Attr() cmFailedAt: string;
  @Attr() directoryFailedAt: string;
  @Attr() storageFailedAt: string;
  @Attr() licensesFailedAt: string;
  @Attr() renderFailedAt: string;
  @Attr() ssmFailedAt: string;
  @Attr() sslFailedAt: string;
  @Attr() wekaFailedAt: string;
  @Attr() gpoFailedAt: string;

  @Attr() errorInfo: object;

  @Attr() state: string;
  @Attr() stepFunctionArn: string;
  @Attr() executionArn: string;
  @Attr() stepFunctionRegion: string;
  @Attr() createChangeSet: boolean;
  @Attr() skipLdapDeployment: boolean;
  @Attr() skipTagUpdate: boolean;
  @Attr() forceCertificateRenewal: boolean;
  @Attr() steps: string[];

  @BelongsTo() facility: Facility;

  isComplete() {
    return this.state === 'complete';
  }

  isPending() {
    return this.state === 'started' || this.state === 'pending';
  }

  isFailed() {
    return this.state === 'failed';
  }

  stepFunctionUrl() {
    return `https://${this.stepFunctionRegion}.console.aws.amazon.com/states/home?region=${this.stepFunctionRegion}#/executions/details/${this.executionArn}`;
  }
}
