import { BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import StorageGateway from './storage_gateway';

@Model()
export default class StorageGatewayStatRetrieval extends ApplicationRecord {
  static jsonapiType = 'storage_gateway_stat_retrievals';

  @BelongsTo() storageGateway: StorageGateway;
}
