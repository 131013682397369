import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Template from './template';

@Model()
export default class WorkstationNamingSuggestion extends ApplicationRecord {
  static jsonapiType = 'workstation_naming_suggestions';
  @Attr() workstationCount: number;
  @Attr() workstationNames: string[];

  @BelongsTo() template: Template;
}
