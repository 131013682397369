import { Field, Form, Formik } from 'formik';

import { TextField } from 'formik-mui';
import { Grid } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import React from 'react';
import * as yup from 'yup';

import PasswordTip from './PasswordTip';

const styles = theme => ({
  requirement: {
    marginTop: theme.spacing(1),
    display: 'flex',
    color: theme.palette.text.secondary,

    alignItems: 'center',
  },
  requirementIcon: {
    marginRight: theme.spacing(1),
  },
});

const ChangePasswordForm = withStyles(
  ({ username, classes, children, ticked, buttonRenderer, onSubmit }) => {
    const [isComplex, setComplex] = React.useState(false);

    const handleCheck = valid => {
      console.log(valid);
      setComplex(valid);
    };
    const inputEl = React.useRef(null);
    return (
      <Formik
        enableReinitialize
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={yup.object().shape({
          password: yup
            .string()
            .required('Password is a required field')
            .min(8)

            .test(
              'no-username',
              'Password cannot contain username',
              value => !value.toLowerCase().includes(username.toLowerCase()),
            ),
          passwordConfirmation: yup
            .string()
            .required()
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        })}
        onSubmit={onSubmit}
        validateOnMount
      >
        {({ isValid, values }) => {
          return (
            <div>
              <Form>
                <Field
                  name="password"
                  type="password"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  label="Password"
                  autofocus
                  inputRef={inputEl}
                />
                Please ensure your password is 8 or more characters and meets 3
                of the other requirements:
                <Grid container>
                  <Grid xs={6}>
                    <PasswordTip
                      check="length"
                      password={values.password}
                      onCheck={handleCheck}
                    >
                      8 or more characters
                    </PasswordTip>
                    <PasswordTip check="uppercase" password={values.password}>
                      1 uppercase character
                    </PasswordTip>
                    <PasswordTip check="lowercase" password={values.password}>
                      1 lowercase character
                    </PasswordTip>
                  </Grid>
                  <Grid xs={6}>
                    <PasswordTip check="punctuation" password={values.password}>
                      1 punctuation character
                    </PasswordTip>
                    <PasswordTip check="number" password={values.password}>
                      1 number
                    </PasswordTip>
                    <PasswordTip check="special" password={values.password}>
                      1 special character
                    </PasswordTip>
                  </Grid>
                </Grid>
                <Field
                  name="passwordConfirmation"
                  type="password"
                  fullWidth
                  component={TextField}
                  margin="normal"
                  variant="outlined"
                  label="Confirm Password"
                />
                {buttonRenderer && buttonRenderer(isValid && isComplex)}
              </Form>
            </div>
          );
        }}
      </Formik>
    );
  },
  styles,
);

export default ChangePasswordForm;
