import { createSelector } from 'redux-bundler';

import Subnet from '../models/subnet';

export default {
  name: 'subnets',
  getReducer: () => {
    const initialData = {
      loading: false,
      subnets: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_SUBNETS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_SUBNETS_SUCCESS') {
        return {
          ...state,
          loading: false,
          subnets: payload.result,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          subnets: null,
        };
      }

      return state;
    };
  },

  doFetchSubnets: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    dispatch({ type: 'FETCH_SUBNETS' });
    const scope = Subnet.where({
      facility_id: facility.id,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_SUBNETS_SUCCESS',
      payload: { result: response.data },
    });
  },

  reactShouldFetchSubnets: createSelector(
    'selectRouteApis',
    'selectSubnetsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, subnetsRaw, currentUser, activeClient) => {
      const wantsSubnets = apis.includes('subnets');
      if (
        !wantsSubnets ||
        subnetsRaw.loading ||
        subnetsRaw.subnets ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchSubnets' };
    },
  ),

  selectSubnetsRaw: state => state.subnets,
  selectSubnets: state => state.subnets.subnets,
};
