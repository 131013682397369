import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class ConnectionManager extends ApplicationRecord {
  static jsonapiType = 'connection_managers';
  @Attr() status: string;
  @Attr() instanceType: string;
  @Attr() instanceId: string;
  @Attr() certificateExpiresAt: string;
  @Attr() certificateIssuer: string;
  @Attr() privateIpAddress: string;
  @Attr() version: string;

  @BelongsTo() facility: Facility;

  hprAddress() {
    return `${this.privateIpAddress}:4900`;
  }

  isExpiring() {
    return (
      new Date(this.certificateExpiresAt) > new Date() &&
      new Date(this.certificateExpiresAt) <
        new Date(new Date().setDate(new Date().getDate() + 14))
    );
  }

  isExpired() {
    if (this.certificateExpiresAt == null) {
      return false;
    }
    return new Date(this.certificateExpiresAt) < new Date();
  }

  daysToExpiration() {
    if (this.certificateExpiresAt == null) {
      return null;
    }

    return Math.round(
      (new Date(this.certificateExpiresAt).getTime() - new Date().getTime()) /
        (1000 * 60 * 60 * 24),
    );
  }

  daysToExpirationAsText() {
    if (this.certificateExpiresAt == null) {
      return null;
    }

    const days = this.daysToExpiration();
    if (days == null) {
      return null;
    }

    if (days < 0) {
      return `${Math.abs(days)} days ago`;
    }

    if (days === 0) {
      return 'Today';
    }

    if (days === 1) {
      return 'Tomorrow';
    }

    return `${days} days`;
  }
}
