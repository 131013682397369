  const getUserAgent = () => {
    return window.navigator.userAgent;
  };

  const userAgentContains = browserName => {
    browserName = browserName.toLowerCase();
    return (
      getUserAgent()
        .toLowerCase()
        .indexOf(browserName) > -1
    );
  };
  const isOSX = () => {
    return userAgentContains('Macintosh');
  };

  const isWindows = () => {
    return userAgentContains('Windows');
  };

  const isLinux = () => {
    return userAgentContains('linux');
  };

  export const dcvClientDownloadInfo = () => {
    if (isLinux()) {
      return {
        version: 'Download from Nice DCV site',
        url: 'https://download.nice-dcv.com/',
        help:
          'https://docs.aws.amazon.com/dcv/latest/userguide/client-linux.html',
        download: false,
      };
    }

    if (isWindows()) {
      return {
        version: 'Download for Windows',
        url: 'https://d1uj6qtbmh3dt5.cloudfront.net/nice-dcv-client-Release.msi',
        help:
          'https://docs.aws.amazon.com/dcv/latest/userguide/client-windows.html',
        download: true,
      };
    }

    if (isOSX()) {
      return {
        version: 'Download from Nice DCV site',
        url: 'https://download.nice-dcv.com/',
        help: 'https://docs.aws.amazon.com/dcv/latest/userguide/client-mac.html',
        download: false,
      };
    }

    return {
      version: 'Download from Nice DCV',
      url: 'https://download.nice-dcv.com/',

      download: false,
    };
  };
