import React, { Component } from 'react';
import { connect } from 'redux-bundler-react';
import ManeAuth from '../../../models/mane_auth';
import { useAuth } from 'oidc-react';
import LoginScreen from './LoginScreen';
import LoggedOutDialog from './LoggedOutDialog';

import moment from 'moment-timezone';

function redirectUrl() {
  console.log(process.env.REACT_APP_CONTEXT);
  if (process.env.REACT_APP_CONTEXT === 'production') {
    return process.env.REACT_APP_URL;
  }

  return process.env.REACT_APP_DEPLOY_PRIME_URL;
}

function useEvent(event, handler, passive = false) {
  React.useEffect(() => {
    // initiate the event handler
    window.addEventListener(event, handler, passive);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, handler);
    };
  });
}

const AuthenticationCheck = ({
  children,
  classes,
  currentUser,
  doUpdateUrl,
  doLogout,
  doSetCurrentUser,
  pathname,
  oauthClient,
  sessionExpired,
  setIsLoaded,
  setIsLoggedIn,
  isLoaded,
  isLoggedIn,
  setExpiresAt,
  disableAutoLogout,
  forcedLogout,
}) => {
  const [state, setState] = React.useState({
    isLoaded: false,
    error: null,
  });

  React.useEffect(() => {
    execute();
  }, []);

  async function getLoginState() {
    console.log('getLoginState');
    if (sessionExpired) {
      return { handled: false, isLoggedIn: false };
    }

    try {
      return (
        (await oauthClient.handlePageLoad(window.location.href)) || {
          handled: false,
          isLoggedIn: false,
        }
      );
    } catch {
      // ManeAuth.logout();
      console.log('User not logged in');

      return { handled: false, isLoggedIn: false };
    }
  }
  /*
   * Get the login URL, store state if needed, then redirect
   */
  async function startAuth(values) {
    try {
      window.location.href = await oauthClient.startLogin(values);
    } catch (e) {
      console.log(e);
    }
  }

  async function execute() {
    try {
      const { handled, isLoggedIn, expiresAt } = await getLoginState();
      if (handled) {
        // After a login completes, the SPA can restore its location, page state and control the back navigation
        if (localStorage.getItem('redirectURL')) {
          const url = localStorage.getItem('redirectURL');
          localStorage.removeItem('redirectURL');
          doUpdateUrl(url);
        } else {
          doUpdateUrl('/');
        }
      }

      setIsLoaded();
      setState((state: any) => {
        return {
          ...state,
          isLoaded: true,
        };
      });

      if (isLoggedIn) {
        setIsLoggedIn();
      } else {
        localStorage.setItem('redirectURL', pathname);
      }

      if (expiresAt) {
        setExpiresAt(expiresAt);
      }
    } catch (e) {
      console.log(e);

      // const remoteError = e as RemoteError;
      // if (remoteError) {

      //     setState((state: any) => {
      //         return {
      //             ...state,
      //             error: remoteError.toDisplayFormat(),
      //         };
      //     });
      // }
    }
  }

  const handleLogout = event => {
    if (event.key === 'newExpiry') {
      setExpiresAt(event.newValue);
    }
    if (event.key === 'logout') {
      if (disableAutoLogout) {
        console.log('skip logging out');
        return;
      }
      if (currentUser) {
        console.log('logged out from storage!');
        doLogout({ forced: true });
      }
    }
  };

  useEvent('storage', handleLogout);

  if (isLoggedIn && forcedLogout) {
    return <LoggedOutDialog />;
  }

  if (isLoggedIn) {
    return children(currentUser);
  }

  return state.isLoaded ? (
    <LoginScreen onSigninClick={startAuth} />
  ) : (
    <div></div>
  );
  // if (forcedLogout) {
  //   return <LoggedOutDialog />;
  // } else if (isLoggedIn) {
  //   return children(currentuser);
  // } else {
  //   return state.isLoaded ? (
  //     <LoginScreen onSigninClick={startAuth} />
  //   ) : (
  //     <div></div>
  //   );
  // }
};

export default connect(
  'doUpdateUrl',
  'selectPathname',
  'doSetCurrentUser',
  'doLogout',
  'selectCurrentUser',
  'selectDisableAutoLogout',
  'selectForcedLogout',
  AuthenticationCheck,
);
