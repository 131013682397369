import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';

import User from '../../../models/user';

const RemoveUserDialog = ({
  baseUrl,
  open,
  onClose,
  doRemoveUserFromClient,
  doUpdateUrl,
  activeClient,
  activeUser,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}>
      <DialogTitle>Inviting user</DialogTitle>
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};

RemoveUserDialog.propTypes = {
  activeUser: PropTypes.instanceOf(User).isRequired,

  doRemoveUserFromClient: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
};

export default connect(
  'selectActiveClient',
  'selectActiveUser',
  'doRemoveUserFromClient',
  'selectBaseUrl',
  'doUpdateUrl',
  RemoveUserDialog,
);
