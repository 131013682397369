import { Model, BelongsTo, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Account from './account';

@Model()
export default class RegionSync extends ApplicationRecord {
  static jsonapiType = 'region_syncs';

  @Attr() accountId: string;
  @BelongsTo() account: Account;
}
