import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';

import ArchBaseSelect from '../../molecules/ArchBaseSelect';

class ArchRoleSelect extends Component {
  render() {
    const { rolesData, ...rest } = this.props;
    return (
      <ArchBaseSelect
        label="Role"
        {...rest}
        options={rolesData}
        renderValueName={value => `${value.name}`}
      />
    );
  }
}

export default connect(
  'selectRolesData',
  ArchRoleSelect,
);
