import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';

@Model()
export default class WebauthnCredentialRegistration extends ApplicationRecord {
  static jsonapiType = 'webauthn_credential_registrations';
  @Attr() data: string;
  @Attr() nickname: string;
}
