import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import Team from './team';
import TeamGroupAssignment from './team_group_assignment';
import Workstation from './workstation';
import WorkstationGroupAssignment from './workstation_group_assignment';

@Model()
export default class Group extends ApplicationRecord {
  static jsonapiType = 'groups';
  @Attr() name: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() enabled: boolean;
  @Attr() facilityId: string;
  @Attr() parsecTeamGroupId: string;
  @Attr() parsecTeamGroupName: string;

  @Attr() parsecRulesetId: string;
  @Attr() parsecRulesetName: string;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;

  @HasMany() teams: Team[];
  @HasMany() teamGroupAssignments: TeamGroupAssignment[];
  @HasMany() workstationGroupAssignments: WorkstationGroupAssignment[];
  @HasMany() workstations: Workstation[];

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  isEmpty() {
    return this.teams.length === 0;
  }

  isWorkstationsEmpty() {
    return this.workstations.length === 0;
  }
}
