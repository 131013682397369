import { BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ImagePipelineSync from './image_pipeline_sync';

@Model()
export default class ImagePipelineSyncCancellation extends ApplicationRecord {
  static jsonapiType = 'image_pipeline_sync_cancellations';

  @BelongsTo() imagePipelineSync: ImagePipelineSync;
}
