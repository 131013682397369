export default theme => ({
  avatar: {
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});
