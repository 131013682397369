import { Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  icon: {
    color: theme.palette.action.disabled,
    cursor: 'pointer',
    // verticalAlign: 'middle',

    '&:hover': {
      color: theme.palette.action.active,
    },
  },
  text: {
    display: 'inline-flex',
    alignItems: 'center',

    cursor: 'pointer',
  },
  link: {
    marginLeft: 10,
    display: 'block',
    height: 15,
  },
});

const CopyableText = ({ classes, className, textToCopy, displayText }) => {
  const [clipboardSuccess, setClipboardSuccess] = React.useState(false);

  const handleClose = () => {
    setClipboardSuccess(false);
  };

  const handleSuccess = () => {
    setClipboardSuccess(true);
  };

  return (
    <div className={cx(classes.text, className)}>
      {displayText}
      <Tooltip open={clipboardSuccess} onClose={handleClose} title="Copied">
        <a
          className={classes.link}
          onClick={() => {
            window.navigator.clipboard.writeText(textToCopy);
            handleSuccess();
          }}
        >
          <FileCopyIcon
            /* component="span" */
            style={{ fontSize: 15 }}
            className={classes.icon}
          />
        </a>
      </Tooltip>
    </div>
  );
};

CopyableText.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
};

export default withStyles(CopyableText, styles);
