import { Model, BelongsTo, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import FacilitySync from './facility_sync';

@Model()
export default class FacilitySyncCheck extends ApplicationRecord {
  static jsonapiType = 'facility_sync_checks';

  @Attr() state: string;
  @BelongsTo() facilitySync: FacilitySync;
}
