import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class ParsecRulesetQuery extends ApplicationRecord {
  static jsonapiType = 'parsec_ruleset_queries';
  @Attr() parsecRulesets: object[];

  @BelongsTo() facility: Facility;
}
