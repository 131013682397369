import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import Client from '../models/client';
import ClientUserAssignment from '../models/client_user_assignment';

export default {
  name: 'clientUserAssignments',
  getReducer: () => {
    const initialData = {
      data: null,
      meta: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      loadingActiveUser: null,
      errors: 0,
      page: 0,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_CLIENT_USER_ASSIGNMENTS_START') {
        return { ...state, loading: true };
      }

      if (type === 'FETCH_CLIENT_USER_ASSIGNMENTS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          meta: payload.meta,
        };
      }

      if (type === 'CHANGE_CLIENT_USER_ASSIGNMENTS_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }

      if (type === 'CHANGE_CLIENT_USER_ASSIGNMENTS_PAGE') {
        return {
          ...state,
          page: payload.page,
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
          activeUser: null,
        };
      }
      return state;
    };
  },

  doChangeClientUserAssignmentsSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_CLIENT_USER_ASSIGNMENTS_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchClientUserAssignments();
  },

  doChangeClientUserAssignmentsPage: page => async ({ dispatch, store }) => {
    dispatch({
      type: 'CHANGE_CLIENT_USER_ASSIGNMENTS_PAGE',
      payload: { page },
    });
    store.doFetchClientUserAssignments();
  },

  doFetchClientUserAssignments: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { page, sortBy, sortDirection } = state.clientUserAssignments;

    const client = store.selectActiveClient(state);

    dispatch({ type: 'FETCH_CLIENT_USER_ASSIGNMENTS_START' });
    const response = await ClientUserAssignment.order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    })
      .where({
        client_id: client.id,
        // client_user_assignments: { client_id: client.id },
        user_hours_stat: { client_id: client.id },
      })
      .includes([{ user: 'user_hours_stat' }])
      .per(25)
      .page(page)
      .stats({ total: 'count' })

      // .includes([
      //   'roles',
      //   { client_user_assignments: { invitation: 'team' } },
      //   'last_workstation_login',
      //   'user_hours_stat',
      // ])
      .all();
    dispatch({
      type: 'FETCH_CLIENT_USER_ASSIGNMENTS_SUCCESS',
      payload: { results: response.data, meta: response.meta },
    });
  },

  selectClientUserAssignmentSortBy: state => state.clientUserAssignments.sortBy,
  selectClientUserAssignmentSortDirection: state =>
    state.clientUserAssignments.sortDirection,
  selectClientUserAssignmentRaw: state => state.clientUserAssignments,

  selectClientUserAssignmentLoading: state =>
    state.clientUserAssignments.loading,
  selectClientUserAssignmentData: state =>
    state.clientUserAssignments.data || [],

  selectClientUserAssignmentMeta: state =>
    state.clientUserAssignments.meta || [],

  selectClientUserAssignmentPage: state =>
    state.clientUserAssignments.page || 1,

  reactShouldFetchClientUserAssignments: createSelector(
    'selectRouteApis',
    'selectClientUserAssignmentRaw',
    'selectAuthenticatedUser',
    'selectActiveClient',
    (apis, clientUserAssignmentData, authenticatedUser, activeClient) => {
      const wantsClientUserAssignments = apis.includes('clientUserAssignments');

      if (!wantsClientUserAssignments) return false;
      if (!activeClient) return false;
      if (!authenticatedUser) return false;
      if (!authenticatedUser) return false;

      if (clientUserAssignmentData.loading || clientUserAssignmentData.data) {
        return false;
      }
      if (!authenticatedUser.isAuthorized('ui.users.view')) {
        return false;
      }
      return { actionCreator: 'doFetchClientUserAssignments' };
    },
  ),
};
