import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';

import { CheckboxWithLabel, TextField, Select } from 'formik-mui';
import { Field, Form, Formik, useFormikContext } from 'formik';

import { Skeleton } from '@mui/lab';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import React from 'react';
import * as yup from 'yup';

import { Breadcrumb } from '../../organisms/Breadcrumbs';
import { baseUrl } from '../../../shared/utils';
import ArchPageContent from '../../molecules/ArchPageContent';
import ArchParsecGroupSelect from '../../organisms/ArchParsecGroupSelect';
import ArchParsecRulesetSelect from '../../organisms/ArchParsecRulesetSelect';
import Client from '../../../models/client';
import Facility from '../../../models/facility';
import FacilityBreadcrumbs from '../../organisms/FacilityBreadcrumbs';
import PageTitle from '../../atoms/PageTitle';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
});

export const mapErrorsToFormik = errors =>
  Object.keys(errors).reduce(
    (memo, key) => ({ ...memo, [key]: errors[key].message }),
    {},
  );

const NewFileSystem = ({
  classes,
  activeClient,
  activeFacility,
  baseUrl,
  doCreateFileSystem,
  doUpdateUrl,
}) => {
  // const {
  //   values: { fileSystemType, throughput, storageSize },
  //   setFieldValue,
  // } = useFormikContext();

  const fileSystemDefaults = [
    { type: 'WINDOWS', size: 1024, throughput: 128 },
    { type: 'ONTAP', size: 1024, throughput: 256 },
  ];

  // React.useEffect(() => {
  //   if (!fileSystemType) {
  //     return;
  //   }
  //   const currentOption = fileSystemDefaults.find(
  //     d => d.name === fileSystemType.name,
  //   );
  //   setFieldValue('throughput', currentOption.throughput);
  //   setFieldValue('size', currentOption.size);
  // }, [fileSystemType]);
  //
  const handleFileSystemTypeChange = (fileSystemType, setFieldValue) => {
    console.log('fileSystemType', fileSystemType);
    const currentOption = fileSystemDefaults.find(
      d => d.type === fileSystemType,
    );
    console.log('currentOption', currentOption);
    console.log('setFieldValue', setFieldValue);
    setFieldValue('throughput', currentOption.throughput);
    setFieldValue('storageSize', currentOption.size);
  };

  if (!activeClient || !activeFacility) {
    return (
      <ArchPageContent>
        <Container>
          <Skeleton />
        </Container>
      </ArchPageContent>
    );
  }

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const fileSystem = await doCreateFileSystem(values);

      setSubmitting(false);
      if (fileSystem.isPersisted) {
        doUpdateUrl(`${baseUrl}/storage/file_systems/${fileSystem.id}`);
      } else {
        setErrors(mapErrorsToFormik(fileSystem.errors));
        setSubmitting(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>New File System</PageTitle>
      <FacilityBreadcrumbs>
        <Breadcrumb
          to={`/clients/${activeClient.id}/facilities/${activeFacility.id}/storage`}
        >
          Storage
        </Breadcrumb>
        <Breadcrumb
          to={`/clients/${activeClient.id}/facilities/${activeFacility.id}/storage/file_systems/new`}
        >
          New File System
        </Breadcrumb>
      </FacilityBreadcrumbs>
      <ArchPageContent>
        <Container>
          <Formik
            enableReinitialize
            initialValues={{
              name: '',
              fileSystemType: 'WINDOWS',
              throughput: 128,
              storageSize: 1024,
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required('Name is a required field'),
            })}
            onSubmit={handleSubmit}
          >
            {({ isValid, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Add File System
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      File System Properties
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <Field
                          name="name"
                          label="Name"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        />

                        <Field
                          name="fileSystemType"
                          label="File System Type"
                          component={TextField}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          select
                          onChange={e => {
                            setFieldValue('fileSystemType', e.target.value);
                            handleFileSystemTypeChange(
                              e.target.value,
                              setFieldValue,
                            );
                          }}
                        >
                          <MenuItem value="WINDOWS">Windows</MenuItem>
                          <MenuItem value="ONTAP">OnTap</MenuItem>
                        </Field>

                        <div>
                          <Field
                            type="number"
                            name="throughput"
                            label="Throughput"
                            component={TextField}
                            margin="normal"
                            variant="outlined"
                          />
                        </div>

                        <div>
                          <Field
                            type="number"
                            name="storageSize"
                            label="StorageSize"
                            component={TextField}
                            margin="normal"
                            variant="outlined"
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.button}
                        component="a"
                        color="inherit"
                        href={`${baseUrl}/storage`}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!isValid}
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </ArchPageContent>
    </>
  );
};

NewFileSystem.propTypes = {
  doUpdateUrl: PropTypes.func.isRequired,
  doCreateTemplate: PropTypes.func.isRequired,
  activeClient: PropTypes.instanceOf(Client),
  activeFacility: PropTypes.instanceOf(Facility),
};

NewFileSystem.defaultProps = {
  activeClient: null,
  activeFacility: null,
};

export default connect(
  'doUpdateUrl',
  'selectActiveClient',
  'selectActiveFacility',
  'selectBaseUrl',
  'doCreateFileSystem',
  withStyles(NewFileSystem, styles, { withTheme: true }),
);
