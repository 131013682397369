import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Facility from './facility';
import FileUploadFile from './file_upload_file';
import User from './user';
import WebauthnChallenge from './webauthn_challenge';

@Model()
export default class FileUploadDestination extends ApplicationRecord {
  static jsonapiType = 'file_upload_destinations';

  @Attr() name: string;
  @Attr() bucket: string;
  @Attr() enableTransferAcceleration: boolean;
  @Attr() requireWebauthnToUpload: boolean;
  @Attr() datasyncTaskArn: string;
  @Attr() storageGatewayShareArn: string;
  @Attr() bucketPrefix: string;

  @BelongsTo() client: Client;
  @BelongsTo() facility: Facility;
  @BelongsTo() performedBy: User;

  @BelongsTo() webauthnChallenge: WebauthnChallenge;
}
