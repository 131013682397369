import { createSelector } from 'redux-bundler';

import AvailabilityZone from '../models/availability_zone';

export default {
  name: 'availabilityZones',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_AVAILABILITY_ZONES') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_AVAILABILITY_ZONES_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          items: null,
        };
      }

      return state;
    };
  },

  doFetchAvailabilityZones: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    dispatch({ type: 'FETCH_AVAILABILITY_ZONES' });
    const scope = AvailabilityZone.where({
      facility_id: facility.id,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_AVAILABILITY_ZONES_SUCCESS',
      payload: { result: response.data },
    });
  },

  reactShouldFetchAvailabilityZones: createSelector(
    'selectRouteApis',
    'selectAvailabilityZonesRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    (apis, availabilityZonesRaw, currentUser, activeClient, activeFacility) => {
      const wantsAvailabilityZones = apis.includes('availabilityZones');
      if (
        !wantsAvailabilityZones ||
        availabilityZonesRaw.loading ||
        availabilityZonesRaw.items ||
        !currentUser ||
        !activeClient ||
        !activeFacility
      ) {
        return false;
      }
      return { actionCreator: 'doFetchAvailabilityZones' };
    },
  ),

  selectAvailabilityZonesRaw: state => state.availabilityZones,
  selectAvailabilityZones: state => state.availabilityZones.items,
};
