import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
  dsn:
    'https://3aa87544a3be43349c2eb9e446e54bf3@o346680.ingest.sentry.io/5879662',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: `${process.env.REACT_APP_SENTRY_RELEASE_PREFIX}${process.env.REACT_APP_COMMIT_REF}`,
});
