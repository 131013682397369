import { Model, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';

@Model()
export default class PusherConfiguration extends ApplicationRecord {
  static jsonapiType = 'pusher_configurations';

  @Attr() key: string;
  @Attr() cluster: string;
  @Attr() authEndpoint: string;
}
