import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

@Model()
export default class WorkstationManeAgentUpdate extends ApplicationRecord {
  static jsonapiType = 'workstation_mane_agent_updates';
  @Attr() maneAgentUrl: string;

  @BelongsTo() workstation: Workstation;
}
