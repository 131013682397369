import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ImagePipeline from './image_pipeline';
import Region from './region';

@Model()
export default class ImagePipelineRegion extends ApplicationRecord {
  static jsonapiType = 'image_pipeline_regions';

  @Attr({ persist: false }) selected: boolean;
  @Attr({ persist: false }) regionCode: string;

  @BelongsTo() region: Region;
  @BelongsTo() imagePipeline: ImagePipeline;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
