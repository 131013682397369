import { green } from '@mui/material/colors';
import { Mutex } from 'async-mutex';
import moment from 'moment';

export default class ManeAuth {
  static _currentUser: any;
  static _expiresAt: any;
  static _oauthClient: any;
  static _csrf: any;
  static mutex = new Mutex();

  static async getCurrentUser(): Promise<any> {
    // if (!this._currentUser) {
    //   const result = await fetch(
    //     `${process.env.REACT_APP_API_BASE_URL}/auth/authenticate`,
    //   );
    //   const user = await result.json();
    //   this._currentUser = user;
    // }
    return this._currentUser;
  }

  static setOauthClient(client: any) {
    this._oauthClient = client;
  }

  static setExpiresAt(expiresAt: any, setByUs: boolean = false) {
    this._expiresAt = expiresAt;

    const now = Date.now().valueOf() / 1000;
    if (setByUs) {
      console.log(
        `%cNew Token Expiry ${this._expiresAt} ${moment
          .unix(this._expiresAt)
          .format('h:mm:ss A')}. ${Math.round(this._expiresAt - now)}s`,
        `color: ${green[500]}`,
      );
    } else {
      console.log(
        `%cReceived Token Expiry ${this._expiresAt} ${moment
          .unix(this._expiresAt)
          .format('h:mm:ss A')}. ${Math.round(this._expiresAt - now)}s`,
        `color: ${green[500]}`,
      );
    }
  }

  static setCsrf(csrf: any) {
    this._csrf = csrf;
  }

  static getCsrf() {
    return this._oauthClient.getAntiForgeryToken();
  }

  static async logout(): Promise<any> {
    try {
      const url = await this._oauthClient.logout();

      window.location = url;
    } catch (err) {
      console.log('Error logging out', err);
      // window.location = '';
    }
  }

  static async refresh(): Promise<any> {
    const now = Date.now().valueOf() / 1000;

    const sleep = (milliseconds: any) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds));
    };

    return await this.mutex.runExclusive(async () => {
      if (this._expiresAt < now) {
        console.log(
          `%cToken has expired ${this._expiresAt} ${moment
            .unix(this._expiresAt)
            .format('h:mm:ss A')}. Requesting refresh`,
          'color: #F00',
        );
        console.log('Waiting to acquire lock');
        await navigator.locks.request('my_resource', async lock => {
          console.log('Lock acquired');
          if (!(this._expiresAt < now)) {
            console.log(`%cExpiry was already renewed. `, 'color: #F00');
            return;
          }

          const expiresAt = await this._oauthClient.refresh();
          this.setExpiresAt(expiresAt, true);

          const newNow = Date.now().valueOf() / 1000;

          localStorage.setItem('newExpiry', expiresAt);
          await sleep(200);

          console.log('Releasing lock');
        });
      }
    });
  }

  static setCurrentUser(user: any) {
    this._currentUser = user;
  }
}
