import { Attr, HasOne, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Facility from './facility';

@Model()
export default class Search extends ApplicationRecord {
  static jsonapiType = 'searches';
  @Attr() query: string;
  @Attr() queryType: string;
  @Attr() results: object[];
  @Attr() facilityId: string;

  @HasOne() client: Client;

  hasResults(): boolean {
    return this.results.length > 0;
  }
}
