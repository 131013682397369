import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import WorkstationDeletion from './workstation_deletion';

@Model()
export default class WorkstationDeletionCheck extends ApplicationRecord {
  static jsonapiType = 'workstation_deletion_checks';
  @Attr() state: string;

  @BelongsTo() workstationDeletion: WorkstationDeletion;
}
