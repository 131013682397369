import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { Skeleton } from '@material-ui/lab';
import { connect } from 'redux-bundler-react';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';

import DropdownToggler from '../DropdownToggler';
import FacilityAvatar from '../../organisms/ArchDrawerMenu/FacilityAvatar';

const menuStyles = theme => ({
  paper: {
    padding: 0,
    borderRadius: 0,
    minWidth: 300,
    width: '20%',
  },
});

const StyledMenu = withStyles(
  props => (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      elevation={4}
      {...props}
    />
  ),
  menuStyles,
  { withTheme: true },
);

const styles = theme => ({
  avatarIcon: {
    minWidth: theme.spacing(4),
  },
});

const FacilitySwitcher = ({
  activeOrNextFacility,
  route,
  privacyMode,
  activeClient,
  doSetNextFacility,
  skeleton,
  classes,
}) => {
  let showSkeletons = skeleton || !activeOrNextFacility;
  const facilityMenuAnchorRef = React.useRef(null);
  const [facilityMenuOpen, setFacilityMenuOpen] = React.useState(false);

  const facilitySwitchablePaths = [
    'workstations',
    'compute',
    'groups',
    'storage',
    'templates',
    'images',
    'image_pipelines',
    'chef_packages',
    'licenses',
    'users',
    'teams',
    'accounts',
  ];
  const facilityPath =
    facilitySwitchablePaths.indexOf(route.drawItem) > -1
      ? `/${route.drawItem}`
      : '';

  return (
    <>
      <DropdownToggler
        clickRef={facilityMenuAnchorRef}
        onClick={e => setFacilityMenuOpen(true)}
        title={
          showSkeletons ? (
            <Skeleton width="200" />
          ) : (
            activeOrNextFacility.nameOrFake(privacyMode)
          )
        }
      />
      <StyledMenu
        anchorEl={facilityMenuAnchorRef.current}
        open={facilityMenuOpen}
        onClose={() => setFacilityMenuOpen(false)}
      >
        {activeClient.facilities.map(facility => (
          <MenuItem
            key={facility.id}
            component="a"
            href={`/clients/${activeClient.id}/facilities/${facility.id}${facilityPath}`}
            onClick={() => {
              setFacilityMenuOpen(false);
              if (facility.id !== activeOrNextFacility?.id) {
                doSetNextFacility(facility);
              }
            }}
          >
            <ListItemIcon className={classes.avatarIcon}>
              <FacilityAvatar
                facility={facility}
                size={32}
                square={true}
                text={false}
                small
              />
            </ListItemIcon>
            <ListItemText>
              {facility.id === activeOrNextFacility?.id ? (
                <strong>{facility.name}</strong>
              ) : (
                facility.name
              )}
            </ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default connect(
  'selectActiveOrNextFacility',
  'selectActiveClient',
  'selectRoute',
  'selectPrivacyMode',
  'doSetNextFacility',
  withStyles(FacilitySwitcher, styles, { withTheme: true }),
);
