import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import InstanceTypeOffering from './instance_type_offering';

@Model()
export default class AvailabilityZone extends ApplicationRecord {
  static jsonapiType = 'availability_zones';
  @Attr() name: string;
  @Attr() identifier: string;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;
  @HasMany() instanceTypeOfferings: InstanceTypeOffering[];

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
