import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  MenuItem,
} from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Skeleton } from '@mui/lab';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import React from 'react';
import * as yup from 'yup';

import ArchPageContent from '../../molecules/ArchPageContent';
import Breadcrumbs, { Breadcrumb } from '../../organisms/Breadcrumbs';
import PageTitle from '../../atoms/PageTitle';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  halfWidthField: {
    width: '50%',
    '&:first-child': {
      paddingRight: 16,
    },
    '&:last-child': {
      paddingLeft: 16,
    },
  },
  checkboxesGroup: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
});

export const mapErrorsToFormik = errors =>
  Object.keys(errors).reduce(
    (memo, key) => ({ ...memo, [key]: errors[key].message }),
    {},
  );

const EditAccount = ({
  doUpdateAccount,
  classes,
  baseUrl,
  activeClient,
  activeAccount,
  doUpdateUrl,
}) => {
  // const { setFieldValue, values } = useFormikContext();

  // React.useEffect(() => {
  //   if (values.accessType === 'access_via_api_gateway') {
  //     // Set default value for another field
  //     console.log('Setting default value for endpoints');
  //   }
  // }, [values.accessType, setFieldValue]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const account = await doUpdateAccount(values);

      setSubmitting(false);
      console.log(account);
      if (account.isPersisted) {
        doUpdateUrl(`${baseUrl}/accounts/${account.id}`);
      } else {
        setErrors(mapErrorsToFormik(account.errors));
        setSubmitting(false);
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  const placeholderEndpoints = {
    facility: 'https://xxx.execute-api.us-west-2.amazonaws.com',
    images: 'https://yyy.execute-api.us-west-2.amazonaws.com',
    workstation: 'https://zzz.execute-api.us-west-2.amazonaws.com',
  };

  if (!activeClient) {
    return (
      <ArchPageContent>
        <Container>
          <Skeleton />
        </Container>
      </ArchPageContent>
    );
  }
  return (
    <>
      <PageTitle>Edit Account</PageTitle>
      <Breadcrumbs>
        <Breadcrumb to={`${baseUrl}/account`}>Accounts</Breadcrumb>
        <Breadcrumb>Edit</Breadcrumb>
      </Breadcrumbs>
      <ArchPageContent>
        <Container>
          <Formik
            enableReinitialize
            initialValues={{
              accessType: activeAccount.accessType || '',
              name: activeAccount.name || '',
              email: activeAccount.email || '',
              accountId: activeAccount.accountId || '',
              roleArn: activeAccount.roleArn || '',
              kmsKeyId: activeAccount.kmsKeyId || '',
              enableLeastPrivilegeIam:
                activeAccount.enableLeastPrivilegeIam || false,
              enableLambdaPermissionBoundary:
                activeAccount.enableLambdaPermissionBoundary || false,
              stackPrefix: activeAccount.stackPrefix || '',
              secretPrefix: activeAccount.secretPrefix || '',
              partOfOrganisation: activeAccount.partOfOrganisation || false,
              accessType: activeAccount.accessType || 'access_via_iam',
              endpoints: JSON.stringify(activeAccount.endpoints || {}),
              customPermissionsBoundary:
                activeAccount.customPermissionsBoundary,
            }}
            validationSchema={yup.object().shape({
              name: yup.string().required(),
              email: yup
                .string()
                .required()
                .email(),
              accountId: yup.string().required(),
            })}
            onSubmit={handleSubmit}
            onChange={values => {
              console.log(values);
            }}
          >
            {({ isValid, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Edit Account
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      Account Overview
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <Field
                          name="accountId"
                          label="Account ID"
                          component={TextField}
                          fullWidth
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                          placeholder="000000000000"
                        />
                        <Field
                          name="name"
                          label="Name"
                          component={TextField}
                          fullWidth
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                        />
                        <Field
                          name="email"
                          label="Email"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          placeholder="aws@example.com (optional)"
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      Account Configuration
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <div className={classes.checkboxesGroup}>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="partOfOrganisation"
                            Label={{
                              label: 'Arch Managed Account',
                            }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="enableLeastPrivilegeIam"
                            Label={{
                              label: 'Enable Least Privilege IAM Mode',
                            }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="enableLambdaPermissionBoundary"
                            Label={{
                              label: 'Enable Lambda Permission Boundaries',
                            }}
                          />
                        </div>

                        <Field
                          component={TextField}
                          select
                          name="accessType"
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Access Type"
                          onChange={e => {
                            const value = e.target.value;
                            setFieldValue('accessType', value);

                            // Set default value for anotherField when access_via_api_gateway is selected
                            const endpointsIsBlank =
                              values.endpoints === '{}' || !values.endpoints;
                            console.log(endpointsIsBlank);
                            if (
                              (value === 'access_via_api_gateway' ||
                                value === 'access_via_api_gateway_and_iam') &&
                              endpointsIsBlank
                            ) {
                              setFieldValue(
                                'endpoints',
                                JSON.stringify(placeholderEndpoints, null, 2),
                              );
                              /* setFieldValue('anotherField', 'default_value'); */
                            }
                          }}
                        >
                          <MenuItem value={'access_via_iam'}>
                            <div className={classes.justify}>
                              <span className={classes.name}>
                                Cross Account IAM
                              </span>
                            </div>
                          </MenuItem>

                          <MenuItem value={'access_via_api_gateway'}>
                            <div className={classes.justify}>
                              <span className={classes.name}>API Gateway</span>
                            </div>
                          </MenuItem>

                          <MenuItem value={'access_via_api_gateway_and_iam'}>
                            <div className={classes.justify}>
                              <span className={classes.name}>
                                API Gateway + Cross Account IAM
                              </span>
                            </div>
                          </MenuItem>
                        </Field>

                        {(values.accessType === 'access_via_api_gateway' ||
                          values.accessType ===
                            'access_via_api_gateway_and_iam') && (
                          <Field
                            name="endpoints"
                            label="Endpoints"
                            component={TextField}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            placeholder={JSON.stringify(
                              placeholderEndpoints,
                              null,
                              2,
                            )}
                            multiline
                          />
                        )}

                        <Field
                          name="customPermissionsBoundary"
                          label="Custom Permissions Boundary"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          placeholder="arn:aws:iam::000000000000:policy/MyCustomPolicy"
                        />
                        <Field
                          name="roleArn"
                          label="Role Arn"
                          helperText="Role Arn is required for Arch managed accounts to enable automatic IAM Role deployment"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                        <Field
                          name="kmsKeyId"
                          label="KMS Key Arn"
                          component={TextField}
                          helperText="If a KMS Key is not provided, one will be generated for each facility. Seperate multiple keys with commas"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                        <Field
                          name="secretPrefix"
                          label="Secret Prefix"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                        <Field
                          name="stackPrefix"
                          label="Stack Prefix"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.button}
                        component="a"
                        color="inherit"
                        href={`${baseUrl}/accounts/${activeAccount.id}`}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!isValid}
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </ArchPageContent>
    </>
  );
};

// EditAccount.propTypes = {
//   baseUrl: PropTypes.string.isRequired,
//   activeClient: PropTypes.instanceOf(Client).isRequired,
//   doUpdateUrl: PropTypes.func.isRequired,
//   doUpdateAccount: PropTypes.func.isRequired,
// };

export default connect(
  'doUpdateUrl',
  'doUpdateAccount',
  'selectActiveClient',
  'selectBaseUrl',
  'selectActiveAccount',
  withStyles(EditAccount, styles, { withTheme: true }),
);
