import { darken } from '@mui/material';

export default theme => ({
  appBarColor: {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : '#464748',
  },

  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1,
  },

  logo: {
    height: '44px',
    marginLeft: -2,
    paddingRight: 20,

    [theme.breakpoints.down('sm')]: {
      height: '40px',
      width: '60px',
      objectFit: 'cover',
      objectPosition: 'left',
    },
  },
  appBarShift: {
    zIndex: 1300,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  toolbar: {
    '&.MuiToolbar-root': {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(3),
    },
  },

  search: {
    padding: 5,
    // color: 'white',
    borderColor: darken('#FFF', 0.4),
    marginRight: theme.spacing(2),
    '&:hover': {
      borderColor: darken('#FFF', 0.1),
    },
    transition: 'right 0.25s ease-in-out',
    position: 'absolute',
  },

  searchIcon: {
    transition: 'right 0.25s ease-in-out',
    position: 'absolute',
  },

  searchText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  key: {
    backgroundColor: darken('#FFF', 0.4),
    marginLeft: theme.spacing(3),
    border: `1px solid rgba(255, 255, 255, 0.23)`,
    fontFamily:
      "source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace",
    fontSize: '12px',
    borderRadius: 4,
    paddingLeft: 4,
    paddingRight: 4,
    textTransform: 'none',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});
