import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import User from './user';

@Model()
export default class WorkstationEventFeedEntry extends ApplicationRecord {
  static jsonapiType = 'workstation_event_feed_entries';
  @Attr() status: string;
  @Attr() createdAt: string;
  @Attr() eventableType: string;
  @Attr() eventableId: string;
  @Attr() workstationId: string;
  @Attr() timestamp: number;
  @Attr() metadata: object;

  @Attr() name: string;
  @Attr() initials: string;
  @Attr() avatarImageUrl: string;

  @BelongsTo() performedBy: User;

  fullName(fake: Boolean): string {
    if (fake) {
      return `${this.fakeFirstName()} ${this.fakeLastName()}`;
    }
    return this.name;
  }

  avatar(fake: Boolean) {
    if (fake) {
      return User.fakeAvatar(this.name);
    }
    return this.avatarImageUrl;
  }

  fakeFirstName() {
    return User.fakeFirstName(this.name);
  }

  fakeLastName() {
    return User.fakeLastName(this.name);
  }
}
