import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Facility from './facility';
import User from './user';

@Model()
export default class FileUploadFile extends ApplicationRecord {
  static jsonapiType = 'file_upload_files';

  @Attr() filename: string;
  @Attr() size: number;
  @Attr() status: string;
}
