import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Image from './image';

@Model()
export default class ImageApproval extends ApplicationRecord {
  static jsonapiType = 'image_approvals';

  @BelongsTo() image: Image;
}
