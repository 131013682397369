import { createSelector } from 'redux-bundler';
import * as WebAuthnJSON from '@github/webauthn-json';

import FileUpload from '../models/file_upload';
import FileUploadDestination from '../models/file_upload_destination';
import WebauthnChallenge from '../models/webauthn_challenge';
import WebauthnChallengeResponse from '../models/webauthn_challenge_response';

export default {
  name: 'fileUploadDestinations',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_FILE_UPLOAD_DESTINATIONS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_FILE_UPLOAD_DESTINATIONS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          scope: payload.scope,
        };
      }
      if (type === 'CHANGE_FILE_UPLOAD_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_FILE_UPLOAD' && state.data) {
        return { ...state, data: [...state.data, payload.fileUpload] };
      }
      if (type === 'UPDATE_FILE_UPLOAD') {
        if (state.data) {
          const index = state.data.findIndex(
            u => u.id === payload.fileUpload.id,
          );
          const { fileUpload } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([fileUpload])
              .concat(state.data.slice(index + 1)),
            activeFileUpload:
              state.activeFileUpload.id === payload.fileUpload.id
                ? fileUpload
                : state.activeFileUpload,
          };
        }

        return {
          ...state,
          activeFileUpload: payload.fileUpload,
        };
      }
      if (type === 'TOGGLE_FILE_UPLOAD_SELECTION') {
        const index = state.data.findIndex(u => u.id === payload.fileUpload.id);
        const { fileUpload } = payload;
        fileUpload.selected = !fileUpload.selected;
        fileUpload.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([fileUpload])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_FILE_UPLOAD') {
        const index = state.data.findIndex(u => u.id === payload.fileUpload.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FILE_UPLOAD') {
        return {
          ...state,
          activeFileUpload: null,
        };
      }

      if (type === 'RESET_FILE_UPLOAD_DESTINATIONS') {
        return {
          ...state,
          data: null,
          scope: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      return state;
    };
  },

  doResetFileUploadDestinations: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_FILE_UPLOAD_DESTINATIONS' });
  },

  doResetActiveFileUpload: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_ACTIVE_FILE_UPLOAD' });
  },

  doFetchFileUploadDestinations: () => async ({
    dispatch,
    getState,
    store,
  }) => {
    console.log('FETCHING FILE UPLOAD DESTINATIONS');
    const state = getState();

    const client = store.selectActiveClient(state);
    const facility = store.selectActiveFacility(state);

    dispatch({ type: 'FETCH_FILE_UPLOAD_DESTINATIONS_START' });
    const response = await FileUploadDestination.where({
      facility_id: facility.id,
    }).all();
    dispatch({
      type: 'FETCH_FILE_UPLOAD_DESTINATIONS_SUCCESS',
      payload: { results: response.data, scope: 'client' },
    });
  },

  doCreateFileUploadDestination: values => async ({
    dispatch,
    store,
    state,
  }) => {
    const webauthnChallenge = new WebauthnChallenge();
    await webauthnChallenge.save();
    const json = JSON.parse(atob(webauthnChallenge.data));

    const credential = await WebAuthnJSON.get({ publicKey: json });

    const response = new WebauthnChallengeResponse({
      data: JSON.stringify(credential),
      webauthnChallenge: webauthnChallenge,
    });
    await response.save({ with: ['webauthnChallenge.id'] });

    const facility = store.selectActiveFacility(state);
    const client = store.selectActiveClient(state);

    const fileUploadDestination = new FileUploadDestination({
      name: values.name,
      bucket: values.bucket,
      datasyncTaskArn: values.datasyncTaskArn,
      storageGatewayShareArn: values.storageGatewayShareArn,
      enableTransferAcceleration: values.enableTransferAcceleration,
      facility,
      client,
      webauthnChallenge,
    });

    const success = await fileUploadDestination.save({
      with: ['facility.id', 'client.id', 'webauthnChallenge.id'],
    });
    if (success) {
      dispatch({
        type: 'ADD_FILE_UPLOAD_DESTINATION',
        payload: { fileUploadDestination },
      });
    }
    return fileUploadDestination;
  },

  doDestroyFileUpload: fileUpload => async ({ dispatch }) => {
    await fileUpload.destroy();
    dispatch({ type: 'REMOVE_FILE_UPLOAD', payload: { fileUpload } });
  },

  // doUpdateFileUploadDestination: values => async ({ dispatch, store }) => {
  //   const {
  //     fileUploadDestinations: { activeFileUploadDestination },
  //   } = store.getState();

  //   const fileUpload = activeFileUpload.dup();
  //   console.log(values);
  //   const { parsecRuleset, parsecTeamFileUpload, ...nextValues } = values;

  //   fileUpload.assignAttributes({
  //     ...nextValues,
  //     parsecTeamFileUploadId: parsecTeamFileUpload?.id,
  //     parsecTeamFileUploadName: parsecTeamFileUpload?.name,

  //     parsecRulesetId: parsecRuleset?.id,
  //     parsecRulesetName: parsecRuleset?.name,
  //   });

  //   const success = await fileUpload.save();
  //   if (success) {
  //     dispatch({
  //       type: 'UPDATE_FILE_UPLOAD',
  //       payload: { fileUpload },
  //     });
  //   }
  //   return fileUpload;
  // },

  selectFileUploadDestinationSortBy: state =>
    state.fileUploadDestinations.sortBy,
  selectFileUploadDestinationSortDirection: state =>
    state.fileUploadDestinations.sortDirection,
  selectFileUploadDestinationLoading: state =>
    state.fileUploadDestinations.loading,
  selectFileUploadDestinationRaw: state => state.fileUploadDestinations,
  selectFileUploadDestinationData: state =>
    state.fileUploadDestinations.data || [],

  reactShouldFetchFileUploadDestinations: createSelector(
    'selectRouteApis',
    'selectFileUploadDestinationRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    (
      apis,
      fileUploadDestinationData,
      currentUser,
      activeClient,
      activeFacility,
    ) => {
      const wantsFileUploadDestinations = apis.includes(
        'fileUploadDestinations',
      );

      console.log(
        'REACT SHOULD FETCH FILE UPLOAD DESTINATIONS',
        wantsFileUploadDestinations,
      );

      if (
        !wantsFileUploadDestinations ||
        fileUploadDestinationData.loading ||
        fileUploadDestinationData.data ||
        !currentUser ||
        !activeClient ||
        !activeFacility
      ) {
        return false;
      }
      return { actionCreator: 'doFetchFileUploadDestinations' };
    },
  ),
};
