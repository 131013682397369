import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Group from './group';
import Workstation from './workstation';

@Model()
export default class WorkstationGroupAssignment extends ApplicationRecord {
  static jsonapiType = 'workstation_group_assignments';
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() workstation: Workstation;
  @BelongsTo() group: Group;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
