import { withStyles } from 'tss-react/mui';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React from 'react';
import cx from 'classnames';
import { green } from '@mui/material/colors';

const styles = theme => ({
  requirement: {
    marginTop: theme.spacing(1),
    display: 'flex',
    color: theme.palette.text.secondary,

    alignItems: 'center',
  },
  requirementIcon: {
    marginRight: theme.spacing(1),
  },
  valid: {
    color: green[500],
  },
});

const checks = {
  length: password => password.length >= 8,
  uppercase: password => password.match(/[A-Z]/),
  lowercase: password => password.match(/[a-z]/),
  punctuation: password =>
    password.match(/[!"\#$%&'()*+, \-./:;<=>?@\[\\\]^_‘{|}~]/),
  number: password => password.match(/[0-9]/),
  special: password => password.match(/(?![A-Z])(?![a-z])[\p{L}\p{Nl}]/u),
};

const optionalChecks = [
  'uppercase',
  'lowercase',
  'punctuation',
  'number',
  'special',
];

const PasswordTip = withStyles(
  ({ classes, children, check, password, onCheck }) => {
    const ticked = checks[check](password);
    const optional = optionalChecks.indexOf(check) > -1;
    const satisfiedCount = optionalChecks.reduce(
      (a, c) => a + (checks[c](password) ? 1 : 0),
      0,
    );

    const valid = satisfiedCount >= 3 && checks['length'](password);

    React.useEffect(() => {
      if (onCheck) {
        onCheck(!!valid);
      }
    }, [!!valid]);

    const hidden = !ticked && optional && satisfiedCount >= 3;
    if (hidden) return null;
    return (
      <div className={classes.requirement}>
        {ticked ? (
          <CheckCircleIcon
            className={cx(classes.requirementIcon, valid && classes.valid)}
            fontSize="small"
          />
        ) : (
          <RadioButtonUncheckedIcon
            className={classes.requirementIcon}
            fontSize="small"
          />
        )}
        {children}
      </div>
    );
  },
  styles,
);

export default PasswordTip;
