import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import WebauthnChallenge from './webauthn_challenge';

@Model()
export default class WebauthnChallengeResponse extends ApplicationRecord {
  static jsonapiType = 'webauthn_challenge_responses';
  @Attr() data: string;

  @BelongsTo() webauthnChallenge: WebauthnChallenge;
}
