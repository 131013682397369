import { Attr, BelongsTo, Model } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import User from './user';
import Workstation from './workstation';

@Model()
export default class WorkstationConnection extends ApplicationRecord {
  static jsonapiType = 'workstation_connections';
  @Attr() active: boolean;
  @Attr() createdAt: string;
  @Attr() disconnectedAt: string;
  @Attr() screenCount: string;
  @Attr() screens: string[];

  @BelongsTo() workstation: Workstation;
  @BelongsTo() user: User;

  formattedCreatedAt(prefix = '') {
    return this.createdAt
      ? `${prefix}: ${moment
          .utc(this.createdAt)
          .local()
          .calendar()}`
      : '';
  }
  formattedDisconnectedAt(prefix = '') {
    return this.disconnectedAt
      ? `${prefix}: ${moment
          .utc(this.disconnectedAt)
          .local()
          .calendar()}`
      : '';
  }
}
