import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Facility from './facility';
import FileUploadDestination from './file_upload_destination';
import FileUploadFile from './file_upload_file';
import User from './user';
import WebauthnChallenge from './webauthn_challenge';

@Model()
export default class FsxFileSystem extends ApplicationRecord {
  static jsonapiType = 'fsx_file_systems';

  @Attr() name: string;
  @Attr() identifier: string;
  @Attr() storageSize: number;
  @Attr() throughput: number;
  @Attr() profileMetrics: object;
  @Attr() utilizationMetrics: object;
  @Attr() hostname: string;
  @Attr() fileSystemType: string;
  @Attr() profile: boolean;
  @Attr() state: string;

  @BelongsTo() facility: Facility;
}
