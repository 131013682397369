import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import AvailabilityZone from './availability_zone';
import ChefPackage from './chef_package';
import Client from './client';
import Facility from './facility';
import Image from './image';
import ImageDefinition from './image_definition';
import ImagePipelineRegion from './image_pipeline_region';
import ImagePipelineSync from './image_pipeline_sync';
import InstanceType from './instance_type';
import Keypair from './keypair';
import Subnet from './subnet';
import Template from './template';
import Vpc from './vpc';

@Model()
export default class ImagePipeline extends ApplicationRecord {
  static jsonapiType = 'image_pipelines';
  @Attr() name: string;
  @Attr({ persist: false }) facilityId: string;
  @Attr() description: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() role: string;
  @Attr() ebsRaid: boolean;
  @Attr() enableFastLaunch: boolean;
  @Attr() autobuild: boolean;
  @Attr() platform: string;
  @Attr() sourceAmi: string;
  @Attr() sourceImagePipelineId: string;
  @Attr() runListLength: number;
  @Attr() ebsSize: number;
  @Attr() requireApproval: boolean;
  @Attr() enableAutoClean: boolean;
  @Attr() workingDirectory: string;
  @Attr() kmsKeyId: string;
  @Attr() osVersion: string;
  @Attr() enableTemporaryBuildDisk: boolean;
  @Attr() customTags: object[];
  @Attr() customUserData: string;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;
  @BelongsTo() client: Client;
  @BelongsTo() chefPackage: ChefPackage;
  @BelongsTo() instanceType: InstanceType;
  @BelongsTo() subnet: Subnet;
  @BelongsTo() vpc: Vpc;
  @BelongsTo() availabilityZone: AvailabilityZone;
  @BelongsTo() keypair: Keypair;
  @BelongsTo() sourceImagePipeline: ImagePipeline;
  @HasMany() downstreamImagePipelines: ImagePipeline[];
  @HasMany() templates: Template[];
  @HasMany() imagePipelineRegions: ImagePipelineRegion[];

  @HasOne() imageDefinition: ImageDefinition;
  @HasOne() lastImagePipelineSync: ImagePipelineSync;
  @HasOne() lastImage: Image;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }

  isSyncPending() {
    return this.lastImagePipelineSync && this.lastImagePipelineSync.isPending();
  }

  isSyncStarted() {
    return this.lastImagePipelineSync && this.lastImagePipelineSync.isStarted();
  }

  isSyncInProgress() {
    return (
      (this.lastImagePipelineSync && this.lastImagePipelineSync.isPending()) ||
      (this.lastImagePipelineSync && this.lastImagePipelineSync.isStarted()) ||
      (this.lastImagePipelineSync && this.lastImagePipelineSync.isBuilding())
    );
  }

  isSyncRecentlyCompleted() {
    const completed =
      this.lastImagePipelineSync && this.lastImagePipelineSync.isComplete();

    if (completed) {
      const now = moment(new Date());

      const duration = moment
        .duration(now.diff(moment.utc(this.lastImagePipelineSync.createdAt)))
        .asMinutes();

      return duration < 60;
    }
    return false;
  }

  lastSyncTime() {
    return moment
      .utc(this.lastImagePipelineSync.updatedAt)
      .local()
      .calendar();
  }

  lastSyncCreatedTime() {
    return moment
      .utc(this.lastImagePipelineSync.createdAt)
      .local()
      .calendar();
  }

  isSyncFailed() {
    return this.lastImagePipelineSync && this.lastImagePipelineSync.isFailed();
  }
  getRelatedImagePipelines(imagePipelines: ImagePipeline[]): ImagePipeline[] {
    const relatedImagePipelines: ImagePipeline[] = [];

    function addRelatedImagePipelines(pipeline: ImagePipeline) {
      // if the pipeline is already in the list, return immediately
      if (relatedImagePipelines.includes(pipeline)) {
        return;
      }

      // add the pipeline to the list
      relatedImagePipelines.push(pipeline);

      // add the source pipeline
      if (pipeline.sourceImagePipelineId !== null) {
        const sourcePipeline = imagePipelines.find(
          ip => ip.id === pipeline.sourceImagePipelineId,
        );
        if (sourcePipeline) {
          addRelatedImagePipelines(sourcePipeline);
        }
      }

      // add all downstream pipelines
      const downstreamPipelines = imagePipelines.filter(
        ip => ip.sourceImagePipelineId === pipeline.id,
      );
      for (const downstreamPipeline of downstreamPipelines) {
        addRelatedImagePipelines(downstreamPipeline);
      }
    }

    addRelatedImagePipelines(this);

    return relatedImagePipelines.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  // getRelatedImagePipelines(imagePipelines: ImagePipeline[]): ImagePipeline[] {
  //   const relatedImagePipelines: ImagePipeline[] = [];
  //   relatedImagePipelines.push(this);

  //   function addSourceImagePipelines(pipeline: ImagePipeline) {
  //     if (pipeline.sourceImagePipelineId !== null) {
  //       const sourcePipeline = imagePipelines.find(
  //         ip => ip.id === pipeline.sourceImagePipelineId,
  //       );
  //       if (sourcePipeline) {
  //         relatedImagePipelines.push(sourcePipeline);
  //         addSourceImagePipelines(sourcePipeline);
  //       }
  //     }
  //   }

  //   function addDownStreamImagePipelines(pipeline: ImagePipeline) {
  //     const downstreamPipelines = imagePipelines.filter(
  //       ip => ip.sourceImagePipelineId === pipeline.id,
  //     );
  //     for (const downstreamPipeline of downstreamPipelines) {
  //       if (!relatedImagePipelines.includes(downstreamPipeline)) {
  //         relatedImagePipelines.push(downstreamPipeline);
  //         addDownStreamImagePipelines(downstreamPipeline);
  //       }
  //     }
  //   }

  //   addSourceImagePipelines(this);
  //   addDownStreamImagePipelines(this);

  //   return relatedImagePipelines;
  // }

  hasDownstream(imagePipelines: ImagePipeline[]): boolean {
    return imagePipelines.some(ip => ip.sourceImagePipelineId === this.id);
  }

  relatedPipelines(): object[] {
    const pipelines = this.downstreamImagePipelines.map(pipeline => {
      return {
        pipeline: pipeline,
        type: 'downstream',
      };
    });

    if (this.sourceImagePipeline) {
      return [
        {
          pipeline: this.sourceImagePipeline,
          type: 'source',
        },
        ...pipelines,
      ];
    } else {
      return pipelines;
    }
  }

  friendlyPlatformName(): string {
    if (this.platform === 'windows' && this.osVersion === 'latest') {
      return 'Windows Server 2022';
    }

    if (this.platform === 'windows') {
      return 'Windows Server 2019';
    }

    if (this.platform === 'linux' && this.osVersion === 'rocky') {
      return 'Rocky Linux 9';
    }

    if (this.platform === 'linux' && this.sourceAmi) {
      return 'Custom Linux';
    }

    if (this.platform === 'linux') {
      return 'CentOS 7';
    }

    return '-';
  }
}
