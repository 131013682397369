import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import React from 'react';
import axios from 'axios';

import AuthorizationCheck from '../../../atoms/AuthorizationCheck';

const styles = theme => ({
  link: {
    flexGrow: '0',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    marginBottom: theme.spacing(2),

    cursor: 'pointer',
  },
});

const ClearCacheDialog = ({ onClose }) => {
  const [busy, setBusy] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  React.useEffect(() => {
    let isMounted = true; // track whether component is mounted
    const clearCache = async () => {
      try {
        setBusy(true);
        await axios.get('https://arch-docs-api.com/purge_cache');
        if (isMounted) {
          setSuccess(true);

          setBusy(false);
        }
      } catch (error) {
        if (isMounted) {
          setBusy(false);
          setFailure(true);
        }
      }
    };

    clearCache();
    return () => {
      isMounted = false; // clean up: set isMounted to false when component unmounts
    };
  }, []);

  const handleRefresh = () => {
    window.location.reload();
    onClose();
  };
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={true}
      onBackdropClick={onClose}
    >
      <DialogTitle>Clear Documentation Cache</DialogTitle>
      <DialogContent>
        {busy && <Typography>Clearing cache...</Typography>}
        {failure && <Typography>Cache purge failed</Typography>}
        {success && (
          <Typography>
            Cache purge has been requested. Please wait 30 seconds before
            refreshing.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {failure && <Button onClick={onClose}>Done</Button>}
        {success && <Button onClick={handleRefresh}>Refresh</Button>}
      </DialogActions>
    </Dialog>
  );
};

const ClearCache = ({ documentationSummary, classes }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  return documentationSummary ? (
    <>
      {showDialog && (
        <ClearCacheDialog
          onClose={() => {
            setShowDialog(false);
          }}
        />
      )}

      <AuthorizationCheck permissionName="admin.purge.documentation.cache">
        <Typography
          onClick={() => setShowDialog(true)}
          color="secondary"
          target="_blank"
          variant="body2"
          className={classes.link}
        >
          Clear Documentation Cache
        </Typography>
      </AuthorizationCheck>
    </>
  ) : null;
};

export default connect(
  'selectDocumentationSummary',
  withStyles(ClearCache, styles),
);
