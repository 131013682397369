import { createSelector } from 'redux-bundler';
import { chain, flatten, forEachRight, groupBy, mapValues } from 'lodash';
import moment from 'moment-timezone';

import WekaConfigurationUpload from '../models/weka_configuration_upload';

export default {
  name: 'weka_configuration_upload',
  getReducer: () => {
    const initialData = {
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      return state;
    };
  },

  doCreateWekaConfigurationUpload: data => async ({ store, getState }) => {
    const wcu = new WekaConfigurationUpload({ upload: data });

    await wcu.save();

    return wcu;
  },
};
