import {
  CircularProgress,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { Skeleton } from '@material-ui/lab';
import { connect } from 'redux-bundler-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState, useEffect } from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import moment from 'moment-timezone';
import { useHotkeys } from 'react-hotkeys-hook';

import { baseUrl } from '../../../shared/utils';
import ArchTableToolbarIconButton from '../ArchTableToolbarIconButton';
import AuthorizationCheck from '../../atoms/AuthorizationCheck';
import DeleteFacilityDialog from '../../pages/facility_detail/DeleteFacilityDialog';
import FacilityAvatar from '../../organisms/ArchDrawerMenu/FacilityAvatar';
import FacilitySwitcher from './FacilitySwitcher';
import InfoTooltip from '../InfoTooltip';
import RebuildDialog from '../../pages/facility_detail/RebuildDialog';

const styles = theme => ({
  avatarAndName: {
    display: 'flex',
    textDecoration: 'none',
    flexGrow: 1,
    alignItems: 'center',
    color: 'inherit',
  },

  menuWithProgressBar: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    marginLeft: 10,
  },
  toolbar: {
    flexShrink: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginRight: theme.spacing(1),
  },

  region: {
    verticalAlign: 'bottom',
    marginLeft: -2,
    paddingRight: 4,
  },

  time: {
    verticalAlign: 'bottom',
    marginLeft: theme.spacing(2),
    paddingRight: 4,
  },
  menuAndInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    width: 360,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  timeAndLocation: {
    display: 'flex',
  },
});

const FacilityToolbar = ({
  classes,
  activeClient,
  activeFacility,
  activeOrNextFacility,
  doCreateWorkstationStatRetrieval,
  doCreateConnectionManagerCertificateRenewal,
  doSyncFacility,
  showBoringAvatars,
  doSetShowBoringAvatars,
  privacyMode,
  baseUrl,
}) => {
  const theme = useTheme();
  const [dt, setDt] = useState(new Date());

  useEffect(() => {
    let secTimer = setInterval(() => {
      setDt(new Date());
    }, 1000);

    return () => clearInterval(secTimer);
  }, []);
  const [showRebuildDialog, setShowRebuildDialog] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [busy, setBusy] = React.useState(false);
  const [statsBusy, setStatsBusy] = React.useState(false);
  const [copiedFacilityId, setCopiedFacilityId] = React.useState(false);
  const [copiedShortFacilityId, setCopiedShortFacilityId] = React.useState(
    false,
  );
  const [
    copiedLicenseServerInstanceId,
    setCopiedLicenseServerInstanceId,
  ] = React.useState(false);

  const syncMenuItemText = busy ? 'Sync Requested' : 'Sync';

  const onSyncFacilityClick = async () => {
    setBusy(true);
    await doSyncFacility();
    setBusy(false);
  };
  const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  const copyFacilityId = async () => {
    setCopiedFacilityId(true);
    window.navigator.clipboard.writeText(activeFacility.id);
    await sleep(2000);
    setCopiedFacilityId(false);
  };

  const copyLicenseServerInstanceId = async () => {
    setCopiedLicenseServerInstanceId(true);
    console.log(activeFacility.licenseServer);
    window.navigator.clipboard.writeText(
      activeFacility.licenseServer.instanceId,
    );
    await sleep(2000);
    setCopiedLicenseServerInstanceId(false);
  };

  const copyShortFacilityId = async () => {
    setCopiedShortFacilityId(true);
    window.navigator.clipboard.writeText(activeFacility.shortId());
    await sleep(2000);
    setCopiedShortFacilityId(false);
  };

  // const [skeleton, setSkeleton] = React.useState(false);
  const skeleton = !activeOrNextFacility;

  useHotkeys('ctrl+alt+a', () => doSetShowBoringAvatars(!showBoringAvatars), [
    showBoringAvatars,
  ]);

  useHotkeys('ctrl+alt+r', () => setShowRebuildDialog(true), [
    setShowRebuildDialog,
  ]);

  useHotkeys('ctrl+alt+s', () => doSyncFacility(), []);

  return (
    <>
      {activeFacility && (
        <>
          <DeleteFacilityDialog
            open={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
          />
          <RebuildDialog
            open={showRebuildDialog}
            onClose={() => setShowRebuildDialog(false)}
          />
        </>
      )}
      <Toolbar
        className={classes.toolbar}
        /* onClick={e => setSkeleton(!skeleton)} */
      >
        <div className={classes.avatarAndName}>
          <FacilityAvatar
            className={classes.avatar}
            skeleton={skeleton}
            facility={activeOrNextFacility}
          />

          <ListItemText
            className={classes.facilityDetails}
            primary={<FacilitySwitcher facility={activeFacility} />}
            primaryTypographyProps={{ variant: 'h6' }}
            secondary={
              <>
                {skeleton ? (
                  <Skeleton width={100} />
                ) : (
                  <div className={classes.timeAndLocation}>
                    {activeOrNextFacility.region && (
                      <Typography variant="inherit" color="textSecondary">
                        <LocationOnIcon
                          className={classes.region}
                          fontSize="small"
                          htmlColor={theme.palette.secondary.main}
                        />
                        {activeOrNextFacility.region.code}
                      </Typography>
                    )}
                    {activeOrNextFacility.timeZone && (
                      <Typography variant="inherit" color="textSecondary">
                        <ScheduleIcon
                          className={classes.time}
                          fontSize="small"
                        />
                        {moment(dt)
                          .tz(activeOrNextFacility.timeZone)
                          .format('HH:mm')}
                      </Typography>
                    )}
                  </div>
                )}
              </>
            }
          />
        </div>

        {activeFacility && (
          <>
            <div className={classes.menuAndInfoWrapper}>
              <AuthorizationCheck
                permissionNames={[
                  'facility.update',
                  'admin.facility.creation.create',
                  'admin.facility.deletion.create',
                ]}
              >
                <ArchTableToolbarIconButton
                  onClick={event => setAnchorEl(event.currentTarget)}
                >
                  <MoreVertIcon data-test="facility-toolbar-menu" />
                </ArchTableToolbarIconButton>
                <Menu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  className={classes.menu}
                >
                  <MenuItem
                    onClick={onSyncFacilityClick}
                    className={classes.menuItem}
                  >
                    {syncMenuItemText}
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className={classes.menuWithProgressBar}
                    >
                      Last Sync:{' '}
                      {activeFacility.lastFacilitySync &&
                        activeFacility.lastFacilitySync.isPending() && (
                          <>
                            {moment
                              .utc(activeFacility.lastFacilitySync.createdAt)
                              .local()
                              .format('[Requested] MMM Do HH:mm')}
                            <CircularProgress
                              size={20}
                              className={classes.progress}
                            />
                          </>
                        )}
                      {activeFacility.lastFacilitySync &&
                        activeFacility.lastFacilitySync.isComplete() &&
                        moment
                          .utc(activeFacility.lastFacilitySync.updatedAt)
                          .local()
                          .format('MMM Do HH:mm')}
                      {activeFacility.lastFacilitySync &&
                        activeFacility.lastFacilitySync.isFailed() &&
                        moment
                          .utc(activeFacility.lastFacilitySync.updatedAt)
                          .local()
                          .format('MMM Do HH:mm')}
                      {/* Hello */}
                      {!activeFacility.lastFacilitySync && 'Never'}
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    onClick={copyFacilityId}
                    className={classes.menuItem}
                  >
                    {copiedFacilityId
                      ? 'Copied to clipboard'
                      : 'Copy Facility ID'}
                  </MenuItem>

                  <MenuItem
                    onClick={copyShortFacilityId}
                    className={classes.menuItem}
                  >
                    {copiedShortFacilityId
                      ? 'Copied to clipboard'
                      : 'Copy Short Facility ID'}
                  </MenuItem>

                  {activeFacility.licenseServer && (
                    <MenuItem
                      onClick={copyLicenseServerInstanceId}
                      className={classes.menuItem}
                    >
                      {copiedLicenseServerInstanceId
                        ? 'Copied to clipboard'
                        : 'Copy License Server ID'}
                    </MenuItem>
                  )}

                  <AuthorizationCheck permissionName="facility.update">
                    <MenuItem
                      onClick={async () => {
                        setStatsBusy(true);
                        await doCreateWorkstationStatRetrieval();
                        setStatsBusy(false);
                      }}
                      disabled={statsBusy}
                    >
                      Update Stats {statsBusy}
                    </MenuItem>
                  </AuthorizationCheck>

                  {/* {activeFacility.isCloud() && ( */}
                  {/*   <AuthorizationCheck permissionName="facility.update"> */}
                  {/*     <MenuItem */}
                  {/*       onClick={async () => { */}
                  {/*         setStatsBusy(true); */}
                  {/*         await doCreateConnectionManagerCertificateRenewal(); */}
                  {/*         setStatsBusy(false); */}
                  {/*       }} */}
                  {/*       disabled={statsBusy} */}
                  {/*     > */}
                  {/*       Renew Connection Manager Certificate */}
                  {/*     </MenuItem> */}
                  {/*   </AuthorizationCheck> */}
                  {/* )} */}

                  <AuthorizationCheck permissionName="facility.update">
                    <MenuItem
                      component="a"
                      href={`${baseUrl}/edit`}
                      onClick={() => setAnchorEl(null)}
                    >
                      Edit
                    </MenuItem>
                  </AuthorizationCheck>

                  <AuthorizationCheck permissionName="admin.facility.access.key">
                    <MenuItem
                      component="a"
                      href={`${baseUrl}/keys`}
                      onClick={() => setAnchorEl(null)}
                    >
                      Access Keys
                    </MenuItem>
                  </AuthorizationCheck>

                  {activeFacility.account?.partOfOrganisation && (
                    <AuthorizationCheck permissionName="admin.facility.deletion.create">
                      <>
                        <MenuItem
                          component="a"
                          target="_blank"
                          href={`https://signin.aws.amazon.com/switchrole?roleName=${activeFacility.account.roleArn.split(
                            '/',
                          )[1] || 'OrganizationAccountAccessRole'}&account=${
                            activeFacility.account.accountId
                          }&displayName=${activeClient.name}`}
                        >
                          AWS Console
                        </MenuItem>
                      </>
                    </AuthorizationCheck>
                  )}

                  {activeFacility.account?.partOfOrganisation && (
                    <AuthorizationCheck permissionName="admin.facility.deletion.create">
                      <>
                        <MenuItem
                          component="a"
                          target="_blank"
                          href={`https://signin.aws.amazon.com/switchrole?roleName=ArchBillingRole&account=${activeFacility.account.accountId}&displayName=${activeClient.name}`}
                        >
                          AWS Billing
                        </MenuItem>
                      </>
                    </AuthorizationCheck>
                  )}

                  {activeFacility.isOnPrem() && (
                    <AuthorizationCheck permissionName="facility.update">
                      <MenuItem
                        component="a"
                        href={`${baseUrl}/setup`}
                        onClick={() => setAnchorEl(null)}
                      >
                        On Prem Setup
                      </MenuItem>
                    </AuthorizationCheck>
                  )}

                  {activeFacility.isCloud() && (
                    <AuthorizationCheck permissionName="admin.facility.creation.create">
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          setShowRebuildDialog(true);
                        }}
                      >
                        Rebuild
                      </MenuItem>
                    </AuthorizationCheck>
                  )}
                  <AuthorizationCheck permissionName="admin.facility.deletion.create">
                    <>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          setShowDeleteDialog(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </>
                  </AuthorizationCheck>
                </Menu>
              </AuthorizationCheck>
              <InfoTooltip>
                {activeFacility.account && (
                  <div>{`AWS Account ID: ${activeFacility.account.accountId}`}</div>
                )}
                <div>{`Facility Created: ${moment
                  .utc(activeFacility.createdAt)
                  .local()
                  .format('MMM Do HH:mm')}`}</div>

                <div>{`Last Sync: ${activeFacility.lastFacilitySync &&
                  moment
                    .utc(activeFacility.lastFacilitySync.updatedAt)
                    .local()
                    .format('MMM Do HH:mm')}`}</div>
              </InfoTooltip>
            </div>
          </>
        )}
      </Toolbar>
    </>
  );
};
export default connect(
  'selectActiveClient',
  'selectActiveFacility',
  'selectActiveOrNextFacility',
  'selectActiveOrNextFacility',
  'selectBaseUrl',
  'doSyncFacility',
  'doCreateWorkstationStatRetrieval',
  'selectPrivacyMode',
  'doCreateConnectionManagerCertificateRenewal',
  'doSetShowBoringAvatars',
  'selectShowBoringAvatars',
  withStyles(FacilityToolbar, styles, { withTheme: true }),
);
