import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import WebauthnChallenge from './webauthn_challenge';
import Workstation from './workstation';

@Model()
export default class SsmSession extends ApplicationRecord {
  static jsonapiType = 'ssm_sessions';
  @Attr() sessionId: string;
  @Attr() tokenValue: string;
  @Attr() streamUrl: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() instanceId: string;
  @Attr() success: boolean;

  @BelongsTo() workstation: Workstation;
  @BelongsTo() facility: Facility;
  @BelongsTo() webauthnChallenge: WebauthnChallenge;
}
