import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import GithubAppInstallation from '../models/github_app_installation';

export default {
  name: 'githubAppInstallations',
  getReducer: () => {
    const initialData = {
      loading: false,
      data: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_GITHUB_APP_INSTALLATIONS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_GITHUB_APP_INSTALLATIONS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.result,
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      return state;
    };
  },

  doFetchGithubAppInstallations: () => async ({
    dispatch,
    getState,
    store,
  }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();
    dispatch({ type: 'FETCH_GITHUB_APP_INSTALLATIONS' });
    const scope = GithubAppInstallation.where({
      client_id: client.id,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_GITHUB_APP_INSTALLATIONS_SUCCESS',
      payload: { result: response.data },
    });
  },

  doCreateGithubAppInstallation: () => async ({ getState, store }) => {
    const state = getState();

    const client = store.selectActiveClient(state).dup();
    const githubAppInstallation = new GithubAppInstallation({
      client,
    });

    await githubAppInstallation.save({
      with: ['client.id'],
    });

    return githubAppInstallation;
  },

  doDestroyGithubAppInstallation: githubAppInstallation => async ({
    dispatch,
  }) => {
    await githubAppInstallation.destroy();
  },

  reactShouldFetchGithubAppInstallations: createSelector(
    'selectRouteApis',
    'selectGithubAppInstallationsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, githubAppInstallationsRaw, currentUser, activeClient) => {
      const wantsGithubAppInstallations = apis.includes(
        'githubAppInstallations',
      );

      if (!wantsGithubAppInstallations) return false;
      if (!currentUser) return false;
      if (!activeClient) return false;

      if (githubAppInstallationsRaw.loading || githubAppInstallationsRaw.data)
        return;

      return { actionCreator: 'doFetchGithubAppInstallations' };
    },
  ),

  selectCodeParam: createSelector(
    'selectQueryObject',
    queryParams => queryParams.code,
  ),

  selectGithubStateParam: createSelector(
    'selectQueryObject',
    queryParams => queryParams.state,
  ),

  selectGithubInstallationIdParam: createSelector(
    'selectQueryObject',
    queryParams => queryParams.installation_id,
  ),

  selectGithubAppInstallationsRaw: state => state.githubAppInstallations,
  selectGithubAppInstallations: state => state.githubAppInstallations.data,
};
