import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import UserMenu from './';
import styles from './styles';

const UserMenuContainer = ({ currentUser }) => {
  return <UserMenu user={currentUser} />;
};

export default connect(
  'selectCurrentUser',
  withStyles(UserMenuContainer, styles, { withTheme: true }),
);
