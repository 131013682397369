import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Facility from './facility';
import FileUploadDestination from './file_upload_destination';
import FileUploadFile from './file_upload_file';
import User from './user';
import WebauthnChallenge from './webauthn_challenge';

@Model()
export default class FileUpload extends ApplicationRecord {
  static jsonapiType = 'file_uploads';

  @Attr() name: string;
  @Attr() size: number;
  // @Attr() readOnly: boolean;
  @Attr() completedAt: string;
  @Attr() speedHistory: number[][];
  @Attr() failedAt: string;
  @Attr() cancelledAt: string;

  @BelongsTo() client: Client;
  @BelongsTo() facility: Facility;
  @BelongsTo() performedBy: User;
  @BelongsTo() fileUploadDestination: FileUploadDestination;

  @BelongsTo() webauthnChallenge: WebauthnChallenge;
  @HasMany({ persist: false }) files: FileUploadFile[];

  status(): string {
    if (this.cancelledAt) {
      return 'Cancelled';
    }
    if (this.completedAt) {
      return 'Completed';
    }

    if (this.failedAt) {
      return 'Failed';
    }

    return 'Pending';
  }

  totalFileSizes(): number {
    return this.files.reduce((total, file) => total + file.size, 0);
  }

  totalFileSizesFormatted(): string {
    return this.humanFileSize(this.totalFileSizes());
  }

  humanFileSize(bytes: number, si: boolean = false): string {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB'];

    let u = -1;
    const r = 10;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(1) + ' ' + units[u];
  }
}
