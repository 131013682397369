import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

const privateFieldNames = ['password'];

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(
  privateFieldNames,
);

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('yd9wum/arch-platform', {
    network: {
      requestSanitizer,
      responseSanitizer,
    },
  });
}
