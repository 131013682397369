import { createSelector } from 'redux-bundler';
import { snakeCase, sumBy, uniqBy } from 'lodash';

// eslint-disable-next-line no-unused-vars
import AwsAccount from '../models/aws_account';
import Account from '../models/account';
// eslint-disable-next-line no-unused-vars
import Region from '../models/region';
import CrossAccountRoleInstallation from '../models/cross_account_role_installation';

export default {
  name: 'accounts',
  getReducer: () => {
    const initialData = {
      loading: false,
      items: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      activeAccount: null,
      loadingActiveAccount: false,
    };

    return (state = initialData, { type, payload }) => {
      // if (type === 'FETCH_CLIENT_START') {
      //   return {
      //     ...state,
      //     activeAccount: null,
      //     items: null,
      //   };
      // }
      if (type === 'RESET_CLIENT') {
        console.log('RESET_CLIENT');
        return {
          ...state,
          activeAccount: null,
          items: null,
        };
      }
      if (type === 'FETCH_ACCOUNTS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_ACCOUNTS_SUCCESS') {
        return {
          ...state,
          loading: false,
          items: payload.result,
        };
      }
      if (type === 'FETCH_ACCOUNT_START') {
        return { ...state, loadingActiveAccount: true };
      }
      if (type === 'FETCH_ACCOUNT_SUCCESS') {
        return {
          ...state,
          loadingActiveAccount: false,
          activeAccount: payload.result,
        };
      }

      if (type === 'REMOVE_ACCOUNT') {
        const index = state.data.findIndex(u => u.id === payload.account.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'UPDATE_ACCOUNT') {
        const { account: nextAccount } = payload;
        return {
          ...state,
          activeAccount:
            state.activeAccount.id === nextAccount.id
              ? nextAccount
              : state.activeAccount,
        };
      }

      if (type === 'CHANGE_ACCOUNT_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }

      return state;
    };
  },

  doFetchAccount: accountId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_ACCOUNT_START' });

    const response = await Account.includes([
      'regions',
      'cross_account_role_installation',
    ]).find(accountId);
    dispatch({
      type: 'FETCH_ACCOUNT_SUCCESS',
      payload: { result: response.data },
    });
  },

  selectAccountsRaw: state => state.accounts,
  selectActiveAccount: createSelector(
    'selectAccountsRaw',
    accountData => accountData.activeAccount,
  ),

  doFetchAllAccounts: () => async ({ dispatch, getState, store }) => {
    const state = getState();

    const { sortBy } = state.accounts;
    const { sortDirection } = state.accounts;

    dispatch({ type: 'FETCH_ACCOUNTS' });
    const scope = Account.where({
      part_of_organisation: true,
    })
      .order({
        [snakeCase(sortBy)]: sortDirection.toLowerCase(),
      })
      .selectExtra(['client_name', 'client_deleted', 'cost_error']);

    const response = await scope.all();

    dispatch({
      type: 'FETCH_ACCOUNTS_SUCCESS',
      payload: { result: response.data },
    });
  },

  doFetchAccounts: () => async ({ dispatch, getState, store }) => {
    const state = getState();

    const { sortBy } = state.accounts;
    const { sortDirection } = state.accounts;

    const client = store.selectActiveClient(state);
    dispatch({ type: 'FETCH_ACCOUNTS' });
    const scope = Account.where({
      kept: true,
      client_id: client.id,
    }).order({
      [snakeCase(sortBy)]: sortDirection.toLowerCase(),
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_ACCOUNTS_SUCCESS',
      payload: { result: response.data },
    });
  },

  doCreateCrossAccountRoleInstallation: () => async ({ store, getState }) => {
    const state = getState();
    const account = store.selectActiveAccount(state);

    const cari = new CrossAccountRoleInstallation({ account });

    try {
      const result = await cari.save({ with: ['account'] });
      if (result) {
        store.doFetchAccount(account.id);
      }

      return cari;
    } catch (err) {
      return false;
    }
  },

  doUpdateAccount: values => async ({ dispatch, store, getState }) => {
    const state = getState();

    const account = store.selectActiveAccount(state).dup();

    account.assignAttributes(values);
    account.endpoints = JSON.parse(values.endpoints);

    const success = await account.save();
    if (success) {
      dispatch({
        type: 'UPDATE_ACCOUNT',
        payload: { account },
      });
    }
    return account;
  },

  doChangeAccountSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_ACCOUNT_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchAllAccounts();
  },

  doDestroyAccount: account => async ({ dispatch }) => {
    await account.destroy();
    dispatch({ type: 'REMOVE_ACCOUNT', payload: { account } });
  },

  reactShouldFetchActiveAccount: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveAccount',
    'selectAccountsRaw',
    (routeParams, pathname, activeAccount, accountData) => {
      if (
        !pathname.includes('/accounts') ||
        !routeParams.accountId ||
        accountData.loadingActiveAccount
      ) {
        return null;
      }
      if (activeAccount && activeAccount.id === routeParams.accountId) {
        return null;
      }
      console.log(
        pathname,
        routeParams.accountId,
        accountData.loadingActiveAccount,
        activeAccount,
      );
      console.log('fetching account');

      return { actionCreator: 'doFetchAccount', args: [routeParams.accountId] };
    },
  ),

  reactShouldFetchAccounts: createSelector(
    'selectRouteApis',
    'selectAccountsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, accountsRaw, currentUser, activeClient) => {
      const wantsAccounts = apis.includes('accounts');
      if (
        !wantsAccounts ||
        accountsRaw.loading ||
        accountsRaw.items ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchAccounts' };
    },
  ),

  reactShouldFetchAllAccounts: createSelector(
    'selectRouteApis',
    'selectAccountsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, accountsRaw, currentUser) => {
      const wantsAccounts = apis.includes('all_accounts');
      if (
        !wantsAccounts ||
        accountsRaw.loading ||
        accountsRaw.items ||
        !currentUser
      ) {
        return false;
      }
      return { actionCreator: 'doFetchAllAccounts' };
    },
  ),

  selectAccountsRaw: state => state.accounts,
  selectAccounts: state => state.accounts.items || [],
  selectAccountsLoading: state => state.accounts.loading,
  selectAccountsSortBy: state => state.accounts.sortBy,
  selectAccountsSortDirection: state => state.accounts.sortDirection,

  selectPreviousMonthCostTotal: createSelector('selectAccounts', accounts => {
    return sumBy(
      uniqBy(accounts, a => a.accountId),
      a => a.costLastMonth,
    );
  }),

  selectMonthToDateCostTotal: createSelector('selectAccounts', accounts => {
    return sumBy(
      uniqBy(accounts, a => a.accountId),
      a => a.costMtd,
    );
  }),
};
