import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import SecurityGroup from './security_group';
import Template from './template';

@Model()
export default class TemplateSecurityGroupAssignment extends ApplicationRecord {
  static jsonapiType = 'template_security_group_assignments';
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() template: Template;
  @BelongsTo() securityGroup: SecurityGroup;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
