import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ClientUserAssignment from './client_user_assignment';
import User from './user';

@Model()
export default class PasswordChange extends ApplicationRecord {
  static jsonapiType = 'password_changes';
  @Attr() password: string;
  @Attr() resetPassword: boolean;
  @BelongsTo() user: User;
  @BelongsTo() clientUserAssignment: ClientUserAssignment;
}
