import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';

@Model()
export default class FacilityCidrBlockSuggestion extends ApplicationRecord {
  static jsonapiType = 'facility_cidr_block_suggestions';

  @Attr() cidrBlocks: string[];

  @BelongsTo() client: Client;
}
