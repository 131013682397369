import { createSelector } from 'redux-bundler';
import { chain, flatten, forEachRight, groupBy, mapValues } from 'lodash';
import moment from 'moment-timezone';

import RecentWorkstationLogin from '../models/recent_workstation_login';

const STALE_AFTER = 120000;
export default {
  name: 'workstation_logins',
  getReducer: () => {
    const initialData = {
      loading: false,
      loaded: false,
      data: [],
      timezone: moment.tz.guess(),
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SET_TIMEZONE') {
        return { ...state, timezone: payload.timezone };
      }
      if (type === 'FETCH_WORKSTATION_LOGINS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_WORKSTATION_LOGINS_SUCCESS') {
        return {
          ...state,
          loading: false,
          loaded: true,
          data: payload.result,
          loadedAt: Date.now(),
        };
      }
      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          loading: false,
          loaded: false,
          data: [],
        };
      }

      return state;
    };
  },

  doSetTimezone: timezone => ({ dispatch }) => {
    dispatch({
      type: 'SET_TIMEZONE',
      payload: { timezone },
    });
  },

  doFetchWorkstationLogins: () => async ({ dispatch, getState, store }) => {
    dispatch({ type: 'FETCH_WORKSTATION_LOGINS_START' });

    const state = getState();
    const client = store.selectActiveClient(state);
    const authenticatedUser = store.selectAuthenticatedUser(state);

    const response = await RecentWorkstationLogin.where({
      client_id: client.id,
    })
      .includes({ workstation: ['facility', 'last_login', 'groups'] })
      .per(3)
      .all();

    dispatch({
      type: 'FETCH_WORKSTATION_LOGINS_SUCCESS',
      payload: { result: response.data },
    });
  },

  selectWorkstationLoginsState: state => state.workstation_logins,

  selectWorkstationLogins: createSelector(
    'selectWorkstationLoginsState',
    workstationLoginsState => workstationLoginsState.data,
  ),

  selectWorkstationLoginsIsStale: createSelector(
    'selectWorkstationLoginsState',
    'selectAppTime',
    (workstationLoginsState, appTime) => {
      if (!workstationLoginsState.loadedAt) {
        return true;
      }
      return appTime - workstationLoginsState.loadedAt > STALE_AFTER;
    },
  ),

  reactShouldFetchWorkstationLogins: createSelector(
    'selectRouteApis',
    'selectActiveClient',
    'selectAuthenticatedUser',
    'selectWorkstationLoginsState',
    'selectWorkstationLogins',
    'selectWorkstationLoginsIsStale',
    (
      apis,
      activeClient,
      authenticatedUser,
      workstationLoginsState,
      workstationLogins,
      workstationLoginsIsStale,
    ) => {
      const wantsEvents = apis.includes('workstationLogins');
      if (
        !wantsEvents ||
        !activeClient ||
        !authenticatedUser ||
        workstationLoginsState.loading ||
        (workstationLoginsState.loaded && !workstationLoginsIsStale)
      ) {
        return null;
      }

      return {
        actionCreator: 'doFetchWorkstationLogins',
      };
    },
  ),
};
