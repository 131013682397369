import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class LicenseServer extends ApplicationRecord {
  static jsonapiType = 'license_servers';
  @Attr() status: string;
  @Attr() instanceType: string;
  @Attr() instanceId: string;
  @Attr() ipAddress: string;

  @BelongsTo() facility: Facility;
}
