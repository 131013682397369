import WekaClusterBoot from '../models/weka_cluster_boot';
import WekaClusterShutdown from '../models/weka_cluster_shutdown';
import WekaStatusRetrieval from '../models/weka_status_retrieval';
import WekaAdminPasswordRetrieval from '../models/weka_admin_password_retrieval';

export default {
  name: 'weka',
  getReducer: () => {
    const initialData = {
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      return state;
    };
  },

  doCreateWekaClusterBoot: data => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state);
    const wekaCluster = facility.wekaCluster;

    const wcb = new WekaClusterBoot({ wekaCluster });

    await wcb.save({ with: ['wekaCluster'] });

    return wcb;
  },

  doCreateWekaClusterShutdown: data => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state);
    const wekaCluster = facility.wekaCluster;

    const wcs = new WekaClusterShutdown({ wekaCluster });

    await wcs.save({ with: ['wekaCluster'] });

    return wcs;
  },

  doCreateWekaStatusRetrieval: data => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state);
    const wekaCluster = facility.wekaCluster;

    const wsr = new WekaStatusRetrieval({ wekaCluster });

    await wsr.save({ with: ['wekaCluster'] });

    return wsr;
  },

  doCreateWekaAdminPasswordRetrieval: data => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state);
    const wekaCluster = facility.wekaCluster;

    const wsr = new WekaAdminPasswordRetrieval({ wekaCluster });

    await wsr.save({ with: ['wekaCluster'] });

    return wsr;
  },
};
