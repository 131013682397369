import { Attr, BelongsTo, HasOne, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import WekaClusterBoot from './weka_cluster_boot';
import WekaClusterShutdown from './weka_cluster_shutdown';

@Model()
export default class WekaCluster extends ApplicationRecord {
  static jsonapiType = 'weka_clusters';
  @Attr() status: string;
  @Attr() instanceType: string;
  @Attr() identifier: string;
  @Attr() data: object;
  @Attr() metrics: object;
  @Attr() totalHosts: number;
  @Attr() totalDrives: number;
  @Attr() capacity: number;
  @Attr() clusterUp: boolean;
  @Attr() ssmConnectionCommand: boolean;

  @BelongsTo() facility: Facility;
  @HasOne() pendingBoot: WekaClusterBoot;
  @HasOne() pendingShutdown: WekaClusterShutdown;

  formattedCapacity(decimals = 2) {
    if (this.capacity === 0) return '0 Bytes';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(this.capacity) / Math.log(k));

    return (
      parseFloat((this.capacity / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    );
  }
}
