import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import User from './user';
import Team from './team';

@Model()
export default class TeamMembership extends ApplicationRecord {
  static jsonapiType = 'team_memberships';
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() team: Team;
  @BelongsTo() user: User;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
