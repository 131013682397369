import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';

import ArchBaseSelect from '../../molecules/ArchBaseSelect';
import Team from '../../../models/team';

const ArchTeamSelect = ({ teamData, ...rest }) => (
  <ArchBaseSelect
    label="Team"
    {...rest}
    options={teamData}
    renderValueName={value => value.name}
  />
);

ArchTeamSelect.propTypes = {
  teamData: PropTypes.arrayOf(Team).isRequired,
};

export default connect(
  'selectTeamData',
  ArchTeamSelect,
);
