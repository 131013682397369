import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

@Model()
export default class WorkstationPcoipAgentUpdate extends ApplicationRecord {
  static jsonapiType = 'workstation_pcoip_agent_updates';
  @Attr() pcoipAgentUrl: string;

  @BelongsTo() workstation: Workstation;
}
