import { Model, BelongsTo } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import User from './user';

@Model()
export default class UserSuspension extends ApplicationRecord {
  static jsonapiType = 'user_suspensions';
  @BelongsTo() user: User;
}
