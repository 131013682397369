import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

const STALE_AFTER = 60 * 1000;

@Model()
export default class Stat extends ApplicationRecord {
  static jsonapiType = 'stats';
  @Attr() latest: number;
  @Attr() name: string;
  @Attr() startsAt: string;
  @Attr() endsAt: string;
  @Attr() updatedAt: string;
  @Attr() data: object;

  // @BelongsTo() workstation: Workstation;

  isStale(log = false) {
    if (!this.updatedAt) return false;
    const age = Date.now() - Date.parse(this.updatedAt);

    if (log) {
      console.log(`stat: ${this.name}, ${age} `);
    }
    return age > STALE_AFTER;
  }
}
