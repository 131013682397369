import { Helmet } from 'react-helmet';
import { connect } from 'redux-bundler-react';
import { useMediaQuery, ThemeProvider, createTheme } from '@mui/material';
import { lighten } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import Snowfall from 'react-snowfall';

import ArchDrawerMenu from './organisms/ArchDrawerMenu';
import ArchPage from './molecules/ArchPage';
import AuthorizationCheck from './atoms/AuthorizationCheck';
import BeamerHelmet from './atoms/BeamerHelmet';
import DevelopmentRibbon from './molecules/DevelopmentRibbon';

const styles = theme => ({});

const Layout = ({
  route: {
    component: PageComponent,
    options: { menu = true },
  },
  themeSetting,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const getPaletteType = () => {
    if (themeSetting === 'system') {
      return prefersDarkMode ? 'dark' : 'light';
    } else {
      return themeSetting || 'light';
    }
  };

  return (
    <>
      <Helmet>
        <html style={`color-scheme: ${getPaletteType()}`} />
      </Helmet>
      <ArchPage drawerMenu={menu && ArchDrawerMenu}>
        <AuthorizationCheck permissionNames={['ui.snowfall.view']}>
          <Snowfall snowflakeCount={100} />
        </AuthorizationCheck>
        <Suspense fallback={<div></div>}>
          <PageComponent />
        </Suspense>
      </ArchPage>

      <DevelopmentRibbon position="right-bottom">Development</DevelopmentRibbon>
      <BeamerHelmet id="BLfxjJkx31528" />
    </>
  );
};

Layout.propTypes = {
  route: PropTypes.shape({
    component: PropTypes.elementType,
  }).isRequired,
};

export default connect('selectRoute', 'selectThemeSetting', Layout);
