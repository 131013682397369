import {
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

import { ArchDot } from '../../../molecules/StateIndicators/styles';
import CopyButton from './CopyButton';

const ConnectionManagerSetup = ({
  classes,
  activeFacility,
  doFetchFacility,
}) => {
  const [showConfiguration, setShowConfiguration] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [showInstructions, setShowInstructions] = React.useState(false);
  const urlRef = React.useRef(null);
  const configRef = React.useRef(null);

  return (
    <Card>
      <CardContent className={classes.setupSection}>
        <IconButton
          className={classes.copy}
          size="small"
          onClick={event => setMenuAnchor(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          open={Boolean(menuAnchor)}
          anchorEl={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          className={classes.menu}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              setMenuAnchor(null);
              activeFacility.connectionManagerSetupComplete = true;
              if (!activeFacility.pcmHostname) {
                activeFacility.pcmHostname = `cm.ext.${activeFacility.directory.domain}`;
              }

              const success = await activeFacility.save();
              if (success) {
                doFetchFacility(activeFacility.id);
              }
            }}
            disabled={activeFacility.connectionManagerSetupComplete}
          >
            Mark as complete
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setShowInstructions(!showInstructions);
              setMenuAnchor(null);
            }}
            disabled={!activeFacility.connectionManagerSetupComplete}
          >
            {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
          </MenuItem>
        </Menu>
        <Typography variant="subtitle1">
          Connection Manager / Security Gateway
        </Typography>
        <Typography variant="body2">
          The Connection Manager / Security Gateway are components of the HP
          Anyware CAS and must be deployed together to enable remote access to
          your on-premise workstations
        </Typography>
        <br />

        {activeFacility.connectionManagerSetupComplete && (
          <>
            <div>
              {' '}
              <ArchDot instanceState="running"></ArchDot>Connection Manager is
              online
            </div>
            {showInstructions && <br />}
          </>
        )}

        {!activeFacility.connectionManagerSetupComplete && (
          <>
            <div>
              {' '}
              <ArchDot instanceState="stopped"></ArchDot>Connection Manager not
              setup
            </div>
            <br />
          </>
        )}

        <Collapse
          in={
            !activeFacility.connectionManagerSetupComplete || showInstructions
          }
        >
          <Typography variant="subtitle2">System Requirements</Typography>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <ul>
                <li>2 or more CPUs or vCPUs at 2.5 GHz or higher</li>
                <li>4 GB of RAM</li>
                <li>4 GB of swap space</li>
                <li>10 GB of free disk space</li>
                <li>RHEL 8 or Rocky Linux 8</li>
                <li>
                  Machine should have a public IP address or some form of load
                  balancer
                </li>
              </ul>
            </Card>
          </Grid>
          <br />

          <Typography variant="subtitle2">Installation</Typography>
          <Typography variant="body2">
            Refer to the instructions provided by HP at the following URL
          </Typography>
          <Grid item xs={12} md={8}>
            <Card variant="outlined" className={classes.code}>
              <code ref={urlRef}>
                https://www.teradici.com/web-help/pcoip_connection_manager_security_gateway/23.01/installing/installing_cmsg/
              </code>

              <CopyButton className={classes.copy} refToCopy={urlRef} />
            </Card>
          </Grid>

          <br />

          <Typography variant="subtitle2">Configuration</Typography>
          <Typography variant="body2">
            Once the CM/SG packages are installed, complete set up by using the
            following as a guide
            <code>/srv/broker/compose.yml</code>
          </Typography>
          <Grid item xs={12} md={8}>
            <Card variant="outlined" className={classes.code}>
              { /*  prettier-ignore */}
              <code>
                    <pre ref={configRef}>
                    sudo pcoip-cmsg-setup install --accept-policies \ {"\n"}
                      --broker-url https://ADDRESS_OF_BROKER \ {"\n"}
                      --enable-collaboration \ {"\n"}
                      --external-pcoip-ip PUBLIC_IP_OF_CMSG \ {"\n"}
                      --host-address PUBLIC_HOSTNAME_OF_CMSG {"\n"}
                    </pre>
                  </code>

              <CopyButton className={classes.copy} refToCopy={configRef} />
            </Card>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  setupSection: {
    position: 'relative',
  },
  code: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingRight: theme.spacing(4),
    fontSize: 12,
  },
  copy: {
    right: theme.spacing(1),
    top: theme.spacing(2),
    position: 'absolute',
  },
});
export default connect(
  'selectActiveClient',
  'selectActiveFacility',
  'selectDirectories',
  'selectBaseUrl',
  'doFetchFacility',
  withStyles(ConnectionManagerSetup, styles),
);
