import { AutoSizer, Table as VirtualizedTable } from 'react-virtualized';
import { Typography, darken, Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { withStyles } from 'tss-react/mui';

import ArchPaper from '../../molecules/ArchPaper';
import ArchTableContext from './ArchTableContext';
import ArchTableToolbar from '../../molecules/ArchTableToolbar';

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // border: '1px solid #e0e0e0',
  },
  head: {
    backgroundColor: theme.palette.background.default,
  },
  tableHeader: {
    backgroundColor: theme.palette.background.default,
  },
  tableRow: {
    // borderBottom: '1px solid #e0e0e0',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: darken(theme.palette.background.paper, 0.1),
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    flex: 1,
  },

  tableContainer: {
    flex: 1,
    // overflowY: 'auto',
  },
  empty: {
    margin: 'auto',
    height: '100%',
    display: 'flex',
  },
  emptyText: {
    margin: 'auto',
    textAlign: 'center',
  },
  pagination: {
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class ArchTable extends React.Component {
  getRowClassName = ({ index }) => {
    const { classes } = this.props;

    return cx(classes.tableRow, classes.flexContainer, {
      [classes.tableHeader]: index === -1,
      [classes.tableRowHover]: index > -1,
    });
  };

  render() {
    const {
      classes,
      title,
      loading,
      children,
      rowGetter,
      rowCount,
      sortBy,
      sortDirection,
      sort,
      numSelected,
      onHeaderClick,
      onCheckboxClick,
      renderToolbar,
      className,
      rowStyle,
      rowHeight,
      minWidth,
      square,
      elevation,
      pagination,
      currentPage,
      pageCount,
      onPageChange,
    } = this.props;
    return (
      <ArchTableContext.Provider value={rowHeight}>
        <ArchPaper
          className={cx(className, classes.root)}
          square={square}
          elevation={elevation}
          fullHeight
        >
          {renderToolbar ? (
            renderToolbar()
          ) : (
            <ArchTableToolbar
              loading={loading}
              title={title}
              numSelected={numSelected}
            />
          )}
          <div className={classes.tableContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <VirtualizedTable
                  sortBy={sortBy}
                  sort={sort}
                  sortDirection={sortDirection}
                  className={classes.table}
                  rowCount={rowCount}
                  rowGetter={rowGetter}
                  width={Math.max(width, minWidth)}
                  hideHeader
                  height={height}
                  rowHeight={rowHeight}
                  headerHeight={48}
                  rowClassName={this.getRowClassName}
                  onHeaderClick={onHeaderClick}
                  onCheckboxClick={onCheckboxClick}
                  rowStyle={rowStyle}
                  noRowsRenderer={() =>
                    loading ? null : (
                      <div className={classes.empty}>
                        <Typography
                          className={classes.emptyText}
                          variant="body2"
                        >
                          No items match your current selection
                        </Typography>{' '}
                      </div>
                    )
                  }
                >
                  {children}
                </VirtualizedTable>
              )}
            </AutoSizer>
          </div>

          {pagination && (
            <div className={classes.pagination}>
              <div />
              <Pagination
                count={pageCount}
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={(event, page) => onPageChange(event, page)}
              />
            </div>
          )}
        </ArchPaper>
      </ArchTableContext.Provider>
    );
  }
}

ArchTable.defaultProps = {
  onCheckboxClick: null,
  onHeaderClick: null,
  renderToolbar: null,
  loading: false,
  className: '',
  rowStyle: {},
  rowHeight: 48,
  minWidth: 800,
};

export default withStyles(ArchTable, styles);
