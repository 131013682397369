import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import React from 'react';

import AuthorizationCheck from '../../../atoms/AuthorizationCheck';
import DocumentationSection from './DocumentationSection';

const styles = theme => ({
  divider: {
    paddingTop: theme.spacing(1),
  },
  itemButton: {
    color: theme.palette.text.primary,
  },
});

const DocumentationMenu = ({ documentationSummary, routeParams, classes }) => {
  const routeHistory = JSON.parse(
    sessionStorage.getItem('routeHistory') || '[]',
  );
  const returnToPage = routeHistory[0] || '/';
  const canReturnToPage = routeHistory[0];
  return (
    documentationSummary && (
      <List component="nav">
        <ListItem disablePadding>
          {canReturnToPage ? (
            <ListItemButton component="a" href={returnToPage}>
              <ListItemIcon>
                <ArrowBackIcon />
              </ListItemIcon>
              <ListItemText primary="Back" />
            </ListItemButton>
          ) : (
            <ListItemButton component="a" href={returnToPage}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          )}
        </ListItem>
        <Divider className={classes.divider} />
        <List>
          <ListItem component="a" href={`/docs`} disablePadding>
            <ListItemButton
              selected={!routeParams.docId}
              className={classes.itemButton}
            >
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Docs" />
            </ListItemButton>
          </ListItem>
          {documentationSummary.user.children[0].children.map(child => (
            <DocumentationSection key={child.id} item={child} level={1} />
          ))}

          <AuthorizationCheck permissionName="ui.view.admin.documentation">
            <Divider className={classes.divider} />
            {documentationSummary.admin.children[0].children.map(child => (
              <DocumentationSection key={child.id} item={child} level={1} />
            ))}
          </AuthorizationCheck>
        </List>
      </List>
    )
  );
};

export default connect(
  'selectDocumentationSummary',
  'selectRouteParams',
  withStyles(DocumentationMenu, styles),
);
