import { BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import Workstation from './workstation';

@Model()
export default class WorkstationStatRetrieval extends ApplicationRecord {
  static jsonapiType = 'workstation_stat_retrievals';

  @BelongsTo() workstation: Workstation;
  @BelongsTo() facility: Facility;
}
