import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { connect } from 'redux-bundler-react';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import React from 'react';

import ConfirmationDialog from './ConfirmationDialog';

const styles = theme => ({
  inProgress: {
    display: 'flex',
    alignItems: 'center',
  },

  spinner: {
    marginLeft: theme.spacing(1),
  },
  requirement: {
    marginTop: theme.spacing(1),
    display: 'flex',
    color: theme.palette.text.secondary,

    alignItems: 'center',
  },
  requirementIcon: {
    marginRight: theme.spacing(1),
  },

  list: {
    maxHeight: 450,
    overflow: 'auto',
  },

  listItem: {
    cursor: 'pointer',
  },
  form: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  dialogHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  spacer: {
    flexGrow: 1,
  },
});

const SplitTestDialog = ({
  classes,
  open,
  onClose,
  doAddSplitTest,
  recentSplitTests,
}) => {
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [clipboardSnackbarOpen, setClipboardSnackbarOpen] = React.useState(
    false,
  );

  const disableSplitTest = () => {
    document.cookie =
      'nf_ab= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';

    setShowConfirmation(true);
    setMessage('Please reload the page for the change to take effect');

    onClose();
  };

  const copyClipboardLink = value => {
    window.navigator.clipboard.writeText(`https://app.archpt.io/st/${value}`);
    setClipboardSnackbarOpen(true);
  };
  const configureSplitTest = value => {
    var now = new Date();
    var expires = now.getTime() + 1000 * 3600 * 24 * 365;
    now.setTime(expires);

    document.cookie = `nf_ab=${value}; expires=${now.toUTCString()}; path=/`;
    doAddSplitTest(value);

    setShowConfirmation(true);
    setMessage('Please reload the page for the change to take effect');
    onClose();
  };

  const handleConfirm = async (values, { setErrors, setSubmitting }) => {
    configureSplitTest(values.cookie);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    window.location.reload();
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      open && inputEl.current.focus();
    }, 100);
    return () => clearTimeout(timer);
  }, [open]);

  const inputEl = React.useRef(null);

  const cookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('nf_ab='));
  const cookieValue = cookie ? cookie.split('=')[1] : null;

  return (
    <>
      <ConfirmationDialog
        title="Split Test Configured"
        open={showConfirmation}
        onClose={handleCloseConfirmation}
        message={message}
      />
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <Snackbar
          open={clipboardSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setClipboardSnackbarOpen(false)}
          message="Link copied to your clipboard"
        />
        <Formik
          enableReinitialize
          initialValues={{
            cookie: cookieValue || '',
          }}
          onSubmit={handleConfirm}
          validateOnMount
        >
          {({ isValid, values }) => {
            return (
              <Form>
                <DialogTitle className={classes.dialogHeader}>
                  Split Test Cookie Value
                </DialogTitle>
                <div className={classes.form}>
                  Enter the name of the test you wish to use, or choose from a
                  previous test
                  <Field
                    name="cookie"
                    fullWidth
                    component={TextField}
                    margin="normal"
                    variant="outlined"
                    label="Split Test Cookie Value"
                    autofocus
                    inputRef={inputEl}
                  />
                </div>
                <Divider />
                <List
                  className={classes.list}
                  subheader={<ListSubheader>Recent Split Tests</ListSubheader>}
                >
                  {recentSplitTests.map(st => (
                    <ListItem
                      className={classes.listItem}
                      onClick={() => configureSplitTest(st)}
                      divider
                      button
                    >
                      <ListItemText primary={st} />

                      <ListItemSecondaryAction>
                        <Tooltip title="Copy split test link">
                          <IconButton
                            edge="end"
                            onClick={e => {
                              copyClipboardLink(st);
                            }}
                          >
                            <InsertLinkIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <DialogActions>
                  <Button onClick={() => disableSplitTest()}>
                    Disable Split Test
                  </Button>

                  <div className={classes.spacer} />
                  <Button onClick={onClose}>Cancel</Button>
                  <Button color="secondary" type="submit" disabled={!isValid}>
                    Update
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default connect(
  'selectActiveClient',
  'doAddSplitTest',
  'selectRecentSplitTests',
  withStyles(SplitTestDialog, styles, { withTheme: true }),
);
