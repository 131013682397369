import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';

import ArchBaseSelect from '../../molecules/ArchBaseSelect';

const ArchParsecRulesetSelect = ({
  facility,
  doCreateParsecRulesetQuery,
  ...rest
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);
      const response = await doCreateParsecRulesetQuery(facility);

      if (active) {
        setOptions(response.parsecRulesets || []);
      }

      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [open]);
  return (
    <ArchBaseSelect
      label="Parsec Ruleset"
      {...rest}
      options={options}
      renderValueName={value => `${value.name}`}
      renderValueIdentifier={value => `ID: ${value.id}`}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
    />
  );
};

export default connect('doCreateParsecRulesetQuery', ArchParsecRulesetSelect);
