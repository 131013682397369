import {
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
import styles from './styles';
import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

class ArchBaseSelect extends Component {
  renderValue = value => {
    const {
      classes,
      renderValueName,
      showIdentifier,
      renderValueIdentifier,
    } = this.props;
    return (
      <>
        <div className={classes.justify}>
          <span className={classes.name}>{renderValueName(value)}</span>
          {showIdentifier && (
            <span className={classes.identifier}>
              {renderValueIdentifier(value)}
            </span>
          )}
        </div>
      </>
    );
  };

  render() {
    const {
      label,
      name,
      options,
      value,
      classes,
      onChange,
      onBlur,
      onFilter,
      InputProps,
      field,
      form,
      size,
      className,
      disabled,
      autoFocus,
      loading,
      help,
      showClearIcon,
      clearValue,
    } = this.props;
    return (
      <div className={classes.fieldWithIcon}>
        <TextField
          name={name || field.name}
          select
          label={label}
          variant="outlined"
          className={
            help
              ? cx(classes.formControl, classes.inputWithIcon, classes)
              : cx(classes.formControl, className)
          }
          value={value || field.value || ''}
          onChange={onChange || field.onChange}
          onBlur={onBlur || field.onBlur}
          SelectProps={{
            renderValue: this.renderValue,
            classes: { root: classes.select },
            MenuProps: {
              className: classes.menu,
            },
          }}
          fullWidth
          InputProps={{
            ...InputProps,
            endAdornment: showClearIcon && (value || field.value) && (
              <InputAdornment className={classes.closeIcon}>
                <CloseIcon
                  onClick={() => clearValue(form.setFieldValue, field.name)}
                />
              </InputAdornment>
            ),
          }}
          size={size}
          disabled={disabled}
          autoFocus={autoFocus}
        >
          {loading ? (
            <MenuItem value="none" disabled>
              <CircularProgress className={classes.spinner} />
              Loading...
            </MenuItem>
          ) : (
            options.filter(onFilter).map(t => {
              return [
                <MenuItem key={t.id} value={t}>
                  {this.renderValue(t)}
                </MenuItem>,
              ];
            })
          )}
        </TextField>
        {help && (
          <Tooltip title={<Typography variant="body2">{help}</Typography>}>
            <HelpIcon htmlColor="grey" fontSize="small" />
          </Tooltip>
        )}
      </div>
    );
  }
}

ArchBaseSelect.propTypes = {
  showIdentifier: PropTypes.bool,
  renderValueIdentifier: PropTypes.func,
  renderValueName: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onFilter: PropTypes.func,
};

ArchBaseSelect.defaultProps = {
  showIdentifier: true,
  renderValueIdentifier: value => value.identifier,
  renderValueName: value => value.name,
  field: {},
  size: 'medium',
  className: null,
  disabled: false,
  autoFocus: false,
  onFilter: () => true,
};

export default withStyles(ArchBaseSelect, styles, { withTheme: true });
