import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { Field, Form, Formik } from 'formik';
import { Skeleton } from '@mui/lab';
import { TextField } from 'formik-mui';
import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';
import * as yup from 'yup';

import { baseUrl } from '../../../shared/utils';
import ArchPageContent from '../../molecules/ArchPageContent';
import ArchRoleSelect from '../../organisms/ArchRoleSelect';
import ArchTeamSelect from '../../organisms/ArchTeamSelect';
import Breadcrumbs, { Breadcrumb } from '../../organisms/Breadcrumbs';
import ClientPrivacyMask from '../../atoms/ClientPrivacyMask';
import PageTitle from '../../atoms/PageTitle';
import CreatingInvitationDialog from './CreatingInvitationDialog';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  halfWidthField: {
    width: '50%',
    '&:first-child': {
      paddingRight: 16,
    },
    '&:last-child': {
      paddingLeft: 16,
    },
  },
});

export const mapErrorsToFormik = errors =>
  Object.keys(errors).reduce((memo, key) => {
    memo[key] = errors[key].message;
    return memo;
  }, {});

const NewInvitation = ({
  doUpdateUrl,
  activeFacility,
  activeClient,
  doSetActiveUser,
  doCreateInvitation,
  classes,
}) => {
  const [busy, setBusy] = React.useState(false);
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    values.firstName = values.firstName.trim();
    values.lastName = values.lastName.trim();
    values.username = values.username.trim();

    try {
      setBusy(true);

      const invitation = await doCreateInvitation(values);

      setSubmitting(false);
      if (invitation.isPersisted) {
        doUpdateUrl(
          `${baseUrl(activeClient, activeFacility)}/users/${invitation.userId}`,
        );
      } else {
        setBusy(false);
        setErrors(mapErrorsToFormik(invitation.errors));
        setSubmitting(false);
      }
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error);
      setSubmitting(false);

      setBusy(false);
      throw error;
    }
  };

  if (!activeClient) {
    return (
      <ArchPageContent>
        <Container>
          <Skeleton />
        </Container>
      </ArchPageContent>
    );
  }
  return (
    <>
      <CreatingInvitationDialog open={busy} />
      <PageTitle>New User</PageTitle>
      <Breadcrumbs>
        <Breadcrumb to={`/clients/${activeClient.id}`}>
          <ClientPrivacyMask client={activeClient} />
        </Breadcrumb>

        <Breadcrumb to={`${baseUrl(activeClient, activeFacility)}/users`}>
          Users
        </Breadcrumb>

        <Breadcrumb to={`${baseUrl(activeClient, activeFacility)}/users/new`}>
          New User
        </Breadcrumb>
      </Breadcrumbs>
      <ArchPageContent>
        <Container>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              username: '',
              password: '',
              team: null,
            }}
            validationSchema={yup.object().shape({
              firstName: yup
                .string()
                .trim()
                .required('First name is a required field'),
              username: yup
                .string()
                .trim()
                .required('Username is a required field'),
              lastName: yup
                .string()
                .trim()
                .required('Last name is a required field'),
              email: yup
                .string()
                .required('Email is required')
                .email('Email is invalid'),
            })}
            onSubmit={handleSubmit}
          >
            {({ dirty, isSubmitting, isValid }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Invite User
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">User Overview</Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <Field
                          name="firstName"
                          label="First Name"
                          component={TextField}
                          className={classes.halfWidthField}
                          variant="outlined"
                        />
                        <Field
                          name="lastName"
                          label="Last Name"
                          component={TextField}
                          className={classes.halfWidthField}
                          variant="outlined"
                        />
                        <Field
                          name="email"
                          label="Email"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">
                      Workstation Login
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <Field
                          name="username"
                          label="Username"
                          component={TextField}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1">Teams</Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <Field
                          name="team"
                          render={({ field, form }) => {
                            return (
                              <ArchTeamSelect
                                {...field}
                                onChange={e =>
                                  form.setFieldValue('team', e.target.value)
                                }
                              />
                            );
                          }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.buttons}>
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.button}
                        component="a"
                        color="inherit"
                        href={`${baseUrl(activeClient, activeFacility)}/users`}
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={!isValid}
                        variant="contained"
                        size="small"
                        color="primary"
                        className={classes.button}
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Container>
      </ArchPageContent>
    </>
  );
};

export default connect(
  'doSetActiveUser',
  'doUpdateUrl',
  'doCreateInvitation',
  'selectActiveClient',
  'selectActiveFacility',
  withStyles(NewInvitation, styles, { withTheme: true }),
);
