import { Chip, Tooltip, LinearProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

const COLORS = {
  pending: '#f4772e',
  running: '#1bc943',
  stopped: '#f83245',
  deleted: '#7a7b97',
  stopping: '#F8C446',
  'shutting-down': '#F8C446',
  terminated: '#7a7b97',
};

const ArchChip = styled(Chip)`
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  background-color: ${props => COLORS[props.instanceState]};
`;

const ArchDot = styled.span`
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  opacity: 0.7;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${props => COLORS[props.instanceState]};
`;

export { ArchChip, ArchDot };
