import {
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

import { ArchDot } from '../../../molecules/StateIndicators/styles';
import CopyButton from './CopyButton';

const WorkstationSetup = ({ classes, activeFacility, doFetchFacility }) => {
  const [showConfiguration, setShowConfiguration] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [showInstructions, setShowInstructions] = React.useState(false);

  const windowsDownloadRef = React.useRef(null);
  const linuxDownloadRef = React.useRef(null);
  const configRef = React.useRef(null);
  return (
    <Card>
      <CardContent className={classes.setupSection}>
        <IconButton
          className={classes.copy}
          size="small"
          onClick={event => setMenuAnchor(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          open={Boolean(menuAnchor)}
          anchorEl={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          className={classes.menu}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              setMenuAnchor(null);
              activeFacility.initialWorkstationSetupComplete = true;

              const success = await activeFacility.save();
              if (success) {
                doFetchFacility(activeFacility.id);
              }
            }}
            disabled={activeFacility.initialWorkstationSetupComplete}
          >
            Mark as complete
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setShowInstructions(!showInstructions);
              setMenuAnchor(null);
            }}
            disabled={!activeFacility.initialWorkstationSetupComplete}
          >
            {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
          </MenuItem>
        </Menu>
        <Typography variant="subtitle1">
          Install Arch Agent on Workstations
        </Typography>

        <Typography variant="body2">
          The Arch Agent needs be installed on computers before they can
          register with Arch.
        </Typography>
        <br />

        {activeFacility.initialWorkstationSetupComplete && (
          <>
            <div>
              <ArchDot instanceState="running"></ArchDot>Agent succesfully
              installed and registered
            </div>
            {showInstructions && <br />}
          </>
        )}

        {!activeFacility.initialWorkstationSetupComplete && (
          <>
            <div>
              {' '}
              <ArchDot instanceState="stopped"></ArchDot>Waiting for agent to
              register
            </div>
            <br />
          </>
        )}

        <Collapse
          in={
            !activeFacility.initialWorkstationSetupComplete || showInstructions
          }
        >
          <Grid item xs={12} md={8}>
            <Typography variant="subtitle2">Download for Windows</Typography>
            <Card variant="outlined" className={classes.code}>
              <code ref={windowsDownloadRef}>
                https://downloads.archpt.io/arch-windows.exe
              </code>
              <CopyButton
                className={classes.copy}
                refToCopy={windowsDownloadRef}
              />
            </Card>

            <br />
            <Typography variant="subtitle2">Download for Linux</Typography>
            <Card variant="outlined" className={classes.code}>
              <code ref={linuxDownloadRef}>
                https://downloads.archpt.io/arch-linux.tgz
              </code>
              <CopyButton
                className={classes.copy}
                refToCopy={linuxDownloadRef}
              />
            </Card>
          </Grid>

          <br />
          <Typography variant="subtitle2">Configuration</Typography>
          <Typography variant="body2">
            Once the agent is installed, add the following settings to your
            agent configuration
          </Typography>
          <Grid item xs={12} md={8}>
            <Card variant="outlined" className={classes.code}>
              { /*  prettier-ignore */}
              <code>
                <pre ref={configRef}>
                  <b>Windows</b><br />
                  Add the following line to c:\programdata\mane-agent\config.yml <br />
                  {"\n"}
                  facility_id: {activeFacility.id} {"\n"}
                  facility_key: bak_{crypto.randomUUID()} {"\n"}
                  {"\n"}
                  <b>Linux</b><br />
                  Add the following line to /etc/mane-agent/config <br />
                  {"\n"}
                  facility_id: {activeFacility.id} {"\n"}
                  facility_key: bak_{crypto.randomUUID()} {"\n"}


                </pre>
              </code>

              <CopyButton className={classes.copy} refToCopy={configRef} />
            </Card>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  setupSection: {
    position: 'relative',
  },
  code: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingRight: theme.spacing(4),
    fontSize: 12,
  },
  copy: {
    right: theme.spacing(1),
    top: theme.spacing(2),
    position: 'absolute',
  },
});
export default connect(
  'selectActiveClient',
  'selectActiveFacility',
  'selectDirectories',
  'selectBaseUrl',
  'doFetchFacility',
  withStyles(WorkstationSetup, styles),
);
