import { Model, BelongsTo, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ChefPackage from './chef_package';
import User from './user';

@Model()
export default class ChefPackageSync extends ApplicationRecord {
  static jsonapiType = 'chef_package_syncs';

  @Attr() state: string;
  @Attr() commitMessage: string;
  @Attr() commitId: string;
  @Attr() commitUrl: string;

  @Attr() authorName: string;
  @Attr() authorEmail: string;

  @Attr() commitName: string;
  @Attr() commitEmail: string;

  @Attr() createdAt: string;
  @Attr() updatedAt: string;

  @BelongsTo() chefPackage: ChefPackage;
  @BelongsTo() performedBy: User;

  isComplete() {
    return this.state === 'completed';
  }

  isPending() {
    return this.state === 'pending';
  }

  isStarted() {
    return this.state === 'started';
  }

  isFailed() {
    return this.state === 'failed';
  }

  hasCommitInfo() {
    return this.commitId !== null;
  }

  shortCommitId() {
    return this.commitId.slice(0, 7);
  }
}
