import { Avatar, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { AvatarGroup } from '@mui/lab';
import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';
import moment from 'moment';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

import ArchPageContent from '../../molecules/ArchPageContent';
import ArchPaper from '../../molecules/ArchPaper';
import ArchTable from '../../organisms/ArchTable';
import ArchTableColumn from '../../molecules/ArchTableColumn';
import ArchTableToolbar from '../../molecules/ArchTableToolbar';
import Breadcrumbs, { Breadcrumb } from '../../organisms/Breadcrumbs';
import PageTitle from '../../atoms/PageTitle';
import PusherSubscription from '../../atoms/PusherContainer/PusherSubscription';
import User from '../../../models/user';
import styles from './styles';

class Licenses extends Component {
  sort = ({ sortDirection, sortBy }) => {
    const { doChangeLicensesSort } = this.props;
    doChangeLicensesSort({ sortDirection, sortBy });
  };
  render() {
    const {
      classes,
      licenseData,
      licensesLoading,
      licensesSortDirection,
      licensesSortBy,
      doUpdateLicenseFromPusher,
      activeClient,
      baseUrl,
      privacyMode,
    } = this.props;
    const rows = [];
    return (
      <>
        {activeClient && (
          <PusherSubscription
            channelName={`private-client-${activeClient.id}-licenses`}
            eventName="update"
            onUpdate={doUpdateLicenseFromPusher}
          />
        )}
        <PageTitle>Licenses</PageTitle>
        <Breadcrumbs>
          <Breadcrumb to="/licenses">Licenses</Breadcrumb>
        </Breadcrumbs>
        <ArchPageContent flex>
          <ArchPaper fullHeight>
            <ArchTableToolbar loading={licensesLoading} title="Licenses" />
            {/* <Filter /> */}
            <ArchTable
              title="Licenses"
              rowCount={licenseData.length}
              rowGetter={({ index }) => licenseData[index]}
              loading={licensesLoading}
              sort={this.sort}
              sortDirection={licensesSortDirection}
              sortBy={licensesSortBy}
              rowHeight={56}
              renderToolbar={() => null}
            >
              <ArchTableColumn
                dataKey="name"
                width={400}
                label="Name"
                cellDataGetter={({ rowData }) => {
                  return (
                    <div className={classes.appName}>
                      <div className={classes.iconContainer}>
                        {rowData?.app?.image ? (
                          <img
                            src={rowData.app.image}
                            width={32}
                            height={32}
                            className={classes.icon}
                          />
                        ) : (
                          <CardMembershipIcon
                            className={classes.icon}
                            color="disabled"
                          />
                        )}
                      </div>
                      {rowData.app ? rowData.app.name : ''}
                    </div>
                  );
                }}
              />
              <ArchTableColumn dataKey="vendor" width={250} label="Vendor" />
              <ArchTableColumn dataKey="feature" width={350} label="Feature" />

              <ArchTableColumn
                dataKey="featureUsage"
                width={160}
                label="Usage"
              />

              <ArchTableColumn
                dataKey="featureCount"
                width={160}
                label="Count"
              />
              <ArchTableColumn
                dataKey="expiry"
                width={240}
                label="Expiry"
                cellDataGetter={({ rowData }) => {
                  {
                    return rowData.expiry
                      ? moment
                          .utc(rowData.expiry)
                          .local()
                          .format('MMM Do YYYY')
                      : 'Permanent';
                  }
                }}
              />

              <ArchTableColumn
                dataKey="userCount"
                width={300}
                label="Users"
                cellDataGetter={({ rowData }) => {
                  return (
                    <div className={classes.name}>
                      <AvatarGroup spacing="small">
                        {rowData.userAvatars.map(user => (
                          <Tooltip
                            title={
                              privacyMode ? User.fakeName(user.name) : user.name
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              src={
                                privacyMode
                                  ? User.fakeAvatar(user.name)
                                  : user.avatar
                              }
                              alt={
                                privacyMode
                                  ? User.fakeName(user.name)
                                  : user.name
                              }
                            />
                          </Tooltip>
                        ))}
                      </AvatarGroup>
                      {rowData.userCount > rowData.userAvatars.length &&
                        ` + ${rowData.userCount -
                          rowData.userAvatarSummary.length}`}
                    </div>
                  );
                }}
              />
            </ArchTable>
          </ArchPaper>
        </ArchPageContent>
      </>
    );
  }
}

export default connect(
  'selectPrivacyMode',
  'selectLicenseData',
  'selectLicensesLoading',
  'selectLicensesSortBy',
  'selectLicensesSortDirection',
  'selectActiveClient',
  'doChangeLicensesSort',
  'doUpdateLicenseFromPusher',
  'selectBaseUrl',
  withStyles(Licenses, styles, { withTheme: true }),
);
