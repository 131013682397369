import RegionSync from '../models/region_sync';

export default {
  name: 'regions',
  getReducer: () => {
    const initialData = {};
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => state;
  },

  doSyncRegions: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const account = store.selectActiveAccount(state).dup();

    dispatch({ type: 'SYNC_REGIONS_START' });

    const regionSync = new RegionSync({ accountId: account.id });

    const result = await regionSync.save({ with: ['account'] });

    if (result) {
      store.doFetchAccount(account.id);
    }
  },
};
