import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ChefPackage from './chef_package';
import Client from './client';

@Model()
export default class ChefPackageAnalysis extends ApplicationRecord {
  static jsonapiType = 'chef_package_analyses';
  @Attr() analysis: string;
  @Attr() createdAt: string;

  @BelongsTo() chefPackage: ChefPackage;
  @BelongsTo() client: Client;

}
