import { Column } from 'react-virtualized';
import React from 'react';

import ArchTableCell from '../ArchTableCell';
import ArchTableHeaderCell from '../ArchTableHeaderCell';

const headerRenderer = props => {
  return (
    <ArchTableHeaderCell {...props}>
      <span>{props.label}</span>
    </ArchTableHeaderCell>
  );
};
const cellRenderer = ({
  cellData,
  columnIndex,
  rowData,
  columnData,
  padding,
  rowIndex,
  className,
}) => {
  return (
    <ArchTableCell
      className={className}
      component="div"
      padding={columnData && columnData.padding}
      variant="body"
      rightAlign={columnData && columnData.rightAlign}
      clickable={Boolean(columnData && (columnData.onClick || columnData.href))}
      onClick={() =>
        columnData &&
        columnData.onClick &&
        columnData.onClick({ rowIndex, rowData })
      }
      href={
        columnData && columnData.href && columnData.href({ rowIndex, rowData })
      }
    >
      {cellData}
    </ArchTableCell>
  );
};

export default class ArchTableColumn extends Column {
  static defaultProps = {
    ...Column.defaultProps,
    ...{
      cellRenderer,
      headerRenderer,
      dataKey: '',
    },
  };
}
