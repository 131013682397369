import { Model, SpraypaintBase } from 'spraypaint';
import deepEqual from 'deep-equal';

@Model()
export class ApplicationRecord extends SpraypaintBase {
  static baseUrl = process.env.REACT_APP_API_BASE_URL || '';
  static apiNamespace = process.env.REACT_APP_API_NAMESPACE || '';

  isDeepDirty() {
    console.log('isDeepDirty');
    if (!this.isDirty()) {
      return false;
    }

    Object.values(this.changes).forEach(change => {
      if (!deepEqual(change[0], change[1])) {
        console.log('isDeepDirty true', change);
        return true;
      }
    });

    console.log('isDeepDirty false');

    return false;
  }
}
