import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Permission from './permission';
import Role from './role';

@Model()
export default class RolePermissionAssignment extends ApplicationRecord {
  static jsonapiType = 'role_permission_assignments';
  @Attr({ persist: false }) selected: boolean;
  @Attr() permissionName: string;

  @BelongsTo() role: Role;
  @BelongsTo() permission: Permission;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
