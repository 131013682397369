import { Model, Attr } from 'spraypaint';

import Account from './account';

@Model()
export default class AwsAccount extends Account {
  static jsonapiType = 'aws_accounts';
  static endpoint = '/accounts';

  @Attr() stackPrefix: string;
  @Attr() secretPrefix: string;
  @Attr() kmsKeyId: string;
  @Attr() roleArn: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr() enableLeastPrivilegeIam: boolean;
  @Attr() enableLambdaPermissionBoundary: boolean;
}
