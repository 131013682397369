import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import WorkstationCreation from './workstation_creation';

@Model()
export default class WorkstationCreationCheck extends ApplicationRecord {
  static jsonapiType = 'workstation_creation_checks';
  @Attr() state: string;

  @BelongsTo() workstationCreation: WorkstationCreation;
}
