import { createSelector } from 'redux-bundler';
import lodash from 'lodash';

export default {
  name: 'preferences',
  getReducer: () => {
    const initialData = {
      themeSetting: 'light',
      showMetrics: false,
      showAdvancedConfig: false,
      privacyMode: false,
      displayMode: 'normal',
      disableAutoLogout: false,
      recentSplitTests: null,
      showBoringAvatars: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'SET_THEME_SETTING') {
        return {
          ...state,
          ...{
            themeSetting: payload.themeSetting,
          },
        };
      }

      if (type === 'SET_DISPLAY_MODE') {
        return {
          ...state,
          ...{
            displayMode: payload.displayMode,
          },
        };
      }

      if (type === 'SET_PRIVACY_MODE') {
        return {
          ...state,
          ...{
            privacyMode: payload.privacyMode,
          },
        };
      }

      if (type === 'SET_SHOW_METRICS') {
        return {
          ...state,
          ...{
            showMetrics: payload.showMetrics,
          },
        };
      }

      if (type === 'SET_SHOW_ADVANCED_CONFIGURATION') {
        return {
          ...state,
          ...{
            showAdvancedConfig: payload.showAdvancedConfig,
          },
        };
      }

      if (type === 'SET_DISABLE_AUTO_LOGOUT') {
        return {
          ...state,
          ...{
            disableAutoLogout: payload.disableAutoLogout,
          },
        };
      }

      if (type === 'SET_METRICS_PANEL') {
        return {
          ...state,
          ...{
            showMetrics: payload.metricsPanel,
          },
        };
      }

      if (type === 'SET_RECENT_SPLIT_TESTS') {
        return {
          ...state,
          ...{
            recentSplitTests: payload.recentSplitTests,
          },
        };
      }

      if (type === 'SET_SHOW_BORING_AVATARS') {
        return {
          ...state,
          ...{
            showBoringAvatars: payload.showBoringAvatars,
          },
        };
      }

      if (type === 'SET_RECENT_ITEMS') {
        return {
          ...state,
          ...{
            recentItems: payload.recentItems,
          },
        };
      }

      if (type === 'SET_RECENT_ITEMS_IN_PRIVACY_MODE') {
        return {
          ...state,
          ...{
            recentItemsInPrivacyMode: payload.recentItems,
          },
        };
      }

      if (type === 'SET_FAVOURITE_CLIENTS') {
        return {
          ...state,
          ...{
            favouriteClients: payload.favouriteClients,
          },
        };
      }

      if (type === 'SET_SHOW_PIPELINE_FLOWCHART') {
        return {
          ...state,
          ...{
            showPipelineFlowchart: payload.showPipelineFlowchart,
          },
        };
      }

      return state;
    };
  },

  doSetDisableAutoLogout: disableAutoLogout => ({ dispatch }) => {
    dispatch({
      type: 'SET_DISABLE_AUTO_LOGOUT',
      payload: {
        disableAutoLogout,
      },
    });
  },

  doSetDisplayMode: displayMode => ({ dispatch }) => {
    localStorage.setItem('displayMode', displayMode);
    dispatch({
      type: 'SET_DISPLAY_MODE',
      payload: {
        displayMode,
      },
    });
  },

  doSetThemeSetting: themeSetting => ({ dispatch }) => {
    localStorage.setItem('themeSetting', themeSetting);
    dispatch({
      type: 'SET_THEME_SETTING',
      payload: {
        themeSetting,
      },
    });

    dispatch({
      type: 'RESET_THEME_SETTING',
    });
  },

  doSetPrivacyMode: privacyMode => ({ dispatch }) => {
    localStorage.setItem('privacyMode', privacyMode);
    dispatch({
      type: 'SET_PRIVACY_MODE',
      payload: {
        privacyMode,
      },
    });
  },

  doSetMetricsPanel: metricsPanel => ({ dispatch }) => {
    localStorage.setItem('metricsPanel', metricsPanel);
    dispatch({
      type: 'SET_METRICS_PANEL',
      payload: {
        metricsPanel,
      },
    });
  },

  doSetShowPipelineFlowchart: showPipelineFlowchart => ({ dispatch }) => {
    localStorage.setItem('showPipelineFlowchart', showPipelineFlowchart);
    dispatch({
      type: 'SET_SHOW_PIPELINE_FLOWCHART',
      payload: {
        showPipelineFlowchart,
      },
    });
  },

  selectThemeSetting: state => {
    return (
      localStorage.getItem('themeSetting') || state.preferences.themeSetting
    );
  },

  selectDisplayMode: state => {
    return localStorage.getItem('displayMode') || state.preferences.displayMode;
  },

  doSetShowMetrics: showMetrics => ({ dispatch }) => {
    localStorage.setItem('showMetrics', showMetrics);
    dispatch({
      type: 'SET_SHOW_METRICS',
      payload: {
        showMetrics,
      },
    });
  },

  doSetShowAdvancedConfig: showAdvancedConfig => ({ dispatch }) => {
    localStorage.setItem('showAdvancedConfig', showAdvancedConfig);
    dispatch({
      type: 'SET_SHOW_ADVANCED_CONFIGURATION',
      payload: {
        showAdvancedConfig,
      },
    });
  },

  doAddSplitTest: splitTest => ({ dispatch }) => {
    let recentSplitTests = JSON.parse(
      localStorage.getItem('recentSplitTests') || '[]',
    );
    recentSplitTests.unshift(splitTest);
    recentSplitTests = lodash.uniq(recentSplitTests).slice(0, 5);

    localStorage.setItem('recentSplitTests', JSON.stringify(recentSplitTests));

    dispatch({
      type: 'SET_RECENT_SPLIT_TESTS',
      payload: {
        recentSplitTests,
      },
    });
  },

  doAddRecentItem: ({ name, type, url, secondary, clientId, facilityId }) => ({
    dispatch,
    getState,
    store,
  }) => {
    const state = getState();
    const recentItemsKey = store.selectRecentItemsKey(state);
    let recentItems = JSON.parse(localStorage.getItem(recentItemsKey) || '[]');
    const item = {
      name,
      type,
      url,
      secondary,
      clientId,
      facilityId,
    };
    recentItems.unshift(item);
    recentItems = lodash.uniqBy(recentItems, r => r.url).slice(0, 30);

    localStorage.setItem(recentItemsKey, JSON.stringify(recentItems));
    if (state.preferences.privacyMode) {
      dispatch({
        type: 'SET_RECENT_ITEMS_IN_PRIVACY_MODE',
        payload: {
          recentItems,
        },
      });
    } else {
      dispatch({
        type: 'SET_RECENT_ITEMS',
        payload: {
          recentItems,
        },
      });
    }
  },

  doAddFavouriteClient: client => ({ dispatch, getState, store }) => {
    const state = getState();
    let favouriteClients = JSON.parse(
      localStorage.getItem('favouriteClients') || '[]',
    );
    favouriteClients.unshift(client);
    favouriteClients = lodash.uniqBy(favouriteClients, r => r);

    localStorage.setItem('favouriteClients', JSON.stringify(favouriteClients));
    dispatch({
      type: 'SET_FAVOURITE_CLIENTS',
      payload: {
        favouriteClients,
      },
    });
  },

  doUnfavouriteClient: client => ({ dispatch, getState, store }) => {
    const state = getState();
    let favouriteClients = JSON.parse(
      localStorage.getItem('favouriteClients') || '[]',
    );
    favouriteClients = favouriteClients.filter(fc => fc !== client);

    localStorage.setItem('favouriteClients', JSON.stringify(favouriteClients));
    dispatch({
      type: 'SET_FAVOURITE_CLIENTS',
      payload: {
        favouriteClients,
      },
    });
  },

  doClearRecentItems: () => ({ getState, store, dispatch }) => {
    const state = getState();
    const recentItemsKey = store.selectRecentItemsKey(state);

    const recentItems = [];

    localStorage.setItem(recentItemsKey, JSON.stringify(recentItems));

    if (state.preferences.privacyMode) {
      dispatch({
        type: 'SET_RECENT_ITEMS_IN_PRIVACY_MODE',
        payload: {
          recentItems,
        },
      });
    } else {
      dispatch({
        type: 'SET_RECENT_ITEMS',
        payload: {
          recentItems,
        },
      });
    }
  },

  doSetShowBoringAvatars: showBoringAvatars => ({ dispatch }) => {
    localStorage.setItem('showBoringAvatars', showBoringAvatars);
    console.log('set show boring avatars', showBoringAvatars);
    dispatch({
      type: 'SET_SHOW_BORING_AVATARS',
      payload: {
        showBoringAvatars,
      },
    });
  },

  selectPrivacyMode: state => {
    const value = localStorage.getItem('privacyMode');
    if (value) {
      return value === 'true';
    }
    return state.preferences.privacyMode;
  },

  selectShowMetrics: state => {
    const value = localStorage.getItem('showMetrics');
    if (value) {
      return value === 'true';
    }
    return state.preferences.showMetrics;
  },

  selectShowAdvancedConfig: state => {
    const value = localStorage.getItem('showAdvancedConfig');
    if (value) {
      return value === 'true';
    }
    return state.preferences.showAdvancedConfig;
  },

  selectDisableAutoLogout: state => {
    return state.preferences.disableAutoLogout;
  },

  selectMetricsPanel: state => {
    return (
      localStorage.getItem('metricsPanel') || state.preferences.metricsPanel
    );
  },

  selectRecentItemsValue: state => {
    return state.preferences.recentItem;
  },

  selectRecentItemsInPrivacyModeValue: state => {
    return state.preferences.recentItemsInPrivacyMode;
  },

  selectRecentItemsKey: createSelector('selectPrivacyMode', privacyMode =>
    privacyMode ? 'recentItemsInPrivacyMode' : 'recentItems',
  ),

  selectPreferencesState: state => state.preferences,

  selectRecentItems: createSelector(
    'selectPreferencesState',
    'selectRecentItemsKey',
    'selectRecentItemsInPrivacyModeValue',
    'selectRecentItemsValue',
    (state, recentItemsKey) => {
      console.log('Recent Items Key', recentItemsKey);
      if (state[recentItemsKey]) {
        return state[recentItemsKey];
      }

      const value = localStorage.getItem(recentItemsKey);
      if (value) {
        return JSON.parse(value);
      } else {
        return [];
      }
    },
  ),

  selectFavouriteClients: state => {
    if (state.preferences.favouriteClients) {
      return state.preferences.favouriteClients;
    }

    const value = localStorage.getItem('favouriteClients');
    if (value) {
      return JSON.parse(value);
    } else {
      return [];
    }
  },

  selectRecentSplitTests: state => {
    if (state.preferences.recentSplitTests) {
      return state.preferences.recentSplitTests;
    }

    const value = localStorage.getItem('recentSplitTests');
    if (value) {
      return JSON.parse(value);
    } else {
      return [];
    }
  },

  selectShowBoringAvatars: state => {
    const value = localStorage.getItem('showBoringAvatars');

    if (value) {
      return value === 'true';
    }
    return state.preferences.showBoringAvatars;
  },

  selectShowPipelineFlowchart: state => {
    const value = localStorage.getItem('showPipelineFlowchart');

    if (value) {
      return value === 'true';
    }
    return state.preferences.showPipelineFlowchart;
  },
};
