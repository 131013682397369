import { Attr, HasMany, HasOne, Model, BelongsTo } from 'spraypaint';
import moment from 'moment';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Facility from './facility';
import User from './user';

@Model()
export default class FacilityAccessKey extends ApplicationRecord {
  static jsonapiType = 'facility_access_keys';
  @Attr() lastActivityAt: string;
  @Attr() createdAt: string;

  @Attr() token: string;
  @Attr() name: string;

  @BelongsTo() createdBy: User;
  @BelongsTo() discardedBy: User;
  @BelongsTo() client: Client;
  @BelongsTo() facility: Facility;

  formattedCreatedAt() {
    return this.createdAt
      ? moment
          .utc(this.createdAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedLastActivityAt() {
    return this.lastActivityAt
      ? moment
          .utc(this.lastActivityAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  recentlyUsed() {
    const now = moment(new Date());

    const duration = moment
      .duration(now.diff(moment.utc(this.lastActivityAt)))
      .asMinutes();

    return duration < 60 * 24 * 30;
  }
}
