import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';

@Model()
export default class ChefRecipe extends ApplicationRecord {
  static jsonapiType = 'chef_recipes';
  @Attr() name: string;
  @Attr() fullname: string;
  @Attr() filename: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr({ persist: false }) selected: boolean;
}
