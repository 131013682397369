import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class VpcConfig extends ApplicationRecord {
  static jsonapiType = 'vpc_configs';
  @Attr() vpcDeploymentType: string;
  @Attr() vpcId: string;
  @Attr() privateSubnetAz1Identifier: string;
  @Attr() privateSubnetAz2Identifier: string;
  @Attr() publicSubnetAz1Identifier: string;
  @Attr() publicSubnetAz2Identifier: string;
  @Attr() routeTablePrivateAz1Identifier: string;
  @Attr() routeTablePrivateAz2Identifier: string;

  @Attr() cidrBlock: string;

  @Attr() manualCidrConfiguration: boolean;
  @Attr() publicSubnetAz1Cidr: string;
  @Attr() publicSubnetAz2Cidr: string;
  @Attr() publicSubnetAz3Cidr: string;

  @Attr() localPublicSubnetAz1Cidr: string;
  @Attr() localPublicSubnetAz2Cidr: string;

  @Attr() privateSubnetAz1Cidr: string;
  @Attr() privateSubnetAz2Cidr: string;
  @Attr() privateSubnetAz3Cidr: string;

  @Attr() localPrivateSubnetAz1Cidr: string;
  @Attr() localPrivateSubnetAz2Cidr: string;

  @Attr() natSubnetAz1Cidr: string;
  @Attr() natSubnetAz2Cidr: string;
  @Attr() natSubnetAz3Cidr: string;

  @Attr() firewallSubnetAz1Cidr: string;
  @Attr() firewallSubnetAz2Cidr: string;
  @Attr() firewallSubnetAz3Cidr: string;

  @BelongsTo() facility: Facility;
}
