import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import WekaCluster from './weka_cluster';

@Model()
export default class WekaAdminPasswordRetrieval extends ApplicationRecord {
  static jsonapiType = 'weka_admin_password_retrievals';

  @Attr() password: string;
  @BelongsTo() wekaCluster: WekaCluster;
}
