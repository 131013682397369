import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

@Model()
export default class WorkstationMountsUpdate extends ApplicationRecord {
  static jsonapiType = 'workstation_mounts_updates';

  @BelongsTo() workstation: Workstation;
}
