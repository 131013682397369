import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class FacilityCreationPreview extends ApplicationRecord {
  static jsonapiType = 'facility_creation_previews';

  @Attr() parameters: object;

  @BelongsTo() facility: Facility;
}
