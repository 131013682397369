import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import User from '../../../models/user';

const styles = theme => ({
  rebuildContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  rebuildSteps: {
    paddingLeft: theme.spacing(2),
    minWidth: '350px',
  },
  code: {
    font:
      '13px "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace',
    backgroundColor: '#073642',

    whiteSpace: 'pre',
    color: '#839496',

    padding: '20px',
    overflow: 'scroll',
    height: '100%',
    flexGrow: 1,
  },
  paper: {
    minHeight: '80vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  scroller: {
    width: 1,
  },

  icon: {
    verticalAlign: 'bottom',
  },
  inProgress: {
    paddingTop: '10px',
  },

  complete: {
    backgroundColor: '#1bc943',
  },
  statusContainer: {
    display: 'flex',
  },
  status: {
    marginRight: '10px',
  },
  spacer: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
});

const RebuildDialog = ({
  classes,
  open,
  onClose,
  doRebuildFacility,
  activeUser,
  doCreateFacilityCreationPreview,
}) => {
  const rebuildTypeRef = React.useRef('all');
  const reasonRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  const steps = [
    { label: 'Configuration', name: 'configuration' },
    { label: 'SSM Documents', name: 'ssm-documents' },
    { label: 'Workstation Permissions', name: 'workstation-permissions' },
    { label: 'Event Bridge', name: 'event-bridge' },
    { label: 'Networking', name: 'networking' },
    { label: 'DCV API', name: 'nice-dcv-api' },
    { label: 'DCV Gateway', name: 'nice-dcv-gateway' },
    { label: 'ProfileMetrics', name: 'profile-metrics' },
    { label: 'Storage', name: 'storage' },
  ];

  const [selectedItems, setSelectedItems] = React.useState(
    steps.reduce((acc, step) => ({ ...acc, [step.name]: false }), {}),
  );

  const handleCheckboxChange = event => {
    setSelectedItems({
      ...selectedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const [reason, setReason] = React.useState('');
  const [parameters, setParameters] = React.useState({});

  const handleConfirm = async () => {
    let steps = Object.keys(selectedItems).filter(item => selectedItems[item]);
    if (rebuildType === 'all') {
      steps = [];
    }
    console.log('steps', steps);

    await doRebuildFacility({
      createChangeSet,
      skipLdapDeployment,
      forceCertificateRenewal,
      reason,
      steps,
    });

    onClose();

    setCreateChangeSet(true);
    setForceCertificateRenewal(false);
    setReason('');
  };

  const loading = open;

  const [rebuildType, setRebuildType] = React.useState(rebuildTypeRef.current); // ['all', 'custom'
  const [createChangeSet, setCreateChangeSet] = React.useState(true);
  const [skipLdapDeployment, setSkipLdapDeployment] = React.useState(true);
  const [forceCertificateRenewal, setForceCertificateRenewal] = React.useState(
    false,
  );

  useHotkeys('ctrl+alt+shift+r', () => reasonRef.current.focus(), [reasonRef]);
  useHotkeys('ctrl+alt+shift+c', () => setCreateChangeSet(!createChangeSet), [
    createChangeSet,
  ]);

  useHotkeys(
    'cmd+return',
    () => {
      console.log('Focus on button');
      buttonRef.current.focus();
    },
    [buttonRef],
  );

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await doCreateFacilityCreationPreview();

      if (active) {
        setParameters(response.attributes.parameters);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);
  const handleReasonKeyDown = event => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      // Submit the form
      if (reason.trim() !== '') {
        handleConfirm();
      }
    }
  };
  return (
    <Dialog
      disableBackdropClick
      maxWidth="lg"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
    >
      <DialogTitle>
        Initiate Facility Rebuild
        <Typography variant="body2">
          Facility rebuilds will re-execute the Facility Creation step function.
          This could cause downtime for the facility.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.rebuildContent}>
          <div className={classes.code}>
            {parameters && JSON.stringify(parameters, null, 2)}
          </div>
          <div className={classes.rebuildSteps}>
            <Typography variant="h6">Rebuild Steps</Typography>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Rebuild Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="all"
                name="radio-buttons-group"
                onChange={e => {
                  setRebuildType(e.target.value);
                  rebuildTypeRef.current = e.target.value;
                }}
                value={rebuildType}
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
            </FormControl>
            {rebuildType === 'custom' && (
              <>
                <Divider />
                <FormGroup>
                  {steps.map(step => (
                    <FormControlLabel
                      key={step.name}
                      control={
                        <Checkbox
                          name={step.name}
                          checked={selectedItems[step.name]}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={step.label}
                      disabled={step.disabled || false}
                    />
                  ))}
                </FormGroup>{' '}
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <TextField
          inputRef={reasonRef}
          autoComplete="off"
          margin="dense"
          label="Reason for rebuild"
          variant="outlined"
          value={reason}
          onChange={e => setReason(e.target.value)}
          required
          className={classes.spacer}
          onKeyDown={handleReasonKeyDown}
        />
        {/* <div className={classes.spacer} /> */}
        <FormControlLabel
          value="createChangeSet"
          control={
            <Switch
              checked={createChangeSet}
              onChange={e => setCreateChangeSet(e.target.checked)}
              name="createChangeSet"
            />
          }
          label="Create Change Set"
          labelPlacement="end"
        />
        <FormControlLabel
          value="skipLdapDeployment"
          control={
            <Switch
              checked={skipLdapDeployment}
              onChange={e => setSkipLdapDeployment(e.target.checked)}
              name="createChangeSet"
            />
          }
          label="Skip Ldap Deployment"
          labelPlacement="end"
        />
        <FormControlLabel
          value="forceCertificateRenewal"
          control={
            <Switch
              checked={forceCertificateRenewal}
              onChange={e => setForceCertificateRenewal(e.target.checked)}
              name="forceCertificateRenewal"
            />
          }
          label="Force Certificate Renewal"
          labelPlacement="end"
        />

        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="secondary"
          disabled={reason.trim() === ''}
          ref={buttonRef}
        >
          Begin
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RebuildDialog.propTypes = {
  activeUser: PropTypes.instanceOf(User).isRequired,

  doCreateMasquerade: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(
  'selectActiveUser',
  'doRebuildFacility',
  'doCreateFacilityCreationPreview',
  withStyles(RebuildDialog, styles),
);
