import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import VpcConfig from '../models/vpc_config';
import Directory from '../models/directory';
import Facility from '../models/facility';
import FacilityCidrBlockSuggestion from '../models/facility_cidr_block_suggestion';
import FacilityCreation from '../models/facility_creation';
import FacilityCreationPreview from '../models/facility_creation_preview';
import FacilityDeletion from '../models/facility_deletion';
import FacilitySync from '../models/facility_sync';
import FacilitySyncCheck from '../models/facility_sync_check';
import Group from '../models/group';
import StorageGatewayStatRetrieval from '../models/storage_gateway_stat_retrieval';
import TeamGroupAssignment from '../models/team_group_assignment';
import Workstation from '../models/workstation';
import ConnectionManagerCertificateRenewal from '../models/connection_manager_certificate_renewal';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
import Vpc from '../models/vpc';

// eslint-disable-next-line no-unused-vars
import Image from '../models/image';

// eslint-disable-next-line no-unused-vars
import Keypair from '../models/keypair';

// eslint-disable-next-line no-unused-vars
import Subnet from '../models/subnet';

// eslint-disable-next-line no-unused-vars
import SecurityGroup from '../models/security_group';

// eslint-disable-next-line no-unused-vars
import Template from '../models/template';

// eslint-disable-next-line no-unused-vars
import AvailabilityZone from '../models/availability_zone';

// eslint-disable-next-line no-unused-vars
import StorageGateway from '../models/storage_gateway';
import WekaCluster from '../models/weka_cluster';

// eslint-disable-next-line no-unused-vars
import ConnectionManager from '../models/connection_manager';
import LicenseServer from '../models/license_server';
import Bastion from '../models/bastion';
import WorkstationStatRetrieval from '../models/workstation_stat_retrieval';

const STALE_AFTER = 120000;

export default {
  name: 'facilities',
  getReducer: () => {
    const initialData = {
      loadingActiveFacility: false,
      activeFacility: null,
      lastSuccess: null,
      data: null,
      filteredClientId: null,
      nextFacility: null,

      sortDirection: 'ASC',
      sortBy: 'pcoip_connection_manager_cert_expires_at',
    };
    return (state = initialData, { type, payload }) => {
      if (type === 'SET_NEXT_FACILITY') {
        return {
          ...state,
          nextFacility: payload.facility,
        };
      }
      if (type === 'FETCH_FACILITIES_START') {
        return {
          ...state,
          loading: true,
          filteredClientId: payload.filteredClientId,
        };
      }
      if (type === 'FETCH_FACILITIES_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.result,
        };
      }
      if (type === 'FETCH_FACILITY_START') {
        return { ...state, loadingActiveFacility: true };
      }
      if (type === 'FETCH_FACILITY_SUCCESS') {
        return {
          ...state,
          loadingActiveFacility: false,
          activeFacility: payload.result,
          lastSuccess: payload.time,
          nextFacility: null,
        };
      }
      if (type === 'UPDATE_FACILITY') {
        return {
          ...state,
          activeFacility: payload.facility,
        };
      }
      if (type === 'ADD_FACILITY_WORKSTATION') {
        const { workstation } = payload;

        const nextFacility = state.activeFacility.dup();

        nextFacility.workstations = [...nextFacility.workstations, workstation];

        return {
          ...state,
          activeFacility: nextFacility,
        };
      }
      if (type === 'UPDATE_FACILITY_WORKSTATION') {
        const { workstation } = payload;

        const nextFacility = state.activeFacility.dup();

        nextFacility.workstations = nextFacility.workstations.map(w =>
          w.id === workstation.id ? workstation : w,
        );

        return {
          ...state,
          activeFacility: nextFacility,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          activeFacility: null,
          loadingActiveFacility: false,
        };
      }

      if (type === 'RESET_ACTIVE_TEMPLATE') {
        return {
          ...state,
          activeTemplate: null,
          loadingActiveTemplate: false,
        };
      }

      if (type === 'CHANGE_FACILITY_SORT') {
        const { sortBy, sortDirection } = payload;
        return {
          ...state,
          sortBy,
          sortDirection,
        };
      }

      return state;
    };
  },

  doResetActiveFacility: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_ACTIVE_FACILITY' });
  },

  doFetchAllFacilities: () => async ({ dispatch, getState }) => {
    const state = getState();
    const { sortBy } = state.facilities;
    const { sortDirection } = state.facilities;
    dispatch({
      type: 'FETCH_FACILITIES_START',
      payload: { filteredClientId: null },
    });

    const response = await Facility.where({ kept: true })
      // .includes(['deletion'])
      .order({
        [snakeCase(sortBy)]: sortDirection.toLowerCase(),
      })
      .selectExtra([
        'clientName',
        'regionCode',
        'dcvGatewayCertExpiresAt',
        'dcvGatewayStatus',
        'pcoipConnectionManagerCertExpiresAt',
        'pcoipConnectionManagerStatus',
      ])
      .all();
    dispatch({
      type: 'FETCH_FACILITIES_SUCCESS',
      payload: { result: response.data },
    });
  },

  doFetchFacilities: clientId => async ({ dispatch }) => {
    dispatch({
      type: 'FETCH_FACILITIES_START',
      payload: { filteredClientId: clientId },
    });
    const response = await Facility.where({ client_id: clientId })
      .includes([
        'region',
        'account',
        'workstations',
        'storage_gateway',
        'groups',
      ])
      .all();
    dispatch({
      type: 'FETCH_FACILITIES_SUCCESS',
      payload: { result: response.data },
    });
  },

  doFetchFacility: facilityId => async ({ dispatch, getState, store }) => {
    dispatch({ type: 'FETCH_FACILITY_START' });

    const state = getState();
    const activeClient = store.selectActiveClient(state);

    const privacyMode = store.selectPrivacyMode(state);

    const response = await Facility.includes([
      'region',
      'account',
      'creation',
      'deletion',
      'last_facility_sync',
      // 'completed_facility_sync',
      // { workstations: 'instance_type' },
      'storage_gateway',
      'connection_manager',
      'dcv_gateway',
      'pcoip_connection_manager',
      'license_server',
      'bastion',
      'directory',
      'active_users_stat',
      'workstation_users_xrange_stat',
      'vpc_config',
      { weka_cluster: ['pending_boot', 'pending_shutdown'] },
    ])
      .selectExtra([
        'workstationsOnline',
        'workstationsTotal',
        'renderOnline',
        'renderTotal',
        'vcpuUsage',
      ])
      .find(facilityId);
    store.doAddRecentItem({
      name: response.data.name,
      secondary: activeClient.clientNameOrFake(privacyMode),
      type: 'Facility',
      url: `/clients/${activeClient.id}/facilities/${facilityId}`,
      clientId: activeClient.id,
      facilityId: facilityId,
    });
    dispatch({
      type: 'FETCH_FACILITY_SUCCESS',
      payload: { result: response.data, time: Date.now() },
    });
  },

  doCreateConnectionManagerCertificateRenewal: ({ niceDcv }) => async ({
    store,
    getState,
    dispatch,
  }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();

    const certificateRenewal = new ConnectionManagerCertificateRenewal({
      niceDcv,
      facility,
    });

    await certificateRenewal.save({
      with: 'facility.id',
    });

    return certificateRenewal;
  },

  doCreateFacilityDeletion: keepResources => async ({
    store,
    getState,
    dispatch,
  }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();

    const facilityDeletion = new FacilityDeletion({
      facility,
      keepResources,
    });

    await facilityDeletion.save({
      with: 'facility.id',
    });

    dispatch({
      type: 'UPDATE_FACILITY',
      payload: { facility: facilityDeletion.facility },
    });

    store.doFetchFacility(facility.id);

    return facilityDeletion;
  },

  doRebuildFacility: ({
    createChangeSet,
    skipLdapDeployment,
    forceCertificateRenewal,
    skipTagUpdate,
    reason,
    steps,
  }) => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    console.log('steps', steps);

    const facilityCreation = new FacilityCreation({
      facility,
      createChangeSet,
      skipLdapDeployment,
      forceCertificateRenewal,
      skipTagUpdate,
      reason,
      steps,
    });

    await facilityCreation.save({
      with: 'facility.id',
    });

    store.doFetchFacility(facility.id);
  },

  doRefreshFacility: () => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();

    await store.doFetchFacility(facility.id);
  },

  doCreateFacilityCreation: values => async ({ store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();
    const directories = store.selectDirectories(state);
    // const teamGroupAssignments = values.teams.map(
    //   team => new TeamGroupAssignment({ team }),
    // );

    // const group = new Group({
    //   name: values.groupName,
    //   teamGroupAssignments,
    // });
    //
    const vpcConfig = new VpcConfig({
      ...values.vpcConfig,
    });

    const directory = values.domain
      ? new Directory({
          domain: values.domain,
          netbios: values.netbios,
          realm: values.realm,
          client,
        })
      : null;

    const facility = new Facility({
      deploymentType: values.deploymentType,
      name: values.name,
      region: values.region,
      account: values.account,
      client,
      directory,
      vpcConfig,
      directoryType: values.directoryType,
      // groups: [group],
      cidrBlock: values.cidrBlock,
      imported: values.imported,
      storageInstanceType: values.storageInstanceType,
      wekaInstanceType: values.wekaInstanceType,
      wekaInstanceCount: values.wekaInstanceCount,
      createStorage: values.createStorage,
      createWeka: values.createWeka,
      enableLocalZone: values.enableLocalZone,

      pcoipLicenseServerAddress: values.pcoipLicenseServerAddress,
      defaultTeradiciLicenseCount: values.defaultTeradiciLicenseCount,
      defaultTeradiciLicenseKey: values.defaultTeradiciLicenseKey,
      shouldCreateLicenseServer: values.shouldCreateLicenseServer,
      enableStorageGatewaySmb: values.enableStorageGatewaySmb,
      enableStorageGatewayMonitor: values.enableStorageGatewayMonitor,
      enableFslogix: values.enableFslogix,
      autoAssignComputerOu: values.autoAssignComputerOu,
      mfa: values.mfa,
      desktopBackgroundUrl: values.desktopBackgroundUrl,
      storageBucketName: values.storageBucketName,
      timeZone: values.timeZone,
      enableBootViaBroker: true,
      enableCloudwatchAgent: true,
      kmsKeyId: values.kmsKeyId,
      enableParsec: values.enableParsec,
      enableTeradici: values.enableTeradici,
      parsecTeamId: values.parsecTeamId,
      parsecTeamKey: values.parsecTeamKey,
      parsecApiKey: values.parsecApiKey,
      secretsPrefix: values.secretsPrefix,
      enableSsh: values.enableSsh,
      enableNiceDcv: values.enableNiceDcv,
      enableSplitRoles: values.enableSplitRoles,
      enablePermissionBoundary: values.enablePermissionBoundary,
      useNlb: values.useNlb,
      customParameters: values.customParameters,

      createFsx: values.createFsx,
      enableFsxProfileMetrics: values.enableFsxProfileMetrics,
      fsxStorageSize: values.fsxStorageSize,
      fsxThroughput: values.fsxThroughput,

      createFsxSharedStorage: values.createFsxSharedStorage,
      fsxSharedStorageSize: values.fsxSharedStorageSize,
      fsxSharedStorageThroughput: values.fsxSharedStorageThroughput,

      directoryServiceAccountName: values.directoryServiceAccountName,
      directoryServiceAccountPassword: values.directoryServiceAccountPassword,
      tripleAz: values.tripleAz,
      replicateActiveDirectory: values.replicateActiveDirectory,
      createProfileStorage: values.createProfileStorage,
      profileStorageProvisionedThroughput:
        values.profileStorageProvisionedThroughput,
      enableAdUserManagement: values.enableAdUserManagement,
    });

    const facilityCreation = new FacilityCreation({
      facility,
    });
    await facilityCreation.save({
      with: {
        facility: [
          'region.id',
          'account.id',
          'client.id',
          'vpcConfig',
          { directory: 'client.id' },
          // { groups: { teamGroupAssignments: 'team.id' } },
        ],
      },
    });

    if (facilityCreation.isPersisted) {
      store.doAddFacilityToClient(facilityCreation.facility);
    }

    return facilityCreation;
  },

  doCreateFacilityCreationPreview: () => async ({ store, getState }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    const preview = new FacilityCreationPreview({
      facility,
    });
    await preview.save({ with: ['facility.id'] });

    return preview;
  },

  doCreateFacilityCidrBlockSuggestion: () => async ({ store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();
    const fcbs = new FacilityCidrBlockSuggestion({
      client,
    });
    await fcbs.save({ with: ['client.id'] });

    return fcbs;
  },

  doUpdateFacility: values => async ({ getState, store }) => {
    const state = getState();
    const isJustStarsOrBlank = string => string === '*'.repeat(string.length);
    let nextValues = { ...values };
    if (isJustStarsOrBlank(values.parsecApiKey || '')) {
      delete nextValues.parsecApiKey;
    }

    if (isJustStarsOrBlank(values.parsecTeamKey || '')) {
      delete nextValues.parsecTeamKey;
    }

    if (isJustStarsOrBlank(values.storageGatewayInstanceVersionNumber || '')) {
      delete nextValues.storageGatewayInstanceVersionNumber;
    }

    if (isJustStarsOrBlank(values.directoryServiceAccountPassword || '')) {
      delete nextValues.directoryServiceAccountPassword;
    }

    const facility = store.selectActiveFacility(state).dup();

    delete nextValues.vpcConfig;

    facility.assignAttributes(nextValues);
    if (facility.vpcConfig) {
      facility.vpcConfig.assignAttributes(values.vpcConfig);
    } else {
      const vpcConfig = new VpcConfig({
        ...values.vpcConfig,
      });
      facility.vpcConfig = vpcConfig;
    }

    const success = await facility.save({ with: 'vpcConfig' });
    if (success) {
      store.doFetchFacility(facility.id);
    }
    return facility;
  },

  doAddFacilityToClient: facility => ({ dispatch, store, getState }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();

    if (facility.isPersisted) {
      client.facilities.push(facility);
      dispatch({ type: 'UPDATE_CLIENT', payload: { client } });
    }
  },

  doSyncFacility: () => async ({ getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();

    const facilitySync = new FacilitySync({ facility });

    await facilitySync.save({ with: ['facility.id'] });
    if (facilitySync.isPersisted) {
      store.doFetchFacility(facility.id);
    }
  },

  doCreateFacilitySyncCheck: () => async ({ getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    const facilitySync = facility.pendingFacilitySync;

    const facilitySyncCheck = new FacilitySyncCheck({ facilitySync });
    await facilitySyncCheck.save({ with: ['facilitySync.id'] });

    if (facilitySyncCheck.isPersisted) {
      store.doFetchFacility(facility.id);
    }
  },

  doSetNextFacility: facility => ({ dispatch }) => {
    dispatch({ type: 'SET_NEXT_FACILITY', payload: { facility } });
  },

  doDestroyFacility: facility => async ({ dispatch, store }) => {
    const nextFacility = facility.dup();

    await nextFacility.destroy();
    dispatch({ type: 'REMOVE_FACILITY', payload: { facility: nextFacility } });
    store.doRemoveFacilityFromClient(facility);
  },

  doRemoveFacilityFromClient: facility => ({ dispatch, store, getState }) => {
    const state = getState();
    const nextFacility = facility.dup();
    const client = store.selectActiveClient(state).dup();

    client.facilities = client.facilities.filter(
      item => item.id !== nextFacility.id,
    );
    dispatch({ type: 'UPDATE_CLIENT', payload: { client } });
  },

  doUpdateActiveFacilityCreation: payload => ({
    dispatch,
    store,
    getState,
  }) => {
    const state = getState();

    const facility = store.selectActiveFacility(state).dup();

    const nextFacility = facility.dup();
    nextFacility.creation = FacilityCreation.fromJsonapi(payload.data, payload);

    dispatch({
      type: 'UPDATE_FACILITY',
      payload: { facility: nextFacility },
    });
  },

  doUpdateFacilityWorkstationFromPusher: payload => ({
    dispatch,
    store,
    getState,
  }) => {
    const workstation = Workstation.fromJsonapi(payload.data, payload);

    const { workstations } = store.selectActiveFacility(getState());

    if (workstations.findIndex(w => w.id === workstation.id) === -1) {
      dispatch({
        type: 'ADD_FACILITY_WORKSTATION',
        payload: { workstation },
      });
    } else {
      dispatch({
        type: 'UPDATE_FACILITY_WORKSTATION',
        payload: { workstation },
      });
    }
  },

  doCreateStorageGatewayStatRetrieval: () => async ({
    dispatch,
    store,
    getState,
  }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    const storageGateway = facility.storageGateway;

    const storageGatewayStatRetrieval = new StorageGatewayStatRetrieval({
      storageGateway,
    });

    await storageGatewayStatRetrieval.save({ with: ['storageGateway.id'] });
    await store.doRefreshFacility();
  },

  doChangeFacilitySort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_FACILITY_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchAllFacilities();
  },

  // doCreateWorkstationStatRetrieval: () => async ({
  //   dispatch,
  //   store,
  //   getState,
  // }) => {
  //   try {
  //     const state = getState();
  //     const facility = store.selectActiveFacility(state).dup();
  //     const workstation = store.selectActiveWorkstation(state)?.dup();

  //     const workstationStatRetrieval = new WorkstationStatRetrieval({
  //       facility,
  //       workstation,
  //     });

  //     await workstationStatRetrieval.save({
  //       with: ['facility.id', 'workstation.id'],
  //     });
  //     if (workstation) {
  //       await store.doFetchWorkstationStats(workstation.id);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // },

  selectFacilityState: state => state.facilities,
  selectFacilityLoading: createSelector(
    'selectFacilityState',
    facilityState => facilityState.loading,
  ),
  selectFacilities: createSelector(
    'selectFacilityState',
    facilityState => facilityState.data || [],
  ),

  selectFacilitiesExpired: createSelector(
    'selectFacilityState',
    facilityState =>
      (facilityState.data || []).filter(f => {
        return f.isExpired();
      }),
  ),

  selectFacilitiesExpiringSoon: createSelector(
    'selectFacilityState',
    facilityState =>
      (facilityState.data || []).filter(f => {
        return f.isExpiring();
      }),
  ),

  selectActiveFacility: createSelector(
    'selectActiveFacilityId',
    'selectFacilityState',
    (facilityId, facilityState) => facilityState.activeFacility,
  ),

  selectActiveOrNextFacility: createSelector(
    'selectFacilityState',
    facilityState => facilityState.activeFacility || facilityState.nextFacility,
  ),
  selectNextFacility: createSelector(
    'selectFacilityState',
    facilityState => facilityState.nextFacility,
  ),
  selectLastFacilitySuccess: createSelector(
    'selectFacilityState',
    facilityState => facilityState.lastSuccess,
  ),
  selectFacilityIsStale: createSelector(
    'selectLastFacilitySuccess',
    'selectAppTime',
    (lastSuccessTime, appTime) => {
      if (!lastSuccessTime) {
        return true;
      }
      return appTime - lastSuccessTime > STALE_AFTER;
    },
  ),
  selectFacilityId: createSelector(
    'selectQueryObject',
    queryObject => queryObject.facilityId,
  ),
  selectActiveFacilityId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.facilityId,
  ),

  selectFacilitiesSortBy: state => state.facilities.sortBy,
  selectFacilitiesSortDirection: state => state.facilities.sortDirection,
  // reactShouldFetchFacilities: createSelector(
  //   'selectRouteParams',
  //   'selectPathname',
  //   'selectFacilityLoading',
  //   'selectFacilityState',
  //   (routeParams, path, facilitiesLoading, facilityState) => {
  //     const onNewWorkstationPage =
  //       path.includes('/workstations') && path.includes('/new');
  //     if (facilitiesLoading || !onNewWorkstationPage) {
  //       return null;
  //     }
  //     const clientIdChanged = routeParams.id !== facilityState.filteredClientId;
  //     if (facilityState.data && !clientIdChanged) {
  //       return null;
  //     }
  //     return { actionCreator: 'doFetchFacilities', args: [routeParams.id] };
  //   },
  // ),
  reactShouldFetchActiveFacility: createSelector(
    'selectRouteParams',
    'selectPathname',
    'selectActiveFacility',
    'selectFacilityState',
    'selectFacilityIsStale',
    'selectActiveClient',
    (
      routeParams,
      pathname,
      activeFacility,
      facilityState,
      isStale,
      activeClient,
    ) => {
      const onFacilityPage =
        pathname.includes('/facilities') && !pathname.includes('/workstations');
      const needsActiveFacility = // for the initial load of routes beneath /facilities
        pathname.includes('/facilities') && !activeFacility;
      const requiresUpdate = activeFacility && activeFacility.requiresUpdate;

      if (!activeClient) {
        return null;
      }

      if (
        (!onFacilityPage && !needsActiveFacility) ||
        !routeParams.facilityId ||
        facilityState.loadingActiveFacility
      ) {
        return null;
      }
      if (
        activeFacility &&
        !isStale &&
        !requiresUpdate &&
        routeParams.facilityId === activeFacility.id
      ) {
        return null;
      }

      return {
        actionCreator: 'doFetchFacility',
        args: [routeParams.facilityId],
      };
    },
  ),

  reactShouldResetActiveFacility: createSelector(
    'selectActiveFacilityId',
    'selectActiveFacility',
    'selectFacilityState',
    (activeFacilityId, activeFacility, facilityState) => {
      if (activeFacility && activeFacility.id !== activeFacilityId) {
        return { actionCreator: 'doResetActiveFacility' };
      }

      if (!activeFacilityId && facilityState.loadingActiveFacility) {
        return { actionCreator: 'doResetActiveFacility' };
      }

      return null;
    },
  ),

  reactShouldFetchAllFacilities: createSelector(
    'selectRouteApis',
    'selectFacilities',
    'selectFacilityLoading',
    (apis, facilities, facilitiesLoading) => {
      const wantsFacilities = apis.includes('all_facilities');
      if (!wantsFacilities || facilitiesLoading || facilities.length > 0) {
        return false;
      }
      return { actionCreator: 'doFetchAllFacilities' };
    },
  ),
};
