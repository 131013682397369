import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ClientUserAssignment from './client_user_assignment';
import Facility from './facility';
import WebauthnChallenge from './webauthn_challenge';
import Workstation from './workstation';

@Model()
export default class NiceDcvToken extends ApplicationRecord {
  static jsonapiType = 'nice_dcv_tokens';
  @Attr() token: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() success: boolean;
  @Attr() connectionUrl: string;

  @BelongsTo() workstation: Workstation;
  @BelongsTo() facility: Facility;
  @BelongsTo() webauthnChallenge: WebauthnChallenge;
  @BelongsTo() clientUserAssignment: ClientUserAssignment;
}
