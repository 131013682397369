import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import WekaCluster from './weka_cluster';

@Model()
export default class WekaStatusRetrieval extends ApplicationRecord {
  static jsonapiType = 'weka_status_retrievals';
  @BelongsTo() wekaCluster: WekaCluster;
}
