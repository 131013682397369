import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import User from './user';

@Model()
export default class Masquerade extends ApplicationRecord {
  static jsonapiType = 'masquerades';

  @BelongsTo() user: User;
  @BelongsTo() masqueradeAs: User;

  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr({ persist: false }) selected: boolean;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
