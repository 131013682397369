import { Model, Attr, HasMany, BelongsTo } from 'spraypaint';
import moment from 'moment-timezone';

import { ApplicationRecord } from './application_record';
import Client from './client';
import Region from './region';
import Team from './team';
import ClientUserAssignment from './client_user_assignment';

@Model()
export default class Invitation extends ApplicationRecord {
  static jsonapiType = 'invitations';
  @Attr() email: string;
  @Attr() firstName: string;
  @Attr() lastName: string;
  @Attr() username: string;
  @Attr() clientId: string;
  @Attr() userId: string;

  @Attr() createdAt: string;
  @Attr() acceptedAt: string;
  @Attr() bouncedAt: string;
  @Attr() deliveredAt: string;
  @Attr() openedAt: string;
  @Attr() clickedAt: string;
  @Attr() expired: boolean;
  @Attr() emailBlocked: boolean;

  @Attr() enableNiceDcv: boolean;
  @Attr() enableTeradici: boolean;
  @Attr() enableParsec: boolean;
  @Attr() skipPasswordStep: boolean;

  @BelongsTo() team: Team;

  @Attr() createdByName: string;
  @Attr() clientName: string;

  @BelongsTo() client: Client;
  @BelongsTo() clientUserAssignment: ClientUserAssignment;
  @HasMany() regions: Region[];

  // constructor(attrs?: Record<string, any>) {
  //   super(attrs);
  //   this.selected = false;
  // }
  //
  formattedCreatedAt() {
    return this.createdAt
      ? moment
          .utc(this.createdAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedAcceptedAt() {
    return this.acceptedAt
      ? moment
          .utc(this.acceptedAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedBouncedAt() {
    return this.bouncedAt
      ? moment
          .utc(this.bouncedAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedDeliveredAt() {
    return this.deliveredAt
      ? moment
          .utc(this.deliveredAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedOpenedAt() {
    return this.openedAt
      ? moment
          .utc(this.openedAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  formattedClickedAt() {
    return this.clickedAt
      ? moment
          .utc(this.clickedAt)
          .local()
          .format('MMM Do HH:mm')
      : '';
  }

  isRecentlyAccepted() {
    if (this.acceptedAt) {
      const now = moment(new Date());

      const duration = moment
        .duration(now.diff(moment.utc(this.acceptedAt)))
        .asMinutes();

      return duration < 60;
    }
    return false;
  }

  canReinvite() {
    return !this.acceptedAt && (this.bouncedAt || this.expired);
  }
}
