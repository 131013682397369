import React from 'react';
import { Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import cx from 'classnames';

const overrideStyles = theme => ({});

export default withStyles(({ classes, bottomMargin, fullHeight, className, ...props }) => (
  <Paper
    elevation={1}
    {...props}
    className={cx([
      className,
      bottomMargin && classes.bottomMargin,
      fullHeight && classes.fullHeight,
    ])}
    classes={{ root: classes.root }}
  />
), theme => ({
  root: {
    width: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    // borderWidth: '1px',
    // borderStyle: 'solid',
    borderColor: theme.palette.divider,
    // marginBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      marginTop: 0,

      // marginBottom: theme.spacing(3),
    },
  },
  fullHeight: {
    height: '100%',
  },
  bottomMargin: {
    marginBottom: theme.spacing(2),
  },
}));
