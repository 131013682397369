import { createSelector } from 'redux-bundler';

import Vpc from '../models/vpc';

export default {
  name: 'vpcs',
  getReducer: () => {
    const initialData = {
      loading: false,
      vpcs: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_VPCS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_VPCS_SUCCESS') {
        return {
          ...state,
          loading: false,
          vpcs: payload.result,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          vpcs: null,
        };
      }

      return state;
    };
  },

  doFetchVpcs: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const facility = store.selectActiveFacility(state).dup();
    dispatch({ type: 'FETCH_VPCS' });
    const scope = Vpc.where({
      facility_id: facility.id,
    });

    const response = await scope.all();

    dispatch({
      type: 'FETCH_VPCS_SUCCESS',
      payload: { result: response.data },
    });
  },

  reactShouldFetchVpcs: createSelector(
    'selectRouteApis',
    'selectVpcsRaw',
    'selectCurrentUser',
    'selectActiveClient',
    (apis, vpcsRaw, currentUser, activeClient) => {
      const wantsVpcs = apis.includes('vpcs');
      if (
        !wantsVpcs ||
        vpcsRaw.loading ||
        vpcsRaw.vpcs ||
        !currentUser ||
        !activeClient
      ) {
        return false;
      }
      return { actionCreator: 'doFetchVpcs' };
    },
  ),

  selectVpcsRaw: state => state.vpcs,
  selectVpcs: state => state.vpcs.vpcs,
};
