import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Account from './account';
import AvailabilityZone from './availability_zone';
import Bastion from './bastion';
import Client from './client';
import ConnectionManager from './connection_manager';
import Directory from './directory';
import FacilityCreation from './facility_creation';
import FacilityDeletion from './facility_deletion';
import FacilitySync from './facility_sync';
import Group from './group';
import Image from './image';
import Keypair from './keypair';
import LicenseServer from './license_server';
import Region from './region';
import SecurityGroup from './security_group';
import Stat from './stat';
import StorageGateway from './storage_gateway';
import Subnet from './subnet';
import Template from './template';
import Vpc from './vpc';
import VpcConfig from './vpc_config';
import WekaCluster from './weka_cluster';
import Workstation from './workstation';

@Model()
export default class Facility extends ApplicationRecord {
  static jsonapiType = 'facilities';
  @Attr() name: string;
  @Attr() clientName: string;
  @Attr() preprovisionedFacilityResourcesStackName: string;
  @Attr() regionCode: string;
  @Attr({ persist: false }) clientId: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() discardedAt: string;
  @Attr() pcmHostname: string;
  @Attr() cidrBlock: string;
  @Attr() imported: boolean;
  @Attr() primary: boolean;
  @Attr() storageInstanceType: string;
  @Attr() createStorage: string;
  @Attr() createStorageBucket: boolean;
  @Attr() renderFarmSize: number;
  @Attr() mounts: object;
  @Attr() pcoipLicenseServerAddress: string;
  @Attr() shouldCreateLicenseServer: boolean;
  @Attr() tripleAz: boolean;
  @Attr() createProfileStorage: boolean;
  @Attr() enableBootViaBroker: boolean;
  @Attr() profileStorageProvisionedThroughput: number;
  @Attr() iceThreshold: number;
  @Attr() adDomain: string;
  @Attr() vcpuLimit: number;
  @Attr({ persist: false }) vcpuUsage: object[];
  @Attr() createWeka: boolean;
  @Attr() mfa: boolean;
  @Attr() rfc2307: boolean;
  @Attr() enableCloudwatchAgent: boolean;
  @Attr() enabled: boolean;
  @Attr() enableLocalZone: boolean;
  @Attr() enableSplitRoles: boolean;
  @Attr() enablePermissionBoundary: boolean;
  @Attr() customParameters: object;
  @Attr() useCustomersOwnAd: boolean;
  @Attr() deploymentType: string;
  @Attr() createBroker: boolean;

  @Attr() enableStorageGatewayMonitor: boolean;
  @Attr() enableStorageGatewaySmb: boolean;
  @Attr() enableFirewall: boolean;
  @Attr() enableFslogix: boolean;
  @Attr() autoAssignComputerOu: boolean;
  @Attr() wekaApiToken: string;
  @Attr() wekaInstanceCount: number;
  @Attr() wekaInstanceType: string;
  @Attr() timeZone: string;
  @Attr() internalName: string;
  @Attr() sshAuthorizedCidr1: string;
  @Attr() sshAuthorizedCidr2: string;
  @Attr() enableSsh: boolean;
  @Attr() defaultTeradiciLicenseKey: string;
  @Attr() defaultTeradiciLicenseCount: number;
  @Attr() storageBucketName: string;

  @Attr() storageGatewayInstanceVersionNumber: number;
  @Attr() desktopBackgroundUrl: string;
  @Attr() connectionManagerInstanceVersionNumber: number;
  @Attr() niceDcvInstanceVersionNumber: number;
  @Attr() niceDcvCustomAmiSsmParameter: string;
  @Attr() niceDcvCustomAmi: string;
  @Attr() niceDcvDeploymentType: string;
  @Attr() niceDcvCustomUserData: string;
  @Attr() niceDcvCustomUserDataMode: string;
  @Attr() kmsKeyId: string;
  @Attr() useDefaultKmsKey: boolean;
  @Attr() useNlb: boolean;
  @Attr() secretsPrefix: string;

  @Attr() createFsx: boolean;
  @Attr() fsxStorageSize: number;
  @Attr() fsxThroughput: number;

  @Attr({ persist: false }) profileMetrics: object;

  @Attr() createFsxSharedStorage: boolean;
  @Attr() fsxSharedStorageSize: number;
  @Attr() fsxSharedStorageThroughput: number;
  @Attr() enableFsxProfileMetrics: boolean;

  @Attr() enableTeradici: boolean;
  @Attr() enableParsec: boolean;
  @Attr() enableNiceDcv: boolean;
  @Attr() parsecTeamId: string;
  @Attr() parsecTeamKey: string;
  @Attr() parsecApiKey: string;
  @Attr() requireWebauthnToConnect: boolean;

  @Attr() directoryServiceAccountName: string;
  @Attr() directoryServiceAccountPassword: string;
  @Attr() directoryServiceAccountPasswordSet: boolean;
  @Attr() replicateActiveDirectory: boolean;
  @Attr() enableAdUserManagement: boolean;

  @Attr() parsecTeamKeySet: boolean;
  @Attr() parsecApiKeySet: boolean;
  @Attr() brokerSetupComplete: boolean;
  @Attr() connectionManagerSetupComplete: boolean;
  @Attr() initialWorkstationSetupComplete: boolean;

  @Attr() computerOu: string;
  @Attr() directoryType: string;

  @HasOne() activeUsersStat: Stat;
  @HasOne() workstationUsersXrangeStat: Stat;

  @Attr() workstationsOnline: number;
  @Attr() workstationsTotal: number;

  @Attr() renderOnline: number;
  @Attr() renderTotal: number;

  @Attr() dcvGatewayCertExpiresAt: string;
  @Attr() dcvGatewayStatus: string;

  @Attr() pcoipConnectionManagerCertExpiresAt: string;
  @Attr() pcoipConnectionManagerStatus: string;

  @Attr({ persist: false }) selected: boolean;
  @Attr({ persist: false }) requiresUpdate: boolean;

  @BelongsTo() client: Client;
  @BelongsTo() region: Region;
  @BelongsTo() account: Account;
  @HasOne() lastFacilitySync: FacilitySync;
  // @HasOne() pendingFacilitySync: FacilitySync;
  // @HasOne() completedFacilitySync: FacilitySync;

  @HasMany() vpcs: Vpc[];
  @HasMany() images: Image[];
  @HasMany() keypairs: Keypair[];
  @HasMany() subnets: Subnet[];
  @HasMany() securityGroups: SecurityGroup[];
  @HasMany() availabilityZones: AvailabilityZone[];
  @HasMany() templates: Template[];
  @HasMany() workstations: Workstation[];

  @HasMany() groups: Group[];

  @BelongsTo() directory: Directory;
  @HasOne() storageGateway: StorageGateway;
  @HasOne() wekaCluster: WekaCluster;
  @HasOne() connectionManager: ConnectionManager;
  @HasOne() pcoipConnectionManager: ConnectionManager;
  @HasOne() dcvGateway: ConnectionManager;
  @HasOne() creation: FacilityCreation;
  @HasOne() deletion: FacilityDeletion;
  @HasOne() licenseServer: LicenseServer;
  @HasOne() bastion: Bastion;
  @HasOne() vpcConfig: VpcConfig;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
    this.requiresUpdate = false;
  }

  isDiscarded() {
    return Boolean(this.discardedAt);
  }

  isOnPrem() {
    return this.deploymentType === 'on_prem';
  }

  isCloud() {
    return this.deploymentType !== 'on_prem';
  }

  avatarColor() {
    const colors = [
      '#FFB900',
      '#D83B01',
      '#B50E0E',
      '#E81123',
      '#B4009E',
      '#5C2D91',
      '#0078D7',
      '#00B4FF',
      '#008272',
      '#107C10',
    ];

    let sum = 0;
    for (let index = 0; index < this.name.length; index += 1) {
      sum += this.name.charCodeAt(index);
    }
    return colors[sum % colors.length];
  }

  storageGatewayHostname() {
    const shortId = this.id && this.id.split('-')[0];
    return `storage.${shortId ||
      '-'}.${this.adDomain.toLowerCase()}.arch.internal`;
  }

  storageGatewayShare() {
    const shortId = this.id && this.id.split('-')[0];
    return `f-${shortId || '-'}-storage-bucket`;
  }

  shortId() {
    return this.id && this.id.split('-')[0];
  }

  workstationCount() {
    return this.workstations && this.workstations.filter(w => !w.render).length;
  }

  workstationRunningCount() {
    return (
      this.workstations &&
      this.workstations.filter(w => !w.render && w.isRunning()).length
    );
  }

  workstationStoppedCount() {
    return (
      this.workstations &&
      this.workstations.filter(w => !w.render && w.isStopped()).length
    );
  }

  renderCount() {
    return this.workstations && this.workstations.filter(w => w.render).length;
  }

  renderRunningCount() {
    return (
      this.workstations &&
      this.workstations.filter(w => w.render && w.isRunning()).length
    );
  }

  renderStoppedCount() {
    return (
      this.workstations &&
      this.workstations.filter(w => w.render && w.isStopped()).length
    );
  }

  nameOrFake(fake: Boolean) {
    if (fake) {
      return this.internalName || this.name;
    }

    return this.name;
  }

  isPcoipExpiring() {
    return (
      new Date(this.pcoipConnectionManagerCertExpiresAt) > new Date() &&
      new Date(this.pcoipConnectionManagerCertExpiresAt) <
        new Date(new Date().setDate(new Date().getDate() + 14))
    );
  }

  isPcoipExpired() {
    if (this.pcoipConnectionManagerCertExpiresAt == null) {
      return false;
    }
    return new Date(this.pcoipConnectionManagerCertExpiresAt) < new Date();
  }

  isDcvExpiring() {
    return (
      new Date(this.dcvGatewayCertExpiresAt) > new Date() &&
      new Date(this.dcvGatewayCertExpiresAt) <
        new Date(new Date().setDate(new Date().getDate() + 14))
    );
  }

  isDcvExpired() {
    if (this.dcvGatewayCertExpiresAt == null) {
      return false;
    }
    return new Date(this.dcvGatewayCertExpiresAt) < new Date();
  }

  isExpired() {
    return this.isPcoipExpired() || this.isDcvExpired();
  }

  isExpiring() {
    return this.isPcoipExpiring() || this.isDcvExpiring();
  }
}
