import { Container, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import React from 'react';

import ArchPageContent from '../../../molecules/ArchPageContent';
import Breadcrumbs, { Breadcrumb } from '../../../organisms/Breadcrumbs';
import BrokerSetup from './BrokerSetup';
import ConnectionManagerSetup from './ConnectionManagerSetup';
import PageTitle from '../../../atoms/PageTitle';
import WorkstationSetup from './WorkstationSetup';

const Setup = ({ classes, activeClient, activeFacility, baseUrl }) => {
  const [showConfiguration, setShowConfiguration] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [brokerComplete, setBrokerComplete] = React.useState(false);
  if (!activeClient || !activeFacility) {
    return (
      <>
        <PageTitle>Facility</PageTitle>
        <ArchPageContent>
          <Container>
            <Skeleton />
          </Container>
        </ArchPageContent>
      </>
    );
  }
  return (
    <>
      <PageTitle>Facility Setup</PageTitle>
      <Breadcrumbs>
        <Breadcrumb to={`/clients/${activeClient.id}`}>
          {activeClient.name}
        </Breadcrumb>

        <Breadcrumb to={`${baseUrl}`}>{activeFacility.name}</Breadcrumb>
        <Breadcrumb>On Prem Setup</Breadcrumb>
      </Breadcrumbs>
      <ArchPageContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Facility Set Up
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} md={12}>
            <BrokerSetup />
          </Grid>

          <Grid item xs={12} md={12}>
            <ConnectionManagerSetup />
          </Grid>

          <Grid item xs={12} md={12}>
            <WorkstationSetup />
          </Grid>
        </Grid>
      </ArchPageContent>
    </>
  );
};

const styles = theme => ({
  setupSection: {
    position: 'relative',
  },
  code: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingRight: theme.spacing(4),
  },
  copy: {
    right: theme.spacing(1),
    top: theme.spacing(2),
    position: 'absolute',
  },
});

export default connect(
  'doUpdateUrl',
  'doCreateFacilityCreation',
  'doCreateFacilityCidrBlockSuggestion',
  'selectActiveClient',
  'selectActiveFacility',
  'selectDirectories',
  'selectBaseUrl',
  withStyles(Setup, styles),
);
