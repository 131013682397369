import { Model, Attr, HasMany, BelongsTo } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Invitation from './invitation';

@Model()
export default class InvitationAcceptance extends ApplicationRecord {
  static jsonapiType = 'invitation_acceptances';

  @BelongsTo() invitation: Invitation;
}
