import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class ConnectionManagerCertificateRenewal extends ApplicationRecord {
  static jsonapiType = 'connection_manager_certificate_renewals';
  @Attr() niceDcv: boolean;

  @BelongsTo() facility: Facility;
}
