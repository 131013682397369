import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import theme from '../theme';

const withLightTheme = Component => props => {
  const lightTheme = createTheme(theme(() => 'light'));

  return (
    <ThemeProvider theme={lightTheme}>
      <Component {...props} />
    </ThemeProvider>
  );
};
export default withLightTheme;
