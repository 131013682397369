import { Attr, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ChefRecipe from './chef_recipe';

@Model()
export default class ChefCookbook extends ApplicationRecord {
  static jsonapiType = 'chef_cookbooks';
  @Attr() name: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr({ persist: false }) selected: boolean;
  @Attr({ persist: false }) expanded: boolean;

  @HasMany() recipes: ChefRecipe[];
}
