import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import FacilityAccessKey from '../models/facility_access_key';

export default {
  name: 'facilityAccessKeys',
  getReducer: () => {
    const initialData = {
      loading: false,
      data: null,

      sortDirection: 'ASC',
      sortBy: 'name',
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_FACILITY_ACCESS_KEYS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_FACILITY_ACCESS_KEYS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.result,
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'CHANGE_FACILITY_ACCESS_KEY_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }

      return state;
    };
  },

  doFetchFacilityAccessKeys: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const client = store.selectActiveClient(state).dup();
    const facility = store.selectActiveFacility(state).dup();

    const { sortBy } = state.facilityAccessKeys;
    const { sortDirection } = state.facilityAccessKeys;

    dispatch({ type: 'FETCH_FACILITY_ACCESS_KEYS' });
    const scope = FacilityAccessKey.where({
      client_id: client.id,
      facility_id: facility.id,
    })
      .includes(['createdBy', 'discardedBy'])
      .order({
        [snakeCase(sortBy)]: sortDirection.toLowerCase(),
      });
    const response = await scope.all();

    dispatch({
      type: 'FETCH_FACILITY_ACCESS_KEYS_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeFacilityAccessKeySort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_FACILITY_ACCESS_KEY_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchFacilityAccessKeys();
  },

  doCreateFacilityAccessKey: name => async ({ getState, store }) => {
    const state = getState();

    const client = store.selectActiveClient(state).dup();
    const facility = store.selectActiveFacility(state).dup();
    const facilityAccessKey = new FacilityAccessKey({
      name,
      client,
      facility,
    });

    await facilityAccessKey.save({
      with: ['client.id', 'facility.id'],
    });

    return facilityAccessKey;
  },

  doDestroyFacilityAccessKey: facilityAccessKey => async ({ dispatch }) => {
    await facilityAccessKey.destroy();
  },

  reactShouldFetchFacilityAccessKeys: createSelector(
    'selectRouteApis',
    'selectFacilityAccessKeysRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    (
      apis,
      facilityAccessKeysRaw,
      currentUser,
      activeClient,
      activeFacility,
    ) => {
      const wantsFacilityAccessKeys = apis.includes('facilityAccessKeys');

      if (!wantsFacilityAccessKeys) return false;
      if (!currentUser) return false;
      if (!activeClient) return false;
      if (!activeFacility) return false;

      if (facilityAccessKeysRaw.loading || facilityAccessKeysRaw.data) return;

      return { actionCreator: 'doFetchFacilityAccessKeys' };
    },
  ),

  selectFacilityAccessKeysRaw: state => state.facilityAccessKeys,
  selectFacilityAccessKeys: state => state.facilityAccessKeys.data,

  selectFacilityAccessKeysSortBy: state => state.facilityAccessKeys.sortBy,
  selectFacilityAccessKeysSortDirection: state =>
    state.facilityAccessKeys.sortDirection,
};
