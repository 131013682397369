import { connect } from 'redux-bundler-react';
import Pusher from 'pusher-js';
import React from 'react';
import PropTypes from 'prop-types';

import PusherContext from './PusherContext';
import ManeAuth from '../../../models/mane_auth';

Pusher.Runtime.createXHR = function() {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  return xhr;
};

class PusherContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pusher: null,
      connected: false,
      lastJwt: null,
    };
  }

  async componentDidMount() {
    const { doFetchPusherConfiguration } = this.props;

    doFetchPusherConfiguration();
  }

  componentDidUpdate() {
    const { connected, lastJwt, pusher: prevPusher } = this.state;
    const { pusherConfiguration, jwtToken } = this.props;
    const { key, cluster, authEndpoint } = pusherConfiguration;

    const configComplete = Boolean(key && cluster);

    if (!configComplete) return;

    // const isJwtTokenSame = jwtToken === lastJwt;

    if (connected) {
      return;
    }

    if (prevPusher) {
      console.log('Disconnecting Pusher');
      prevPusher.disconnect();
    }

    const pusher = new Pusher(key, {
      cluster,
      forceTLS: true,
      channelAuthorization: {
        endpoint: authEndpoint,
        headersProvider: () => {
          return {
            'x-bff-csrf': ManeAuth.getCsrf(),
          };
        },
      },
    });
    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ pusher, connected: true, lastJwt: jwtToken });
  }

  componentWillUnmount() {
    const { pusher } = this.state;

    if (pusher) {
      pusher.disconnect();
    }
  }

  render() {
    const {
      props: { children },
      state: { pusher },
    } = this;

    return (
      <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>
    );
  }
}

PusherContainer.propTypes = {
  children: PropTypes.node.isRequired,
  pusherConfiguration: PropTypes.shape({
    key: PropTypes.string,
    cluster: PropTypes.string,
    authEndpoint: PropTypes.string,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  jwtToken: PropTypes.string,
  doFetchPusherConfiguration: PropTypes.func.isRequired,
};

PusherContainer.defaultProps = {
  jwtToken: null,
};

export default connect(
  'selectPusherConfiguration',
  'doFetchPusherConfiguration',
  'selectJwtToken',
  PusherContainer,
);
