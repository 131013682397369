import { LinearProgress, Toolbar, Typography, lighten } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import React from 'react';

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
//Unexpected value type of ConditionalExpression.
const useToolbarStyles = makeStyles()(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: '#3f51b5',
        },
  spacer: {},
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {},
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
  },
}));

export default function ArchTableToolbar(props) {
  const { classes, cx } = useToolbarStyles();
  const { numSelected, title, loading, children } = props;

  return (
    <Toolbar
      className={cx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {title}
          </Typography>
        )}
      </div>

      <div className={classes.spacer} />
      <div className={classes.actions}>{children}</div>
      {loading && <LinearProgress className={classes.progress} />}
    </Toolbar>
  );
}
