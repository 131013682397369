import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';

import Facility from './facility';

@Model()
export default class FacilityDeletion extends ApplicationRecord {
  static jsonapiType = 'facility_deletions';
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() keepResources: boolean;
  @Attr() state: string;

  @Attr() executionRegion: string;
  @Attr() executionArn: string;

  @BelongsTo() facility: Facility;

  isComplete() {
    return this.state === 'complete';
  }

  isPending() {
    return this.state === 'started' || this.state === 'pending';
  }

  executionUrl() {
    return `https://${this.executionRegion}.console.aws.amazon.com/states/home?region=${this.executionRegion}#/executions/details/${this.executionArn}`;
  }
}
