import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';

@Model()
export default class WorkosDirectorySyncRepair extends ApplicationRecord {
  static jsonapiType = 'workos_directory_sync_repairs';
  @BelongsTo() client: Client;
}
