import { connect } from 'redux-bundler-react';

const AuthorizationCheck = ({
  children,
  authenticatedUser,
  permissionName,
  permissionNames,
}) => {
  if (
    authenticatedUser &&
    permissionName &&
    authenticatedUser.isAuthorized(permissionName)
  ) {
    return children;
  }

  if (
    authenticatedUser &&
    permissionNames &&
    authenticatedUser.isAuthorizedAny(permissionNames)
  ) {
    return children;
  }

  return null;
};

export default connect(
  'selectAuthenticatedUser',
  AuthorizationCheck,
);
