import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

const PageTitle = ({ children }) => (
  <Helmet>
    <title>
      {children}
      {process.env.NODE_ENV !== 'production'
        ? ` - ${process.env.NODE_ENV}`
        : ''}
    </title>
  </Helmet>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageTitle;
