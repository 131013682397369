import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class StorageGateway extends ApplicationRecord {
  static jsonapiType = 'storage_gateways';
  @Attr() status: string;
  @Attr() instanceType: string;
  @Attr() availabilityZoneName: string;
  @Attr() identifier: string;
  @Attr() ipAddress: string;
  @Attr() clockSpeed: number;
  @Attr() metadata: any;
  @Attr() stats: object;
  @Attr() vcpus: number;
  @Attr() memorySize: number;
  @Attr() bucketSize: number;
  @Attr() cacheAllocatedInBytes: number;
  @Attr() cacheUsedPercentage: number;
  @Attr() cacheDirtyPercentage: number;
  @Attr() cacheHitPercentage: number;
  @Attr() cacheMissPercentage: number;
  @Attr() activeDirectoryDomain: string;
  @Attr() activeDirectoryStatus: string;
  @Attr() smbSecurityStrategy: string;

  @BelongsTo() facility: Facility;

  bucketSizeUnits(): string {
    if (this.bucketSize === 0) return 'GB';

    const k = 1000;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(this.bucketSize) / Math.log(k));
    return sizes[i];
  }

  formattedBucketSize(decimals = 0, withUnits = true) {
    const zeroBytes = withUnits ? '0 GB' : '0';
    if (this.bucketSize === 0) return zeroBytes;

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(this.bucketSize) / Math.log(k));

    return (
      parseFloat((this.bucketSize / Math.pow(k, i)).toFixed(dm)) +
      (withUnits ? ' ' + this.bucketSizeUnits() : '')
    );
  }
  formattedCacheSize(decimals = 2, withUnits = true) {
    const zeroBytes = withUnits ? '0 Bytes' : '0';
    if (this.cacheAllocatedInBytes === 0) return zeroBytes;

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(this.cacheAllocatedInBytes) / Math.log(k));

    return (
      parseFloat((this.cacheAllocatedInBytes / Math.pow(k, i)).toFixed(dm)) +
      ' ' +
      sizes[i]
    );
  }
  primaryFileShare() {
    const { metadata } = this;
    let primary = metadata.file_shares.find(
      (fs: any) => fs.bucket_size_history.length > 0,
    );
    if (primary) {
      return primary;
    }

    return metadata.file_shares[0];
  }
}
