import { createSelector } from 'redux-bundler';

import Invitation from '../models/invitation';
import InvitationAcceptance from '../models/invitation_acceptance';

export default {
  name: 'invitations',
  getReducer: () => {
    const initialData = {
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_INVITATION_START') {
        return {
          ...state,
          loading: true,
          loaded: false,
          error: false,
        };
      }
      if (type === 'FETCH_INVITATION_SUCCESS') {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          activeInvitation: payload.result,
        };
      }

      if (type === 'FETCH_INVITATION_FAILURE') {
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
          activeInvitation: null,
        };
      }

      return state;
    };
  },

  doReinvite: oldInvitation => async ({ getState, store }) => {
    const state = getState();

    const activeClient = store.selectActiveClient(state);
    const activeUser = store.selectActiveUser(state);

    const clientUserAssignment = activeUser.clientUserAssignmentForClient(
      activeClient,
    );

    const invitation = new Invitation({
      firstName: oldInvitation.firstName,
      lastName: oldInvitation.lastName,
      email: oldInvitation.email,
      username: oldInvitation.username,
      client: activeClient,
      clientUserAssignment: clientUserAssignment,
      team: oldInvitation.team,
    });

    const success = await invitation.save({
      with: ['client.id', 'clientUserAssignment.id', 'team.id'],
    });
    if (success) {
      store.doResetUser();
      store.doFetchUsers();
    }
    return invitation;
  },
  doCreateInvitation: values => async ({ getState, store }) => {
    const state = getState();

    const activeClient = store.selectActiveClient(state);

    const invitation = new Invitation({
      ...values,
      client: activeClient,
    });

    const success = await invitation.save({
      with: ['client.id', 'team.id'],
    });
    if (success) {
      store.doResetUser();
      store.doFetchUsers();
    }
    return invitation;
  },

  doCreateInvitationAcceptance: () => async ({ getState, store }) => {
    const state = getState();

    const activeInvitation = store.selectActiveInvitation(state);

    const invitationAcceptance = new InvitationAcceptance({
      invitation: activeInvitation,
    });

    const success = await invitationAcceptance.save({
      with: ['invitation.id'],
    });
    if (success) {
      return invitationAcceptance;
    }
  },

  doFetchActiveInvitation: invitationId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_INVITATION_START' });

    try {
      const response = await Invitation.find(invitationId);
      dispatch({
        type: 'FETCH_INVITATION_SUCCESS',
        payload: { result: response.data },
      });
    } catch (err) {
      dispatch({
        type: 'FETCH_INVITATION_FAILURE',
      });
    }
  },

  selectInvitationsState: state => state.invitations,

  selectActiveInvitation: createSelector(
    'selectInvitationsState',
    invitationsState => invitationsState.activeInvitation,
  ),

  reactShouldFetchActiveInvitation: createSelector(
    'selectRouteApis',
    'selectRouteParams',
    'selectPathname',
    'selectActiveInvitation',
    'selectInvitationsState',
    (apis, routeParams, pathname, activeInvitation, state) => {
      const wantsInvitation = apis.includes('invitation');
      if (
        !wantsInvitation ||
        !routeParams.invitationId ||
        state.loading ||
        state.error
      ) {
        return null;
      }
      if (
        activeInvitation &&
        activeInvitation.id === routeParams.invitationId
      ) {
        return null;
      }

      return {
        actionCreator: 'doFetchActiveInvitation',
        args: [routeParams.invitationId],
      };
    },
  ),
};
