import { Alert } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { connect } from 'redux-bundler-react';
import PropTypes from 'prop-types';
import React from 'react';

import Client from '../../../models/client';
import ClientPrivacyMask from '../../atoms/ClientPrivacyMask';
import Facility from '../../../models/facility';

const styles = {
  content: {
    padding: 24,
  },
  divider: {
    marginTop: 24,
    marginBottom: 12,
  },
  option: {
    lineHeight: 1,
  },
  label: {
    lineHeight: 1,
  },
  options: {
    marginTop: 12,
  },
};

const DeleteFacilityDialog = ({
  open,
  onClose,
  doCreateFacilityDeletion,
  activeFacility,
  activeClient,
  classes,
}) => {
  const [keepResources, setKeepResources] = React.useState(false);
  const [confirmationText, setConfirmationText] = React.useState('');
  const [confirmed, setConfirmed] = React.useState(false);

  React.useEffect(() => {
    setConfirmed(confirmationText === activeFacility.name);
  });

  // Reset dialog
  React.useEffect(() => {
    setConfirmationText('');
    setConfirmed(false);
    setKeepResources(false);
  }, [open]);

  const handleConfirm = async () => {
    await doCreateFacilityDeletion(keepResources);

    onClose();
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={open}
      onBackdropClick={handleClose}
    >
      <DialogTitle>Delete {activeFacility.name}</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        Are you sure you want to delete{' '}
        <ClientPrivacyMask client={activeClient} />
        &apos;s facility, <strong>{activeFacility.name}?</strong>
        <div className={classes.options}>
          <FormControlLabel
            control={
              <Checkbox
                checked={keepResources}
                onChange={e => setKeepResources(e.target.checked)}
                name="checkedA"
              />
            }
            label={
              <>
                <Typography className={classes.option} variant="body2">
                  Preserve resources
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Removes facility from Arch, but maintains all infrastructure
                </Typography>
              </>
            }
            classes={{ label: classes.label }}
          />
        </div>
        <div className={classes.divider} />
        <div>
          To confirm deletion, enter the name of the facility
          <TextField
            required
            id="outlined-required"
            fullWidth
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={confirmationText}
            onChange={e => setConfirmationText(e.target.value)}
          />{' '}
        </div>
        <Alert severity="error">
          Prior to deletion, complete the{' '}
          <a
            href="https://github.com/ArchPlatform/mane/wiki/Facility-Decommissioning"
            target="_blank"
            rel="noopener noreferrer"
          >
            facility decomissioning workflow
          </a>
        </Alert>
      </DialogContent>

      <Divider />
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} disabled={!confirmed}>
          Delete Facility
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteFacilityDialog.propTypes = {
  activeClient: PropTypes.instanceOf(Client).isRequired,
  activeFacility: PropTypes.instanceOf(Facility).isRequired,

  doCreateFacilityDeletion: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(
  'selectActiveClient',
  'selectActiveFacility',
  'doCreateFacilityDeletion',
  withStyles(DeleteFacilityDialog, styles),
);
