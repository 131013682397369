import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';

@Model()
export default class WekaConfigurationUpload extends ApplicationRecord {
  static jsonapiType = 'weka_configuration_uploads';

  @Attr() upload: string;
}
