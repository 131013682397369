import { lighten } from '@mui/material/styles';

const theme = getPaletteType => ({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&>.MuiRadio-root': {
            padding: '8px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
          '& .MuiToolbar-root': {
            paddingLeft: 16,
            paddingRight: 8,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          paddingTop: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-containedInherit': {
            color: 'rgba(0, 0, 0, 0.87)',
          },
          '&.MuiButton-outlined': {
            color:
              getPaletteType() === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
            borderColor:
              getPaletteType() === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
          },
          '&.MuiButton-text': {
            color:
              getPaletteType() === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color:
              getPaletteType() === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
          },
        },
      },
    },
    // MuiDialogActions: {
    //   styleOverrides: {
    //     root: {
    //       '& .MuiButtonBase-root': {
    //         color:
    //           getPaletteType() === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
    //       },
    //     },
    //   },
    // },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.MuiSvgIcon-colorDisabled': {
            color:
              getPaletteType() === 'light'
                ? 'rgba(0, 0, 0, 0.3)'
                : 'rgba(255, 255, 255, 0.3)',
          },
        },
      },
    },
  },
  shadows: [
    'none', // 0
    getPaletteType() === 'light'
      ? '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)'
      : '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)',
    '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)', // 2
    '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)', // 3
    '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)', // 4
    '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)', // 5
    '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 6px -2px rgba(0,0,0,0.50)', // 6
    '0 0 1px 0 rgba(0,0,0,0.70), 0 4px 8px -2px rgba(0,0,0,0.50)', // 7
    '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)', // 8
    '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)', // 9
    '0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50)', // 10
    '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 10px -2px rgba(0,0,0,0.50)', // 11
    '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 10px -2px rgba(0,0,0,0.50)', // 12
    '0 0 1px 0 rgba(0,0,0,0.70), 0 6px 10px -2px rgba(0,0,0,0.50)', // 13
    '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 10px -2px rgba(0,0,0,0.50)', // 14
    '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 10px -2px rgba(0,0,0,0.50)', // 15
    '0 0 1px 0 rgba(0,0,0,0.70), 0 7px 10px -2px rgba(0,0,0,0.50)', // 16
    '0 0 1px 0 rgba(0,0,0,0.70), 0 8px 12px -2px rgba(0,0,0,0.50)', // 17
    '0 0 1px 0 rgba(0,0,0,0.70), 0 8px 12px -2px rgba(0,0,0,0.50)', // 18
    '0 0 1px 0 rgba(0,0,0,0.70), 0 8px 12px -2px rgba(0,0,0,0.50)', // 19
    '0 0 1px 0 rgba(0,0,0,0.70), 0 9px 12px -2px rgba(0,0,0,0.50)', // 20
    '0 0 1px 0 rgba(0,0,0,0.70), 0 9px 12px -2px rgba(0,0,0,0.50)', // 21
    '0 0 1px 0 rgba(0,0,0,0.70), 0 9px 12px -2px rgba(0,0,0,0.50)', // 22
    '0 0 1px 0 rgba(0,0,0,0.70), 0 10px 14px -2px rgba(0,0,0,0.50)', // 23
    '0 0 1px 0 rgba(0,0,0,0.70), 0 10px 14px -2px rgba(0,0,0,0.50)', // 24
  ],
  palette: {
    mode: getPaletteType(),
    muiDefaultColor: {
      main: getPaletteType() === 'light' ? '#3f51b5' : '#3f51b5',
      dark: '#303f9f',
      contrastText: 'white',
    },
    background: {
      paper: getPaletteType() === 'dark' ? '#282C34' : '#fff',
      default: getPaletteType() === 'dark' ? '#1c1f24' : '#fafafa',
    },
    primary: {
      main: getPaletteType() === 'light' ? '#639' : '#639', // blue[200],
    },
    secondary: {
      main:
        getPaletteType() === 'light'
          ? lighten('#639', 0.1)
          : lighten('#639', 0.2),
    },
    contrast: {
      main: getPaletteType() === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff',
    },
  },
});
export default theme;
