import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import Region from './region';
import Subnet from './subnet';

@Model()
export default class Vpc extends ApplicationRecord {
  static jsonapiType = 'vpcs';
  @Attr() name: string;
  @Attr() identifier: string;
  @Attr() availabilityZoneId: string;
  @Attr() default: boolean;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;
  @BelongsTo() region: Region;
  @HasMany() subnets: Subnet[];

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
