import { connect } from 'redux-bundler-react';
import React, { Component } from 'react';
import { Button, TextField } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    background: '#fafafa',
  },
});

const SplitTest = ({ branchNameParam, classes, doAddSplitTest }) => {
  React.useEffect(() => {
    var now = new Date();
    var expires = now.getTime() + 1000 * 3600 * 24 * 365;
    now.setTime(expires);

    var randomuuid = crypto.randomUUID();

    document.cookie = `nf_ab=${branchNameParam}; expires=${now.toUTCString()}; path=/`;
    document.cookie = `arch_uuid=${randomuuid}; expires=${now.toUTCString()}; path=/`;

    doAddSplitTest(branchNameParam);

    const timer = setTimeout(() => {
      window.location = '/';
    }, 200);
    return () => clearTimeout(timer);
  }, [branchNameParam]);

  return <div className={classes.root}>Please wait...</div>;
};

export default connect(
  'selectBranchNameParam',
  'doAddSplitTest',
  withStyles(SplitTest, styles, { withTheme: true }),
);
