import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import InstanceType from './instance_type';

@Model()
export default class InstanceTypeOffering extends ApplicationRecord {
  static jsonapiType = 'instance_type_offerings';
  @Attr() identifier: string;
  @Attr({ persist: false }) selected: boolean;

  @BelongsTo() facility: Facility;
  @BelongsTo() instanceType: InstanceType;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
