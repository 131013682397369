import { createSelector } from 'redux-bundler';

import DashboardStat from '../models/dashboard_stat';

const STALE_AFTER = 120000;
export default {
  name: 'dashboard_stats',
  getReducer: () => {
    const initialData = {
      loadedAt: null,
      loading: false,
      item: null,
    };
    // eslint-disable-next-line no-unused-vars
    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_DASHBOARD_STATS') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_DASHBOARD_STATS_SUCCESS') {
        return {
          ...state,
          loading: false,
          item: payload.result,
          loadedAt: Date.now(),
        };
      }

      return state;
    };
  },

  doFetchDashboardStats: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    dispatch({ type: 'FETCH_DASHBOARD_STATS' });

    const dashboardStat = new DashboardStat();

    const response = await dashboardStat.save();

    dispatch({
      type: 'FETCH_DASHBOARD_STATS_SUCCESS',
      payload: { result: dashboardStat },
    });
  },

  reactShouldFetchDashboardStats: createSelector(
    'selectRouteApis',
    'selectDashboardStats',
    'selectCurrentUser',
    'selectDashboardStatsAreStale',
    (apis, dashboardStats, currentUser, dashboardStatsAreStale) => {
      const wantsDashboardStats = apis.includes('dashboard_stats');
      if (
        !wantsDashboardStats ||
        dashboardStats.loading ||
        (dashboardStats.item && !dashboardStatsAreStale) ||
        !currentUser
      ) {
        return false;
      }
      return { actionCreator: 'doFetchDashboardStats' };
    },
  ),

  selectDashboardStats: state => state.dashboard_stats,
  selectDashboardStatsItem: state => state.dashboard_stats.item,

  selectDashboardStatsAreStale: createSelector(
    'selectDashboardStats',
    'selectAppTime',
    (stats, appTime) => {
      if (!stats.loadedAt) {
        return true;
      }
      return appTime - stats.loadedAt > STALE_AFTER;
    },
  ),
};
