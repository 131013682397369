import { Model, BelongsTo, Attr } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class FacilitySync extends ApplicationRecord {
  static jsonapiType = 'facility_syncs';

  @Attr() state: string;
  @Attr() updatedAt: string;
  @Attr() createdAt: string;
  @BelongsTo() facility: Facility;

  isComplete() {
    return this.state === 'completed';
  }

  isPending() {
    return this.state === 'started' || this.state === 'pending';
  }

  isFailed() {
    return this.state === 'failed';
  }
}
