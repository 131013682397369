import { Attr, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';

@Model()
export default class InstanceType extends ApplicationRecord {
  static jsonapiType = 'instance_types';
  @Attr() identifier: string;
  @Attr() clockSpeed: number;
  @Attr() vcpus: number;
  @Attr() memorySize: number;
  @Attr() gpuCount: number;
  @Attr() gpuName: string;
  @Attr() gpuManufacturer: string;
  @Attr() instanceStorageSize: number;
  @Attr() totalGpuMemory: number;
  @Attr() maximumBandwidthInMbps: number;
  @Attr() maximumIops: number;
  @Attr() maximumThroughputInMBps: number;
  @Attr() baselineBandwidthInMbps: number;
  @Attr() baselineIops: number;
  @Attr() baselineThroughputInMBps: number;
  @Attr({ persist: false }) selected: boolean;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
