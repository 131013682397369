import { AppBar, Toolbar, IconButton, Button, useTheme } from '@mui/material';
import { connect } from 'redux-bundler-react';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AuthorizationCheck from '../../atoms/AuthorizationCheck';
import ClientPicker from '../ClientPicker';
import UserMenuContainer from '../UserMenu/UserMenuContainer';
import logo from './arch.svg';
import styles from './styles';
import useResizeObserver from './useResizeObserver';

function MainToolbar({ classes, menu, onShowSearch, activeOrNextClient }) {
  const theme = useTheme();
  const resizeObserver = useResizeObserver();
  const Menu = menu || UserMenuContainer;
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [right, setRight] = React.useState(0);

  React.useLayoutEffect(() => {
    if (resizeObserver) {
      setRight(resizeObserver.width + 55);
    }
  }, [resizeObserver]);

  return (
    <AppBar
      data-test="main-toolbar"
      position="absolute"
      color="primary"
      classes={{ colorPrimary: classes.appBarColor }}
      className={classes.appBarShift}
    >
      <Toolbar className={classes.toolbar}>
        <a
          href="/"
          className={classes.logoWrapper}
          data-test="main-toolbar-logo"
        >
          <img src={logo} className={classes.logo} alt="Mane" />
        </a>

        <div className={classes.spacer} />

        <AuthorizationCheck permissionNames={['search.create']}>
          {mobile ? (
            <>
              {right > 0 && (
                <IconButton
                  onClick={onShowSearch}
                  className={classes.searchIcon}
                  style={{ right: right }}
                >
                  <SearchIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {right > 0 && (
                <Button
                  disableFocusRipple
                  style={{
                    right: right,
                    backgroundColor: '#fafafa',
                    color: '#000',
                  }}
                  onClick={onShowSearch}
                  className={classes.search}
                >
                  <SearchIcon />
                  <span className={classes.searchText}>Search...</span>
                  <kbd className={classes.key}>^k</kbd>
                </Button>
              )}
            </>
          )}
        </AuthorizationCheck>
        <div ref={resizeObserver.ref}>
          <ClientPicker />
        </div>

        <Menu />
      </Toolbar>
    </AppBar>
  );
}

MainToolbar.propTypes = {
  menu: PropTypes.node,
};

MainToolbar.defaultProps = {
  menu: null,
};

export default connect(
  'selectActiveOrNextClient',
  withStyles(MainToolbar, styles, { withTheme: true }),
);
