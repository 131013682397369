import {
  Collapse,
  darken,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { uuidToId } from 'notion-utils';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { withStyles } from 'tss-react/mui';
import { sortBy, reverse } from 'lodash';

const styles = (theme, _params) => ({
  navbarItem: {
    color: theme.palette.text.primary,
  },
  itemButton: {
    paddingLeft:
      _params.level > 1 ? theme.spacing(3 * _params.level) : theme.spacing(4),
    whiteSpace: 'nowrap',
    '& span': {
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: theme.palette.text.primary,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
  },
  navbarItemContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  expandIconContainer: {
    borderRadius: theme.spacing(1),
    '&: hover': {
      backgroundColor: darken(theme.palette.background.paper, 0.1),
    },
  },
});

const DocumentationSection = connect(
  'selectRouteParams',
  withStyles(({ item, level, routeParams, classes, onExpanded }) => {
    if (routeParams?.docId) {
      if (uuidToId(routeParams.docId) === uuidToId(item.id)) {
        onExpanded && onExpanded();
      }
    }
    const [showSubSections, setShowSubsections] = React.useState(false);
    const { children } = item;
    const hasChildren = children.length > 0;

    return (
      <>
        <div className={classes.navbarItemContainer}>
          <ListItem
            disablePadding
            className={classes.navbarItem}
            component="a"
            href={`/docs/${uuidToId(item.id)}`}
            onClick={() => setShowSubsections(true)}
          >
            <ListItemButton
              className={classes.itemButton}
              selected={
                routeParams?.docId
                  ? uuidToId(routeParams.docId) === uuidToId(item.id)
                  : false
              }
            >
              <ListItemText primary={item.title} />
              {hasChildren && (
                <div
                  className={classes.expandIconContainer}
                  onClick={e => {
                    setShowSubsections(!showSubSections);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {showSubSections ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              )}
            </ListItemButton>
          </ListItem>
        </div>
        {hasChildren && (
          <Collapse in={showSubSections}>
            {children.map(child => {
              return (
                <DocumentationSection
                  key={child.id}
                  item={child}
                  level={level + 2}
                  onExpanded={() => {
                    setShowSubsections(true);
                    onExpanded && onExpanded();
                  }}
                />
              );
            })}
          </Collapse>
        )}
      </>
    );
  }, styles),
);

export default DocumentationSection;
