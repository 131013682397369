import { BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import Image from './image';
import Template from './template';
import Workstation from './workstation';
import Group from './group';

@Model()
export default class WorkstationCreationGroup extends ApplicationRecord {
  static jsonapiType = 'workstation_creation_groups';

  @BelongsTo() group: Group;
  @BelongsTo() template: Template;
  @BelongsTo() facility: Facility;
  @BelongsTo() overrideImage: Image;

  @HasMany() workstations: Workstation[];
}
