/* eslint-disable no-unused-expressions */

import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import React from 'react';
import { Menu, MenuItem, useTheme } from '@mui/material';

const NestedMenuItem = ({
  label,
  mainMenuOpen,
  expandIcon,
  highlightColor,
  left,
  ...rest
}) => {
  //label:  The MenuItem text content
  //mainMenuOpen: The same variable assigned to main menu 'open' prop
  //expandIcon:  usually left or right arrow icon
  //highlightColor: highlight background color when item is focused
  //left:  expand nested menu to the left?
  //MenuItemProps:  e.g. { dense }
  const theme = useTheme();
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);

  const expandIconSize = rest.MenuItemProps?.dense ? 'small' : 'default';
  expandIcon = expandIcon ? (
    expandIcon
  ) : left ? (
    <ArrowLeft fontSize={expandIconSize} />
  ) : (
    <ArrowRight fontSize={expandIconSize} />
  );
  highlightColor = highlightColor ? highlightColor : theme.palette.action.hover; // To be changed with the teme.palette.background something color
  const subMenuRef = React.useRef(null);
  const nestedMenuRef = React.useRef(null);
  const isSubmenuFocused = () => {
    const active = nestedMenuRef.current?.ownerDocument?.activeElement;
    for (const child of subMenuRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleMouseEnter = e => {
    e.stopPropagation();
    setSubMenuOpen(true);
    nestedMenuRef.current.style.backgroundColor = highlightColor;
  };
  const handleMouseLeave = e => {
    setSubMenuOpen(false);
    nestedMenuRef.current.style.backgroundColor = 'transparent';
  };

  const handleClick = e => {
    e.stopPropagation();
    // this.setState({ subMenuOpen: this.state.subMenuOpen ? false : true });
    setSubMenuOpen(true);
  };

  const handleFocus = evt => {
    if (evt.target === nestedMenuRef.current) {
      setSubMenuOpen(true);
      nestedMenuRef.current.style.backgroundColor = highlightColor;
    }
  };

  const handleKeyDown = evt => {
    const arrowRight = left ? 'ArrowLeft' : 'ArrowRight';
    const arrowLeft = left ? 'ArrowRight' : 'ArrowLeft';
    const length = subMenuRef.current?.children.length;
    if (length && length > 0) {
      // When keyboard nav goes out of bounds, wrap around the current menu
      // and prevent parent menu from receiving the key input
      if (
        evt.target === subMenuRef.current?.children[length - 1] &&
        evt.key === 'ArrowDown'
      ) {
        evt.stopPropagation();
        subMenuRef.current?.children[0]?.focus();
      } else if (
        evt.target === subMenuRef.current?.children[0] &&
        evt.key === 'ArrowUp'
      ) {
        evt.stopPropagation();
        subMenuRef.current?.children[length - 1]?.focus();
      } else if (isSubmenuFocused()) {
        evt.stopPropagation();
      }
    }
    // Handle arrow key directions behaviour
    if (evt.key === arrowRight && !isSubmenuFocused()) {
      if (!subMenuOpen) {
        setSubMenuOpen(true);
      }
      subMenuRef.current?.children[0]?.focus();
      evt.stopPropagation();
    } else if (
      (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') &&
      evt.target === nestedMenuRef.current
    ) {
      setSubMenuOpen(false);
      nestedMenuRef.current.style.backgroundColor = 'white';
    } else if (evt.key === arrowLeft) {
      nestedMenuRef.current?.focus();
      setSubMenuOpen(false);
    }
  };
  return (
    <MenuItem
      ref={nestedMenuRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onFocus={handleFocus}
      // Root element must have a `tabIndex` attribute for keyboard navigation
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      style={{ outline: 'none', overflow: 'hidden' }}
      {...rest.MenuItemProps}
    >
      {label}
      {expandIcon}
      <Menu
        // set to pointerEvents to none to prevent menu from capturing
        // events meant for child elements
        style={{ pointerEvents: 'none', overflow: 'none' }}
        TransitionProps={{
          onMouseLeave: evt => {},
        }}
        anchorEl={nestedMenuRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: left ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: left ? 'left' : 'right',
        }}
        open={subMenuOpen && mainMenuOpen}
        onClose={() => {
          setSubMenuOpen(false);
        }}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <div ref={subMenuRef} style={{ pointerEvents: 'auto' }}>
          {rest.children} {/* Change this and we're good to */}
        </div>
      </Menu>
    </MenuItem>
  );
};

export default NestedMenuItem;
