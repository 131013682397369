import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftChildAndTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  title: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
});

function DropdownHeader({ classes, title, leftChild, rightChild }) {
  return (
    <div className={classes.root}>
      <div className={classes.leftChildAndTitleWrapper}>
        {leftChild}
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.title}
        >
          {title}
        </Typography>
      </div>
      {rightChild}
    </div>
  );
}

DropdownHeader.propTypes = {
  title: PropTypes.string.isRequired,
  leftChild: PropTypes.node,
  rightChild: PropTypes.node,
};

DropdownHeader.defaultProps = {
  leftChild: null,
  rightChild: null,
};

export default withStyles(DropdownHeader, styles, { withTheme: true });
