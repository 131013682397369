import { createSelector } from 'redux-bundler';
import { snakeCase } from 'lodash';

import FileUpload from '../models/file_upload';
import FileUploadFile from '../models/file_upload_file';

const STALE_AFTER = 30000;

export default {
  name: 'fileUploads',
  getReducer: () => {
    const initialData = {
      data: null,
      sortDirection: 'ASC',
      sortBy: 'name',
      loading: false,
      activeFileUpload: null,
      loadingActiveFileUpload: null,
      scope: 'client',
      fetchLastSuccess: null,
    };

    return (state = initialData, { type, payload }) => {
      if (type === 'FETCH_FILE_UPLOAD_START') {
        return { ...state, loadingActiveFileUpload: true };
      }
      if (type === 'FETCH_FILE_UPLOAD_SUCCESS') {
        return {
          ...state,
          loadingActiveFileUpload: false,
          activeFileUpload: payload.result,
        };
      }
      if (type === 'FETCH_FILE_UPLOADS_START') {
        return { ...state, loading: true };
      }
      if (type === 'FETCH_FILE_UPLOADS_SUCCESS') {
        return {
          ...state,
          loading: false,
          data: payload.results,
          scope: payload.scope,
          fetchLastSuccess: payload.time,
        };
      }
      if (type === 'CHANGE_FILE_UPLOAD_SORT') {
        return {
          ...state,
          sortDirection: payload.sortDirection,
          sortBy: payload.sortBy,
        };
      }
      if (type === 'ADD_FILE_UPLOAD' && state.data) {
        return { ...state, data: [...state.data, payload.fileUpload] };
      }
      if (type === 'UPDATE_FILE_UPLOAD') {
        if (state.data) {
          const index = state.data.findIndex(
            u => u.id === payload.fileUpload.id,
          );
          const { fileUpload } = payload;
          return {
            ...state,
            data: state.data
              .slice(0, index)
              .concat([fileUpload])
              .concat(state.data.slice(index + 1)),
            activeFileUpload:
              state.activeFileUpload.id === payload.fileUpload.id
                ? fileUpload
                : state.activeFileUpload,
          };
        }

        return {
          ...state,
          activeFileUpload: payload.fileUpload,
        };
      }
      if (type === 'TOGGLE_FILE_UPLOAD_SELECTION') {
        const index = state.data.findIndex(u => u.id === payload.fileUpload.id);
        const { fileUpload } = payload;
        fileUpload.selected = !fileUpload.selected;
        fileUpload.reset();
        return {
          ...state,
          data: state.data
            .slice(0, index)
            .concat([fileUpload])
            .concat(state.data.slice(index + 1)),
        };
      }
      if (type === 'REMOVE_FILE_UPLOAD') {
        const index = state.data.findIndex(u => u.id === payload.fileUpload.id);
        return {
          ...state,
          data: state.data.slice(0, index).concat(state.data.slice(index + 1)),
        };
      }

      if (type === 'RESET_CLIENT') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      if (type === 'RESET_ACTIVE_FILE_UPLOAD') {
        return {
          ...state,
          activeFileUpload: null,
        };
      }

      if (type === 'RESET_FILE_UPLOADS') {
        return {
          ...state,
          data: null,
          scope: null,
        };
      }

      if (type === 'RESET_ACTIVE_FACILITY') {
        return {
          ...state,
          data: null,
        };
      }

      return state;
    };
  },

  doResetFileUploads: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_FILE_UPLOADS' });
  },

  doResetActiveFileUpload: () => ({ dispatch }) => {
    dispatch({ type: 'RESET_ACTIVE_FILE_UPLOAD' });
  },

  doFetchFileUploads: () => async ({ dispatch, getState, store }) => {
    const state = getState();
    const { sortBy } = state.fileUploads;
    const { sortDirection } = state.fileUploads;

    const client = store.selectActiveClient(state);
    const facility = store.selectActiveFacility(state);

    dispatch({ type: 'FETCH_FILE_UPLOADS_START' });
    const response = await FileUpload.where({ facility_id: facility.id })
      .includes([
        'facility',
        'performed_by',
        'files',
        'file_upload_destination',
      ])
      .order({ createdAt: 'desc' })
      .all();
    dispatch({
      type: 'FETCH_FILE_UPLOADS_SUCCESS',
      payload: { results: response.data, scope: 'client', time: Date.now() },
    });
  },

  doFetchActiveFileUpload: fileUploadId => async ({ dispatch }) => {
    dispatch({ type: 'FETCH_FILEUPLOAD_START' });

    const response = await FileUpload.includes([]).find(fileUploadId);
    dispatch({
      type: 'FETCH_FILE_UPLOAD_SUCCESS',
      payload: { result: response.data },
    });
  },

  doChangeFileUploadSort: ({ sortDirection, sortBy }) => async ({
    dispatch,
    store,
  }) => {
    dispatch({
      type: 'CHANGE_FILE_UPLOAD_SORT',
      payload: { sortDirection, sortBy },
    });
    store.doFetchFileUploads();
  },

  selectFileUploadSortBy: state => state.fileUploads.sortBy,
  selectFileUploadSortDirection: state => state.fileUploads.sortDirection,
  selectFileUploadLoading: state => state.fileUploads.loading,
  selectFileUploadRaw: state => state.fileUploads,
  selectFileUploadData: state => state.fileUploads.data || [],

  selectFileUploadFetchLastSuccess: createSelector(
    'selectFileUploadRaw',
    fileUploadData => fileUploadData.fetchLastSuccess,
  ),
  selectFileUploadsAreStale: createSelector(
    'selectFileUploadFetchLastSuccess',
    'selectAppTime',
    (lastSuccessTime, appTime) => {
      if (!lastSuccessTime) {
        return true;
      }
      return appTime - lastSuccessTime > STALE_AFTER;
    },
  ),
  selectSelectedFileUploadCount: createSelector(
    'selectFileUploadData',
    teamData => teamData.filter(u => u.selected).length,
  ),
  selectSelectedFileUploads: createSelector('selectFileUploadData', teamData =>
    teamData.filter(u => u.selected),
  ),

  selectActiveFileUploadId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.fileUploadId,
  ),
  selectActiveFileUpload: createSelector(
    'selectFileUploadRaw',
    teamData => teamData.activeFileUpload,
  ),
  reactShouldFetchFileUploads: createSelector(
    'selectRouteApis',
    'selectFileUploadRaw',
    'selectCurrentUser',
    'selectActiveClient',
    'selectActiveFacility',
    'selectFileUploadsAreStale',
    (
      apis,
      fileUploadData,
      currentUser,
      activeClient,
      activeFacility,
      isStale,
    ) => {
      const wantsFileUploads = apis.includes('fileUploads');

      if (
        !wantsFileUploads ||
        fileUploadData.loading ||
        !currentUser ||
        !activeClient ||
        !activeFacility
      ) {
        return false;
      }

      if (fileUploadData.data && !isStale) {
        return false;
      }
      return { actionCreator: 'doFetchFileUploads' };
    },
  ),

  // reactShouldFetchFileUploadsForFacilities: createSelector(
  //   'selectRouteApis',
  //   'selectFileUploadRaw',
  //   'selectCurrentUser',
  //   'selectActiveFacility',
  //   (apis, fileUploadData, currentUser, activeFacility) => {
  //     const wantsFileUploads = apis.includes('fileUploads_for_facility');

  //     if (
  //       !wantsFileUploads ||
  //       fileUploadData.loading ||
  //       fileUploadData.data ||
  //       !currentUser ||
  //       !activeFacility
  //     ) {
  //       return false;
  //     }
  //     return { actionCreator: 'doFetchFileUploadsForFacility' };
  //   },
  // ),

  // reactShouldFetchActiveFileUpload: createSelector(
  //   'selectRouteParams',
  //   'selectPathname',
  //   'selectActiveFileUpload',
  //   'selectFileUploadRaw',
  //   (routeParams, pathname, activeFileUpload, teamData) => {
  //     if (
  //       !pathname.includes('/fileUploads') ||
  //       !routeParams.fileUploadId ||
  //       teamData.loadingActiveFileUpload
  //     ) {
  //       return null;
  //     }
  //     if (
  //       activeFileUpload &&
  //       activeFileUpload.id === routeParams.fileUploadId
  //     ) {
  //       return null;
  //     }

  //     return {
  //       actionCreator: 'doFetchActiveFileUpload',
  //       args: [routeParams.fileUploadId],
  //     };
  //   },
  // ),

  // reactScopeChange: createSelector(
  //   'selectRouteApis',
  //   'selectFileUploadRaw',
  //   (apis, fileUploadData) => {
  //     let scope;
  //     if (apis.includes('fileUploads')) scope = 'client';

  //     if (apis.includes('fileUploads_for_facility')) scope = 'facility';
  //     if (fileUploadData.scope && fileUploadData.scope !== scope) {
  //       return { actionCreator: 'doResetFileUploads' };
  //     }

  //     return null;
  //   },
  // ),

  // reactShouldResetActiveFileUpload: createSelector(
  //   'selectActiveFileUploadId',
  //   'selectActiveFileUpload',
  //   (activeFileUploadId, activeFileUpload) => {
  //     if (!activeFileUpload) return null;
  //     if (activeFileUpload.id !== activeFileUploadId) {
  //       return { actionCreator: 'doResetActiveFileUpload' };
  //     }

  //     return null;
  //   },
  // ),
};
