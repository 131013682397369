import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AppsIcon from '@mui/icons-material/Apps';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CakeIcon from '@mui/icons-material/Cake';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import CategoryIcon from '@mui/icons-material/Category';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HomeIcon from '@mui/icons-material/Home';
import LayersIcon from '@mui/icons-material/Layers';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PublishIcon from '@mui/icons-material/Publish';
import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';

import AuthorizationCheck from '../../atoms/AuthorizationCheck';

const styles = theme => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  facilityDetails: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

const AppMenu = ({
  activeClient,
  activeOrNextFacility,
  authenticatedUser,
  route,
  baseUrl,
  classes,
}) => {
  const isRouteActive = key => {
    const currentPage = route.drawItem;
    return currentPage === key;
  };

  return (
    <div>
      {activeClient && (
        <List>
          <ListItem
            selected={isRouteActive('home')}
            button
            component="a"
            href={`/clients/${activeClient.id}`}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          {!authenticatedUser.isAuthorized('ui.advanced.mode') && (
            <AuthorizationCheck permissionName="ui.view.documentation">
              <ListItem
                button
                component="a"
                href={`/docs`}
                selected={isRouteActive('docs')}
              >
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Docs" />
              </ListItem>
            </AuthorizationCheck>
          )}
        </List>
      )}

      <AuthorizationCheck permissionName="ui.advanced.mode">
        <Collapse in={Boolean(activeOrNextFacility)}>
          {activeOrNextFacility && (
            <>
              <Divider />
              <List>
                <ListItem
                  selected={isRouteActive('dashboard')}
                  button
                  component="a"
                  href={baseUrl}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  selected={isRouteActive('workstations')}
                  button
                  component="a"
                  href={`${baseUrl}/workstations`}
                >
                  <ListItemIcon>
                    <DesktopWindowsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Workstations" />
                </ListItem>

                <ListItem
                  selected={isRouteActive('compute')}
                  button
                  component="a"
                  href={`${baseUrl}/compute`}
                >
                  <ListItemIcon>
                    <AppsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={activeClient?.computeName || 'Compute'}
                  />
                </ListItem>

                <ListItem
                  selected={isRouteActive('groups')}
                  button
                  component="a"
                  href={`${baseUrl}/groups`}
                >
                  <ListItemIcon>
                    <GroupWorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Groups" />
                </ListItem>

                <ListItem
                  selected={isRouteActive('storage')}
                  button
                  component="a"
                  href={`${baseUrl}/storage`}
                >
                  <ListItemIcon>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary="Storage" />
                </ListItem>

                <AuthorizationCheck permissionName="file.upload.create">
                  <ListItem
                    selected={isRouteActive('file_uploads')}
                    button
                    component="a"
                    href={`${baseUrl}/file_uploads`}
                  >
                    <ListItemIcon>
                      <PublishIcon />
                    </ListItemIcon>
                    <ListItemText primary="Uploads" />
                  </ListItem>
                </AuthorizationCheck>
              </List>

              <AuthorizationCheck
                permissionNames={[
                  'ui.images.view',
                  'ui.image.pipelines.view',
                  'ui.chef.packages.view',
                ]}
              >
                <Divider />
              </AuthorizationCheck>

              <List>
                <AuthorizationCheck permissionName="ui.templates.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/templates`}
                    selected={isRouteActive('templates')}
                  >
                    <ListItemIcon>
                      <CategoryIcon />{' '}
                    </ListItemIcon>
                    <ListItemText primary="Templates" />
                  </ListItem>
                </AuthorizationCheck>

                <AuthorizationCheck permissionName="ui.images.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/images`}
                    selected={isRouteActive('images')}
                  >
                    <ListItemIcon>
                      <LayersIcon />
                    </ListItemIcon>
                    <ListItemText primary="Images" />
                  </ListItem>
                </AuthorizationCheck>

                <AuthorizationCheck permissionName="ui.image.pipelines.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/image_pipelines`}
                    selected={isRouteActive('image_pipelines')}
                  >
                    <ListItemIcon>
                      <CakeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Image Pipelines" />
                  </ListItem>
                </AuthorizationCheck>

                <AuthorizationCheck permissionName="ui.chef.packages.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/chef_packages`}
                    selected={isRouteActive('chef_packages')}
                  >
                    <ListItemIcon>
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Scripts" />
                  </ListItem>
                </AuthorizationCheck>

                <AuthorizationCheck permissionName="ui.licenses.view">
                  <ListItem
                    button
                    component="a"
                    href={`${baseUrl}/licenses`}
                    selected={isRouteActive('licenses')}
                  >
                    <ListItemIcon>
                      <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText primary="Licenses" />
                  </ListItem>
                </AuthorizationCheck>
              </List>
            </>
          )}
        </Collapse>

        <AuthorizationCheck permissionNames={['ui.users.view']}>
          <Divider />
        </AuthorizationCheck>

        <AuthorizationCheck permissionName="ui.users.view">
          <List>
            <AuthorizationCheck permissionName="ui.users.view">
              <ListItem
                button
                component="a"
                href={`${baseUrl}/users`}
                selected={isRouteActive('users')}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            </AuthorizationCheck>

            <AuthorizationCheck permissionName="ui.users.view">
              <ListItem
                button
                component="a"
                href={`${baseUrl}/teams`}
                selected={isRouteActive('teams')}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Teams" />
              </ListItem>
            </AuthorizationCheck>

            <ListItem
              button
              component="a"
              href={`${baseUrl}/accounts`}
              selected={isRouteActive('accounts')}
            >
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary="Accounts" />
            </ListItem>
          </List>
        </AuthorizationCheck>

        <AuthorizationCheck permissionNames={['ui.clients.view']}>
          <Divider />
        </AuthorizationCheck>

        <List>
          <AuthorizationCheck permissionName="ui.clients.view">
            <ListItem
              button
              component="a"
              href="/clients"
              selected={isRouteActive('clients')}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Organizations" />
            </ListItem>
          </AuthorizationCheck>

          <AuthorizationCheck permissionName="ui.roles.view">
            <ListItem
              button
              component="a"
              href={`${baseUrl}/roles`}
              selected={isRouteActive('roles')}
            >
              <ListItemIcon>
                <AssignmentTurnedInIcon />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItem>
          </AuthorizationCheck>

          <AuthorizationCheck permissionName="ui.admin.view">
            <ListItem
              button
              component="a"
              href={`/admin`}
              selected={isRouteActive('admin')}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin" />
            </ListItem>
          </AuthorizationCheck>
          <AuthorizationCheck permissionName="ui.view.documentation">
            <ListItem
              button
              component="a"
              href={`/docs`}
              selected={isRouteActive('docs')}
            >
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="Docs" />
            </ListItem>
          </AuthorizationCheck>
        </List>
      </AuthorizationCheck>
    </div>
  );
};

export default connect(
  'selectActiveOrNextFacility',
  'selectRoute',
  'selectBaseUrl',
  'selectActiveClient',
  'selectAuthenticatedUser',
  withStyles(AppMenu, styles),
);
