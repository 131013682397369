import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Client from './client';

@Model()
export default class OtpCode extends ApplicationRecord {
  static jsonapiType = 'otp_codes';

  @Attr() otpCode: string;
  @Attr() expiresAt: string;
}
