import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import ClientUserAssignment from './client_user_assignment';
import User from './user';

@Model()
export default class LdapUsernameChange extends ApplicationRecord {
  static jsonapiType = 'ldap_username_changes';
  @Attr() username: string;
  @BelongsTo() user: User;
  @BelongsTo() clientUserAssignment: ClientUserAssignment;
}
