import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import App from './app';
import Client from './client';
import Facility from './facility';
import Workstation from './workstation';

@Model()
export default class License extends ApplicationRecord {
  static jsonapiType = 'licenses';
  @Attr() vendor: string;
  @Attr() feature: string;
  @Attr() featureCount: number;
  @Attr() featureUsage: number;
  @Attr() featureVersion: string;
  @Attr() expiry: string;

  @Attr() users: string[];
  @Attr({ persist: false }) userAvatars: object[];

  @BelongsTo() facility: Facility;
  @BelongsTo() client: Client;
  @BelongsTo() app: App;

  isInUseByWorkstation(workstation: Workstation) {
    if (!workstation) {
      return false;
    }
    return this.workstationNames().find(
      name =>
        name === workstation.name.toLowerCase().replace(/_/, '') ||
        name === workstation.name.toLowerCase(),
    );
  }

  workstationNames(): string[] {
    if (!this.users) {
      return [];
    }
    return this.users.map(u => u.split('@')[1].split('.')[0]);
  }
}
