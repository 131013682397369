import { Link, Typography } from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import React from 'react';

import AppMenu from './AppMenu';
import AuthorizationCheck from '../../atoms/AuthorizationCheck';
import DocumentationMenu from './Documentation/DocumentationMenu';
import ClearCache from './Documentation/ClearCache';

const styles = theme => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  fill: {
    flexGrow: '1',
  },

  link: {
    flexGrow: '0',
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    marginBottom: theme.spacing(2),
  },

  links: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

// eslint-disable-next-line
const ArchDrawerMenu = ({ documentationSummary, classes }) => {
  return (
    <div className={classes.flex}>
      {/* <AuthorizationCheck permissionName="ui.help.view"> */}

      {documentationSummary ? <DocumentationMenu /> : <AppMenu />}

      <div className={classes.fill} />

      <div className={classes.links}>
        <Typography
          component={Link}
          href="https://archpt.io/privacy-policy"
          color="secondary"
          target="_blank"
          variant="body2"
          className={classes.link}
        >
          Privacy policy
        </Typography>
        <ClearCache />
      </div>

      {/* </AuthorizationCheck> */}
    </div>
  );
};

export default connect(
  'selectDocumentationSummary',
  withStyles(ArchDrawerMenu, styles),
);
