import axios from 'axios';
import { uuidToId } from 'notion-utils';
import { createSelector } from 'redux-bundler';

import { useMediaQuery } from '@mui/material';

const cloudflareEndpoint =
  process.env.REACT_APP_CLOUDFLARE_DOCUMENTATION_ENDPOINT;

export default {
  name: 'documentation_pages',
  getReducer: () => {
    const initialData = {
      content: null,
      rendered: null,
      summary: null,
      currentId: null,
      menuReady: false,
      loading: false,
      routeHistory: [],
    };
    return (state = initialData, { type, payload }) => {
      if (type === 'SET_THEME_SETTING') {
        return {
          ...state,
          content: null,

          currentId: payload.currentId,
          rendered: null,
        };
      }
      if (type === 'FETCH_SUMMARY') {
        return {
          ...state,
          summary: payload.summary,
        };
      }
      if (type === 'UPDATE_DOCUMENTATION_CONTENT') {
        return {
          ...state,
          content: payload.content,
          rendered: payload.rendered,
          currentId: payload.currentId,
          loading: payload.loading,
        };
      }

      if (type === 'CLEAR_DOCUMENTATION') {
        return {
          ...state,
          content: null,
          rednered: null,
          summary: null,
        };
      }
      if (type === 'MENU_STATUS') {
        return {
          ...state,
          menuReady: payload.menuReady,
        };
      }
      if (type === 'LOG_ROUTE') {
        const { route } = payload;
        const { routeHistory } = state;

        const nextRouteHistory = [route, ...routeHistory];
        // limit to 5 items
        if (nextRouteHistory.length > 5) {
          nextRouteHistory.pop();
        }

        sessionStorage.setItem(
          'routeHistory',
          JSON.stringify(nextRouteHistory),
        );

        return {
          ...state,
          routeHistory: nextRouteHistory,
        };
      }
      return state;
    };
  },

  doUpdateDocumentationContent: () => async ({ dispatch, store, getState }) => {
    const state = getState();
    const docId = store.selectDocId(state);
    const themeSetting = store.selectThemeSetting();

    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

    const useDarkMode = () => {
      if (themeSetting === 'system') {
        return prefersDarkMode;
      } else {
        return themeSetting == 'dark';
      }
    };

    dispatch({
      type: 'UPDATE_DOCUMENTATION_CONTENT',
      payload: {
        content: null,
        currentId: docId,
        loading: true,
      },
    });

    const url = docId
      ? `${cloudflareEndpoint}/pages/?id=${docId}&dark=${useDarkMode()}`
      : `${cloudflareEndpoint}/pages/?dark=${useDarkMode()}`;
    const pageContent = await axios.get(url);

    dispatch({
      type: 'UPDATE_DOCUMENTATION_CONTENT',
      payload: {
        content: pageContent.data.content,
        rendered: pageContent.data.rendered,
        currentId: docId,
        loading: false,
      },
    });
  },

  doFetchDocumentationSummary: () => async ({ dispatch, store, getState }) => {
    const summary = await axios.get(`${cloudflareEndpoint}/summary`);
    dispatch({
      type: 'FETCH_SUMMARY',
      payload: { summary: summary.data },
    });
  },

  doLogRoute: route => ({ dispatch }) => {
    dispatch({ type: 'LOG_ROUTE', payload: { route } });
  },

  doResetDocumentationContent: () => ({ dispatch }) => {
    dispatch({ type: 'CLEAR_DOCUMENTATION' });
  },

  doUpdateMenuStatus: menuReady => ({ dispatch, store, getState }) => {
    dispatch({
      type: 'MENU_STATUS',
      payload: {
        menuReady,
      },
    });
  },

  selectDocumentationRendered: state => state.documentation_pages.rendered,
  selectDocumentationContent: state => state.documentation_pages.content,
  selectDocumentationContentLoading: state => state.documentation_pages.loading,
  selectDocumentationCurrentId: state => state.documentation_pages.currentId,

  selectDocumentationSummary: state => state.documentation_pages.summary,

  selectDocumentationMenuReady: state => state.documentation_pages.menuReady,
  selectPageHistory: state => state.documentation_pages.routeHistory,
  selectDocId: createSelector(
    'selectRouteParams',
    routeParams => routeParams.docId,
  ),

  reactLogRoute: createSelector(
    'selectRouteInfo',
    'selectPageHistory',
    (route, routeHistory) => {
      //detect last logged route
      const lastLoggedRoute = sessionStorage.getItem('routeHistory')
        ? JSON.parse(sessionStorage.getItem('routeHistory'))[0]
        : null;

      if (lastLoggedRoute && lastLoggedRoute === route.url) {
        return;
      }

      // don't log if the route starts with /docs
      if (route.url.startsWith('/docs')) {
        return;
      }

      return {
        actionCreator: 'doLogRoute',
        args: [route.url],
      };
    },
  ),

  reactShouldFetchSummary: createSelector(
    'selectRouteApis',
    'selectDocumentationSummary',
    (apis, documentationSummary) => {
      const wantsDoc = apis.includes('docs');

      if (!wantsDoc) {
        return;
      }

      if (documentationSummary) {
        return;
      }
      return {
        actionCreator: 'doFetchDocumentationSummary',
      };
    },
  ),

  reactShouldFetchDocumentation: createSelector(
    'selectRouteApis',
    'selectDocumentationCurrentId',
    'selectPathname',
    'selectDocumentationContent',
    'selectDocId',

    'selectDocumentationContentLoading',
    (
      apis,
      documentationCurrentId,
      pathName,
      documentationContent,
      docId,
      loading,
    ) => {
      const wantsDoc = apis.includes('docs');
      if (!wantsDoc) {
        return;
      }

      if (docId && documentationCurrentId === docId) {
        return;
      }

      if (loading) {
        return;
      }

      if (
        !docId &&
        pathName === '/docs' &&
        documentationContent &&
        !documentationCurrentId
      ) {
        return;
      }

      return { actionCreator: 'doUpdateDocumentationContent' };
    },
  ),

  reactShouldResetDocumentation: createSelector(
    'selectRouteApis',
    'selectDocumentationContent',
    (apis, documentationContent) => {
      const wantsDoc = apis.includes('docs');

      if (wantsDoc) {
        return;
      }

      if (!documentationContent) {
        return;
      }

      return {
        actionCreator: 'doResetDocumentationContent',
      };
    },
  ),
};
