import { Attr, HasMany, HasOne, Model, BelongsTo } from 'spraypaint';
import moment from 'moment';

import { ApplicationRecord } from './application_record';
import Client from './client';

@Model()
export default class GithubAppInstallation extends ApplicationRecord {
  static jsonapiType = 'github_app_installations';
  // @Attr() name: string;
  @Attr() installationId: string;
  @Attr() login: string;
  @Attr() settingsUrl: string;
  @Attr() createdAt: string;
  @Attr() updatedAt: string;
  @Attr() uninstalledAt: string;
  @Attr() suspendedAt: string;
  @Attr({ persist: false }) repositories: string[];

  @Attr() githubTemporaryCode: string;

  @BelongsTo() client: Client;

  displayName() {
    const name = this.login || this.id;
    if (this.uninstalledAt) {
      return `${name} (uninstalled)`;
    }

    if (this.suspendedAt) {
      return `${name} (suspended)`;
    }

    return name;
  }
}
