import { IconButton, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';
import React from 'react';

import CopyableText from '../../../atoms/CopyableText';

const styles = theme => ({
  icon: {
    color: theme.palette.action.disabled,
    cursor: 'pointer',
    // verticalAlign: 'middle',

    '&:hover': {
      color: theme.palette.action.active,
    },
  },
  text: {
    display: 'inline-flex',
    alignItems: 'center',

    cursor: 'pointer',
  },
  link: {
    marginLeft: 10,
    display: 'block',
    height: 15,
  },
});

const CopyButton = ({ classes, className, textToCopy, refToCopy }) => {
  const [clipboardSuccess, setClipboardSuccess] = React.useState(false);

  const handleClose = () => {
    setClipboardSuccess(false);
  };

  const handleSuccess = () => {
    setClipboardSuccess(true);
  };

  return (
    <Tooltip open={clipboardSuccess} onClose={handleClose} title="Copied">
      <IconButton
        className={className}
        onClick={() => {
          window.navigator.clipboard.writeText(
            textToCopy || refToCopy?.current?.innerText,
          );
          handleSuccess();
        }}
      >
        <FileCopyIcon style={{ fontSize: 15 }} className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
};

CopyableText.propTypes = {
  textToCopy: PropTypes.string.isRequired,
  displayText: PropTypes.string.isRequired,
};

export default withStyles(CopyButton, styles);
