import { Avatar, Fade } from '@mui/material';
import { connect } from 'redux-bundler-react';
import BoringAvatar from 'boring-avatars';
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { withStyles } from 'tss-react/mui';

import { SwitchTransition } from 'react-transition-group';

import Facility from '../../../models/facility';

const style = theme => ({
  facility: {
    width: theme.spacing(6),
    height: theme.spacing(6),

    [theme.breakpoints.down('md')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    color: 'white',
    marginRight: theme.spacing(1),
  },
  avatar: {
    borderRadius: 4,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    position: 'relative',
  },

  initials: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: '600',
  },
  initialsContainer: {
    left: 0,
    top: 0,
    position: 'absolute',

    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

const FacilityAvatar = ({
  classes,
  activeClient,
  facility,
  className,
  skeleton,
  size = 48,
  square = false,
  text = true,
  showBoringAvatars,
  small,
}) => {
  const backgroundColor =
    !skeleton && facility ? facility.avatarColor() : 'grey';
  const initials = !skeleton && facility ? facility.name[0] : '';
  const name = facility?.name || 'Loading';
  const clientName = activeClient?.name || 'Loading';

  return (
    <SwitchTransition>
      <Fade key={name}>
        {showBoringAvatars ? (
          <div className={cx(classes.avatar)}>
            <BoringAvatar
              size={size}
              square={square}
              name={`${clientName}${name}`}
              variant="marble"
              colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
            />
            {text && (
              <div className={classes.initialsContainer}>
                <div className={classes.initials}>{initials}</div>
              </div>
            )}
          </div>
        ) : (
          <Avatar
            variant="rounded"
            className={cx(classes.facility, small && classes.small)}
            style={{
              backgroundColor: backgroundColor,
              color: 'white',
            }}
          >
            {initials}
          </Avatar>
        )}
      </Fade>
    </SwitchTransition>
  );
};
FacilityAvatar.propTypes = {
  activeFacility: PropTypes.instanceOf(Facility).isRequired,
};
export default connect(
  'selectActiveFacility',
  'selectActiveOrNextFacility',
  'selectActiveClient',
  'selectShowBoringAvatars',
  withStyles(FacilityAvatar, style, { withTheme: true }),
);
