import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Workstation from './workstation';

@Model()
export default class WorkstationShutdown extends ApplicationRecord {
  static jsonapiType = 'workstation_shutdowns';
  @Attr() state: string;
  @Attr() hard: boolean;

  @BelongsTo() workstation: Workstation;
}
