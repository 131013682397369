import { Model, BelongsTo } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import User from './user';

@Model()
export default class UserActivation extends ApplicationRecord {
  static jsonapiType = 'user_activations';
  @BelongsTo() user: User;
}
