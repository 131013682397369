import { Attr, BelongsTo, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';

@Model()
export default class ParsecGroupQuery extends ApplicationRecord {
  static jsonapiType = 'parsec_group_queries';
  @Attr() parsecGroups: object[];

  @BelongsTo() facility: Facility;
}
