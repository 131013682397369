export const baseUrl = (activeClient, activeFacility) => {
  if (activeClient && activeFacility) {
    return `/clients/${activeClient.id}/facilities/${activeFacility.id}`;
  }

  if (activeClient) {
    return `/clients/${activeClient.id}`;
  }

  return null;
};
