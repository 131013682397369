import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Facility from './facility';
import Client from './client';
import Workstation from './workstation';

@Model()
export default class App extends ApplicationRecord {
  static jsonapiType = 'apps';
  @Attr() name: string;
  @Attr() image: string;
}
