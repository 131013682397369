import { Model, Attr, BelongsTo } from 'spraypaint';

import { ApplicationRecord } from './application_record';
import Role from './role';
import User from './user';

@Model()
export default class RoleUserAssignment extends ApplicationRecord {
  static jsonapiType = 'role_user_assignments';
  @BelongsTo() role: Role;
  @BelongsTo() user: User;
  @Attr() roleId: 'string';

  @Attr({ persist: false }) selected: boolean;

  constructor(attrs?: Record<string, any>) {
    super(attrs);
    this.selected = false;
  }
}
