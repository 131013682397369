export default theme => ({
  appName: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: theme.spacing(1),
    textAlign: 'center',
    width: 32,
  },

  icon: {},
  nowrap: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    overflow: 'hidden',
  },

  avatar: {
    flex: '0 0 auto',
    width: theme.spacing(3) + 4,
    height: theme.spacing(3) + 4,
    marginRight: theme.spacing(1),
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
});
