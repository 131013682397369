import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { connect } from 'redux-bundler-react';
import { withStyles } from 'tss-react/mui';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

import { ArchDot } from '../../../molecules/StateIndicators/styles';
import CopyButton from './CopyButton';

const BrokerSetup = ({ classes, activeFacility, doFetchFacility }) => {
  const [showConfiguration, setShowConfiguration] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [showInstructions, setShowInstructions] = React.useState(false);
  const codeRef = React.useRef(null);
  return (
    <Card>
      <CardContent className={classes.setupSection}>
        <IconButton
          className={classes.copy}
          size="small"
          onClick={event => setMenuAnchor(event.currentTarget)}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          open={Boolean(menuAnchor)}
          anchorEl={menuAnchor}
          onClose={() => setMenuAnchor(null)}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          className={classes.menu}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              setMenuAnchor(null);
              activeFacility.brokerSetupComplete = true;

              const success = await activeFacility.save();
              if (success) {
                doFetchFacility(activeFacility.id);
              }
            }}
            disabled={activeFacility.brokerSetupComplete}
          >
            Mark as complete
          </MenuItem>

          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              setShowInstructions(!showInstructions);
              setMenuAnchor(null);
            }}
            disabled={!activeFacility.brokerSetupComplete}
          >
            {showInstructions ? 'Hide Instructions' : 'Show Instructions'}
          </MenuItem>
        </Menu>
        <Typography variant="subtitle1">Broker</Typography>
        <Typography variant="body2">
          The Arch Broker connects your on-prem HP Anyware infrastructure to the
          Arch platform.
        </Typography>
        <br />
        {activeFacility.brokerSetupComplete && (
          <>
            <div>
              {' '}
              <ArchDot instanceState="running"></ArchDot>Broker is online
            </div>
            {showInstructions && <br />}
          </>
        )}

        {!activeFacility.brokerSetupComplete && (
          <>
            <div>
              {' '}
              <ArchDot instanceState="stopped"></ArchDot>Broker not setup
            </div>
            <br />
          </>
        )}

        <Collapse in={!activeFacility.brokerSetupComplete || showInstructions}>
          <Typography variant="subtitle2">System Requirements</Typography>
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <ul>
                <li>2 or more CPUs or vCPUs at 2.5 GHz or higher</li>
                <li>4 GB of RAM</li>
                <li>4 GB of swap space</li>
                <li>4 GB of free disk space</li>
                <li>RHEL 7 or CentOS 7</li>
                <li>Machine should not have a public IP address</li>
              </ul>
            </Card>
          </Grid>
          <br />

          <Typography variant="subtitle2">Installation</Typography>
          <Typography variant="body2">
            SSH into the machine that will host the Broker, and run the
            installation script:
          </Typography>
          <Grid item xs={12} md={8}>
            <Card variant="outlined" className={classes.code}>
              <code>
                curl -fsSL https://app.archpt.io/install_broker.sh | sh
              </code>
              <CopyButton
                className={classes.copy}
                textToCopy="curl -fsSL https://app.archpt.io/install_broker.sh | sh"
              />
            </Card>
          </Grid>

          <br />

          <Typography variant="subtitle2">Configuration</Typography>
          <Typography variant="body2">
            Add the following configuration to{' '}
            <code>/srv/broker/compose.yml</code>
          </Typography>
          <Grid item xs={12} md={8}>
            <Card variant="outlined" className={classes.code}>
              {showConfiguration ? (
                <>
                  { /*  prettier-ignore */}
                  <code>
                    <pre ref={codeRef}>
                      MANE_RESOURCE_API_BASE=https://api.prod.archpt.io{"\n"}
                      FACILITY_ID={activeFacility.id}{"\n"}
                      BROKER_ACCESS_KEY=bak_{crypto.randomUUID()}{"\n"}
                      AD_DOMAIN={activeFacility.directory.domain}{"\n"}
                      AD_IP_ADDRESS={activeFacility.customParameters.directory_dns_ip1}{"\n"}
                    </pre>
                  </code>
                  <CopyButton className={classes.copy} refToCopy={codeRef} />
                </>
              ) : (
                <div>
                  <Button onClick={() => setShowConfiguration(true)}>
                    Show Configuration
                  </Button>
                </div>
              )}
            </Card>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  setupSection: {
    position: 'relative',
  },
  code: {
    padding: theme.spacing(2),
    position: 'relative',
    paddingRight: theme.spacing(4),
    fontSize: 12,
  },
  copy: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    position: 'absolute',
  },
});
export default connect(
  'selectActiveClient',
  'selectActiveFacility',
  'selectDirectories',
  'selectBaseUrl',
  'doFetchFacility',
  withStyles(BrokerSetup, styles),
);
