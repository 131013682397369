import { createSelector } from 'redux-bundler';
import { chain, flatten, forEachRight, groupBy, mapValues } from 'lodash';
import moment from 'moment-timezone';

import OtpCode from '../models/otp_code';

export default {
  name: 'otp_code',
  getReducer: () => {
    const initialData = {
      loading: false,
    };

    return (state = initialData, { type, payload }) => {
      return state;
    };
  },

  doCreateOtpCode: () => async ({ store, getState }) => {
    const otpCode = new OtpCode();
    await otpCode.save();

    return otpCode;
  },
};
