import Masquerade from '../models/masquerade';

export default {
  name: 'masquerades',
  getReducer: () => {
    const initialData = {};

    return (state = initialData) => state;
  },

  doCreateMasquerade: user => async ({ getState, store }) => {
    const state = getState();

    const authenticatedUser = store.selectAuthenticatedUser(state);

    const masquerade = new Masquerade({
      user: authenticatedUser,
      masqueradeAs: user,
    });

    const success = await masquerade.save({
      with: ['user.id', 'masqueradeAs.id'],
    });
    if (success) {
      window.location = window.location.origin;
    }
  },

  doDestroyMasquerade: () => async ({ getState, store }) => {
    const state = getState();

    const authenticatedUser = store.selectAuthenticatedUser(state);

    const response = await Masquerade.find(authenticatedUser.masqueradeId);
    const masquerade = response.data;

    const success = await masquerade.destroy();
    if (success) {
      window.location = window.location.origin;
    }
  },
};
